import React, {Component} from 'react'
import moment             from 'moment'
import _                  from 'lodash'
import cn                 from 'classnames'
import PropTypes          from 'prop-types'
import VOD                from './vod'
import Schedule           from './schedule.js'
import Splash             from './splash.js'
import Streaming          from './streaming.js'
import VideoJS            from './video'
import {getMovies,
        parseMovie}       from './movies'
import {isFreeUser}       from '../user';
import {getMovie}         from '../services/api'
import {getThumbnail}     from '../services/vimeo'
import {updateMovie}      from '../actions/movie'

/*

   pre -> streaming -> post

   schedule

 */

export default class MovieNight extends Component {
    constructor(props, ctx){
        super(props, ctx)

        const movies = getMovies()
        const film = movies && movies[0]

        const streamState = (film && film.isAfterStartDate) ? 'streaming' : 'pre'

        this.state = {
            film,
            streamState,
            curtains: 'closed',
            schedule: false,
            renderVideo: false,
            thumbnailUrl: null
        }

        this.toggleSchedule      = this.toggleSchedule.bind(this)
        this.streamFilm          = this.streamFilm.bind(this)
        this.currentFilmHasEnded = this.currentFilmHasEnded.bind(this)
        this.viewFilm            = this.viewFilm.bind(this)
        this.canViewFilm         = this.canViewFilm.bind(this)
        this.checkEntryEndDate   = this.checkEntryEndDate.bind(this)
        this.getThumbnailUrl = this.getThumbnailUrl.bind(this)

        this.autoPlay = false;
    }

    componentDidMount() {
        const { film = {} } = this.state
        this.emit = setTimeout(() => {
            if (!film.isBetweenStartDateAndEndDate) return
            this.streamFilm(film)
        }, 1000)

        if (film.isLiveStreaming) {
            this.openPipe = setInterval(() => {
                getMovie(film._id)
                    .then(this.checkEntryEndDate)
                    .catch(console.warn)
            }, 1000 * 60); // 1 min
        }
    }

    componentWillUnmount(){
        clearTimeout(this.emit)
        clearTimeout(this.filmEnded)
        clearTimeout(this.bindRenderVideo)

        clearInterval(this.openPipe)
    }

    componentWillUpdate(nextProps, nextState){
        const {curtains} = this.state
        if (curtains !== nextState.curtains) {
            if (nextState.curtains === 'closed') {
                this.bindRenderVideo = setTimeout(()=>{
                    this.setState({renderVideo:false})
                }, 1100) //after the closing animation
            } else {
                this.setState({ renderVideo:true })
            }
        }
    }

    streamFilm(film){
        const now = moment()
        if (film.endDate.isBefore(now)) {
            return
        }

        clearTimeout(this.bindCurtains)

        this.setState({ film }, () => {
            // Download vimeo thumbnail only for free/anon users since for active subscripbers the streamer is shown
            if(isFreeUser()) this.getThumbnailUrl(film)

            if (this.state.curtains === 'opened') {
                this.setState({curtains:'closed'}, ()=>{
                    this.bindCurtains = setTimeout(()=>{
                        this.setState({renderVideo:true}, ()=>{
                            setTimeout(()=>{
                                this.setState({streamState:'streaming', curtains:'opened'})
                            }, 600)
                        })
                    }, 1500)
                })
            } else {
                this.setState({renderVideo:true}, ()=>{
                    this.bindCurtains = setTimeout(()=>{
                        this.setState({streamState:'streaming', curtains:'opened'})
                    }, 600)
                })
            }
        })
    }
    canViewFilm (film) {
        return isFreeUser() && film.requiresSubscription ? false : true
    }
    viewFilm(film){
        if (!_.has(film, 'film.fields.filmId')) return
        const id = film.film.fields.filmId
        const {router} = this.context
        router.push(router.createLocation(`/film?id=${id}`));
    }
    currentFilmHasEnded(){
        this.filmEnded = setTimeout(()=>{
            const film = { ...this.state.film }
            film.isAfterStartDate = true
            this.setState({ film, streamState: 'post', curtains: 'closed' })
        }, 6000)
    }
    toggleSchedule(){
        this.setState({schedule:!this.state.schedule})
    }
    checkEntryEndDate(entry) {
        const film = parseMovie(entry)

        if (film.endDate.isSame(this.state.film.endDate) === true) return

        this.setState({ film }, () => updateMovie(entry))
    }
    getThumbnailUrl(film) {
        if (film && film.videoID) {
            getThumbnail(film.videoID).then(result => {
                this.setState({
                    thumbnailUrl: result
                })
            })
        }
    }
    render(){

        const { curtains, streamState, schedule, renderVideo, film } = this.state

        const splash        = !schedule && streamState === 'pre'
        const streaming     = !schedule && streamState === 'streaming'
        const streamingPost = !schedule && streamState === 'post'

        const opened = curtains === 'opened',
              closed = curtains === 'closed'

        return (
            <div className="movie-night">
              <div className="content-wrapper">
                <div className="video-content">
                  <div className="video">
                    {renderVideo && (
                        film.isLiveStreaming ?
                        <VideoJS film={film} onEnded={this.currentFilmHasEnded} canViewFilm={this.canViewFilm} /> :
                        <VOD film={film} prePoster={film.imageURL || this.state.thumbnailUrl} currentFilmHasEnded={this.currentFilmHasEnded} canViewFilm={this.canViewFilm} />
                    )}
                    <div className={cn("presenting", {opened, closed})}/>
                  </div>
                  <div className={cn('curtains', {opened, closed})}>
                    <div className="left" />
                    <div className="right" />
                    <div className="top" />
                  </div>
                </div>
                <div className="main-content">
                  <div className="window" />
                  <div className="content">
                    { schedule && <Schedule onClose={this.toggleSchedule} watchFilm={this.streamFilm} canViewFilm={this.canViewFilm} /> }
                    { splash && <Splash film={film} showSchedule={this.toggleSchedule} /> }
                    { streaming && <Streaming film={film} showSchedule={this.toggleSchedule} watchMovie={this.streamFilm} canViewFilm={this.canViewFilm} /> }
                    { streamingPost && <Streaming post={true} film={film} showSchedule={this.toggleSchedule} watchMovie={this.streamFilm} canViewFilm={this.canViewFilm}/> }
                  </div>
                </div>
              </div>
            </div>
        )
    }
}

MovieNight.contextTypes = {
    router: PropTypes.object.isRequired
}
