import React, {Component}    from 'react'
import _                     from 'lodash'
import cn                    from 'classnames'
import PropTypes             from 'prop-types'
import OverviewPanel         from './overview'
import SubscriptionPanel     from './subscription'
import GiftsPanel     from './gifts'
import PlansPanel            from './plans'
import ReceiptsPanel         from './receipts'
import NotificationsPanel    from './notifications'
import DataUsagePanel        from './data-usage'
import Tickets               from './tickets'
import {hasSubscriptionInfo} from '../user'
import UserInfo              from '../components/user-info'
import { fetchInitialTickets, fetchExclusiveTickets, fetchReceipts, hasAuth } from '../services/api.js'
import Login from '../services/login';
import { updateMetadata, resetMetadata } from '../actions/metas'

export default class AccountInfo extends Component {
    constructor(props, ctx){
        super(props, ctx)

        this.goto = this.goto.bind(this)
        this.updateDefaultRoute = this.updateDefaultRoute.bind(this)
        this.contentForRoute = this.contentForRoute.bind(this)

        this.state = {
            ticketsData: {
                tickets: [],
                ticketsRequestedForCode: [],
                assignedCodes: []
            },
            receipts: [],
        }

        this.anonymousUserTabs = [
            {name:'Plans', route:'plans'},
            {name:'Tickets', route: 'tickets'},
        ]

        this.freeUserTabs = [
            {name:'Account overview', route:'overview'},
            {name:'Subscription', route:'subscription'},
            {name:'Plans', route:'plans'},
            {name:'Receipts', route:'receipts'},
            {name:'Notification settings', route: 'notifications'},
            {name:'Gift', route: 'gift'},
            {name:'Tickets', route: 'tickets'},
            {name: 'My data', route: 'myData'},
            {name:'Help', route:'help'}
        ]

        this.paidUserTabs = [
            {name:'Account overview', route:'overview'},
            {name:'Subscription', route:'subscription'},
            {name:'Plans', route:'plans'},
            {name:'Receipts', route:'receipts'},
            {name:'Notification settings', route: 'notifications'},
            {name:'Gift', route: 'gift'},
            {name:'Tickets', route: 'tickets'},
            {name: 'My data', route: 'myData'},
            {name:'Help', route:'help'}
        ]
    }
    componentWillUpdate() {
        this.updateDefaultRoute()
    }
    componentWillMount() {
        this.updateDefaultRoute()
        hasAuth() ? updateMetadata({ title: 'My account' }) : resetMetadata()
    }
    componentDidMount () {
        if (hasAuth()) {
            fetchExclusiveTickets()
                .then((result = {}) => {
                    const {body: ticketsData} = result
                    this.setState({ticketsData})
                })
                .catch(err => {
                    console.error(err)
                })

            fetchReceipts().then(
                receipts => {
                    this.setState({receipts})
                }).catch(err => {
                    console.error(err)
                })
        } else {
            fetchInitialTickets()
                .then((result = {}) => {
                    const {body: ticketsData} = result
                    this.setState({ticketsData})
                })
                .catch(err => {
                    console.error(err)
                })
        }
        const {relogin} = this.props.location.query
        if (relogin && !hasAuth()) {
            setTimeout(() => !hasAuth() && Login('/account?screen=overview'), 1500);
        }
    }
    goto(route){
        const {router} = this.context
        if (route === 'help') {
            router.push(router.createLocation('/contact'))
        } else {
            router.push(router.createLocation(`/account?screen=${route}`))
        }
    }
    updateDefaultRoute() {
        this.defaultRoute = hasAuth() ? {name:'Account overview', route:'overview'} : {name:'Plans', route:'plans'}
    }
    contentForRoute(route, view) {
        const {ticketsData} = this.state
        const {router, location} = this.props

        switch (route) {
            case "overview":
                return <OverviewPanel />
            case "subscription":
                return <SubscriptionPanel />
            case "plans":
                return <PlansPanel view={view} router={router} />
            case "receipts":
                return <ReceiptsPanel />
            case "notifications":
                return <NotificationsPanel />
            case "gift":
                return <GiftsPanel router={router}/>
            case "myData":
                return <DataUsagePanel />
            case "tickets":
                return <Tickets data={ticketsData} accountPage={this.accountPage} entryID={_.get(location,'query.ticket')}/>
            default:
                return null
        }
    }
    render(){
        const {screen, view} = this.props.location.query
        const {receipts} = this.state;
        const tabs = hasSubscriptionInfo() || receipts.length > 0 ? this.paidUserTabs : hasAuth() ? this.freeUserTabs : this.anonymousUserTabs;
        const selectedRoute = _.find(tabs, t=>t.route === screen) || this.defaultRoute;

        const {route} = selectedRoute

        return (
            <div ref={el => this.accountPage = el} className="account-info-page">
                <div className="account-info">
                    <span className="tape tape--1"></span>
                    <span className="tape tape--2"></span>
                    <span className="tape tape--3"></span>
                    <span className="tape tape--4"></span>
                    <div className="tabs">
                        {
                            tabs.map((t, idx)=>{
                                const selected = selectedRoute === t
                                const {name, route} = t
                                return (
                                    <div
                                        className={cn('tab', {selected})}
                                        onClick={()=>this.goto(route)}
                                        key={idx}
                                    >
                                        {name}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="tab-content">
                        {this.contentForRoute(route, view)}
                        <div className='preload-font'>A</div>
                        <div className='preload-font-1'>A</div>
                    </div>
                </div>
                <UserInfo />
            </div>
        )
    }
}

AccountInfo.contextTypes = {
    router: PropTypes.object.isRequired
}
