import _ from 'lodash'
import { ENTRIES_LOADED, RELATED_LOADED } from '../constants';

const initialState = {}

function updateState(state, contentType, newContent) {
    let content = _.extend({}, state[contentType] || {}, newContent)
    return _.extend({}, state, {[contentType]: content})
}

function indexSection(key, entries){
    let objs = entries[key]
    let result = {}
    Object.keys(objs).forEach(key => {
        let obj = objs[key]

        if (!obj.image) return

        let tracks = _.isArray(obj.trackId) ? obj.trackId : [obj.trackId]
        tracks.forEach(trackId => {
            if (!result[trackId]) {
                result[trackId] = [obj]
            } else {
                result[trackId].push(obj)
            }
        })
    })
    return result
}

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            let keys = ["photos", "memo", "press", "docs"]
            let update  = _.clone(state)
            let found   = false
            let byContentfulId = _.clone(state.byContentfulId || {})
            keys.forEach(key=>{
                if (action.entries[key]) {
                    update[key] = indexSection(key, action.entries)
                    let objs = action.entries[key]
                    Object.keys(objs).forEach(key=>{
                        let entry = objs[key]
                        byContentfulId[entry._id] = entry
                    })
                    found = true
                }
            });

            if (found) update.byContentfulId = byContentfulId

            if (found) return update;
            else       return state;
        case RELATED_LOADED:
            let {related} = action
            let {entries, contentType, trackId} = related

            let asArray = Object.keys(entries || {}).map(id=>entries[id])
            let content = {[trackId]: asArray};
            return updateState(state, contentType, content);
    }
    return state;
}
