import React, {Component} from 'react'
import SectionPage        from './components/section-page'
import store              from './store'
import marked             from 'marked'
import privacyPolicy from '../images/section-page/privacy-policy.png'

export default class PrivacyPolicyContainer extends Component {
    getContent(){
        return store.getState().pages.byId.privacy.content
    }
    render(){
        return (
            <SectionPage className="privacy-policy-page"
                         headerImageUrl={privacyPolicy}
                         html={marked(this.getContent())}/>
        )
    }
}
