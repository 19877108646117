import cn                                     from 'classnames'
import store                                  from './store'
import React, {Component}                     from 'react'
import {Link}                                 from 'react-router'
import { HSplitFirst }                        from './components/hsplits';
import {showLiveStream, showLiveStreamTeaser,
        showLinkToLiveRecording}              from './toggleLiveStreams'
import marked                                 from 'marked'
import _                                      from 'lodash'
import removeHash                             from './services/remove-hash'
import Auth                                   from './services/auth'
import {hasAuth}                              from './services/api'
import { resetMetadata }                      from './actions/metas'
import postit01 from '../images/filing-cabinet/postit_01.png'
import postit02 from '../images/filing-cabinet/postit_02.png'
import postit03 from '../images/filing-cabinet/postit_03.png'
import postit04 from '../images/filing-cabinet/postit_04.png'
import postit05 from '../images/filing-cabinet/postit_05.png'
import cabinetDoorBottom from '../images/filing-cabinet/cabinet-door-bottom.png'

const postits = [
    postit01,
    postit02,
    postit03,
    postit04,
    postit05,
];

const showWelcome = false
const showPostIt  = showLiveStream || showLiveStreamTeaser
const showNewsUpdate = false

const ignore = e => {
    if (e) {
        e.preventDefault()
        e.stopPropagation()
    }
    return false;
}

function externalizeHrefs(el) {
    let links = el.querySelectorAll('a')
    for (let i = 0; i < links.length; i++) {
        let a = links[i]
        if (location.hostname === a.hostname || !a.hostname.length) continue;
        a.setAttribute('target', '_blank')
    }
}

class PostIt extends Component {
    componentDidMount(){
        if (this.refs.link) externalizeHrefs(this.refs.link)
    }
    render(){
        let {id} = this.props

        let state = store.getState()
        if (!state.postIt) return null

        let it = state.postIt[id]
        if (!it) return null

        let {url, display, content, style, videoId} = it
        if (!display) return null

        // No render if there is a top type "paper" post and it is displayed
        if( state.postIt['top'].style.indexOf('paper') !== -1 && state.postIt['top'].display && it.id !== 'top') return null;

        // Postit or paper style
        const type = style.slice(0, -2);
        const typeValue = style.slice(-1);
        let background = `url('${postit01}')`
        if (type === 'paper') {
            background = null;
        } else if ( type === 'postit') {
            background = `url('${postits[parseInt(style.slice(-1)) - 1]}')`
        }

        content = marked(content)
        let cx = cn('post-it', id, type, typeValue)
        let contentCn = cn('content', type)

        const link = videoId ? `/video?vimeoId=${videoId}` : url

        if (link) {
            const path = removeHash(link)
            if (path.indexOf('http') === 0) {
                return (<div ref="link" style={{backgroundImage: background}} className={cx}><a className={contentCn} href={path} dangerouslySetInnerHTML={{__html:content}}></a></div>)
            }
            return (<div ref="link" style={{backgroundImage: background}} className={cx}><Link className={contentCn} to={path} dangerouslySetInnerHTML={{__html:content}}></Link></div>)
        }

        return (<div style={{backgroundImage: background}} className={cx}><div className={contentCn} dangerouslySetInnerHTML={{__html:content}}></div></div>)
    }
}

function tryParseIcon(suffix, data, out){
    const imageKey = 'icon'+suffix
    const linkKey  = 'link'+suffix
    const videoKey = 'video'+suffix
    const image    = data[imageKey]
    const videoId = data[videoKey]

    if (!image) return
    if (!_.has(image, 'fields.file.url')) return
    const url    = image.fields.file.url
    const backgroundImage = `url(${url})`
    const link   = videoId ? `/video?vimeoId=${videoId}` : data[linkKey] || ''

    out.push({link, backgroundImage})
}

function getLeftSideIcons(){
    const data = store.getState().leftSideIcons.icons

    if (!data) return []

    const result = []

    tryParseIcon('1', data, result)
    tryParseIcon('2', data, result)
    tryParseIcon('3', data, result)
    tryParseIcon('4', data, result)
    tryParseIcon('5', data, result)

    return result
}

class LeftSideIcons extends Component {
    constructor(props, context){
        super(props, context)
        this.state = {icons:getLeftSideIcons()}
    }
    componentDidMount() {
        if (this.refs.icons) externalizeHrefs(this.refs.icons)
    }

    render() {
        return (
            <div className="left-side-icons" ref="icons">
            {
                this.state.icons.map(({ link, backgroundImage }, idx) => {
                    const path = removeHash(link)
                    if (path.indexOf('http') === 0) {
                        return (
                            <a href={path} key={idx}>
                                <div className="icon" style={{ backgroundImage }} />
                            </a>
                        )
                    }

                    return (
                        <Link to={path} key={idx}>
                            <div className="icon" style={{ backgroundImage }} />
                        </Link>
                    )
                })
            }
            </div>
        )
    }
}

export default class DrawerFront extends Component {
    constructor(props){
        super(props)
        this.hideAuth0 = this.hideAuth0.bind(this)

        console.log('%cstore', 'color:red;font-size:1.1em;', store)
    }
    componentDidMount() {
        this.hideAuth0()
        resetMetadata()
    }
    componentWillUpdate() {
        this.hideAuth0()
    }
    hideAuth0() {
        if (!hasAuth()) {
            Auth.hide()
        }
    }
    postIt(id) {
        if (this.props.behindLogin) return null
        return <PostIt id={id} />
    }
    leftSideIcons(){
        if (this.props.behindLogin) return null
        return <LeftSideIcons />
    }
    render() {
        let {hideLinks} = this.props
        let showLinks = !hideLinks

        let style = { width: '100%', height: '100%'}
        let barStyle = { width: '100%', height: '100%'}

        let fbLiveVideo = "https://www.facebook.com/NeilYoung/videos/10159601154410317/"
        let fbRecordedVideo = "https://www.facebook.com/NeilYoung/videos/10159601154410317/"

        let c = String.fromCharCode(169)  //copyright symbol

        return (
            <HSplitFirst height="109px" style={style}>
              <div style={barStyle}></div>
              <div id="drawer-front-container">
                <div id="drawer-front">
                  <div className="front-wrapper">
                    <Link to="/drawer">
                      <img className="front" src={cabinetDoorBottom} />
                    </Link>
                    <div className="ul panel" />
                    <div className="ur panel" />
                    <div className="ll panel" />
                    <div className="lm panel" />
                    <div className="lr panel" />
                    {showNewsUpdate && <Link to="/news"><div className="nya-news-update" /></Link>}
                    {showWelcome && <Link to="/video?id=qIuQOVBqRq6S2iMAg68oi"><div className="welcome" /></Link> }
                    {showPostIt && <a href={fbLiveVideo} target="_blank"><div className="livestream" /></a> }
                    {showLinkToLiveRecording &&
                     <a href={fbRecordedVideo} target="_blank"><div className="livestream-recording" /></a> }
                     {this.postIt('top')}
                     {this.postIt('middle')}
                     {this.postIt('bottom')}
                     {this.leftSideIcons()}
                  </div>
                </div>
                <div className="drawer-top-line" />
              </div>
              { showLinks &&
                <div className="drawer-front-bottom-items">
                  <div className="items-right">
                  <span className="c">{c}</span>2017 Shakey Pictures/<span className="c">{c}</span>2017 Warner Bros. Records
                  <span className="pipe">|</span>
                    <a target="_blank" onContextMenu={ignore} href="https://nya.orastream.com/">powered by ORASTREAM</a><span className="pipe">|</span>
                    <a target="_blank" onContextMenu={ignore} href="/terms.html">TERMS AND CONDITIONS</a><span className="pipe">|</span>
                    <a target="_blank" onContextMenu={ignore} href="/privacy.html">PRIVACY POLICY</a><span className="pipe">|</span>

                    <a target="_blank" onContextMenu={ignore} href="/privacy.html#adchoices">AD CHOICES</a><span className="pipe">|</span>
                    <Link to="/credits">CREDITS</Link><span className="pipe">|</span>
                    <Link to="/faq">FAQ</Link>
                  </div>
                </div>
              }
            </HSplitFirst>
        )
    }
}
