import _ from 'lodash';
import { ENTRIES_LOADED } from '../constants';

export default function update(state = {icons:undefined}, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.leftSideIcons) {
                let result = undefined
                Object.keys(action.entries.leftSideIcons).forEach(k => {
                    //there should only be one of these...
                    result = {icons: action.entries.leftSideIcons[k]}
                })
                return result
            }
            break;
    }
    return state;
}
