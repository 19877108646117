import React, {Component} from 'react'
import cn from 'classnames'

class Toggle extends Component {
    render(){
        let cx = cn('toggle', this.props.className)
        return (
            <div className={cx}>
              <div className="toggle-label" />
              <div className={this.props.on ? 'toggle-on' : 'toggle-off'} onClick={this.props.toggle} />
            </div>
        )
    }
}

export default class Toggles extends Component {
    render(){
        let {toggleOriginal, toggleAdditional, additional, original} = this.props
        return (
            <div className="timeline-toggles">
              <div className="original-release"   onClick={toggleOriginal} />
              <Toggle toggle={toggleOriginal}
                      on={original}   className="original"    />
              <Toggle toggle={toggleAdditional}
                      on={additional} className="additional"  />
              <div className="additional-release" onClick={toggleAdditional} />
            </div>
        )
    }
}
