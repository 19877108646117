import FolderBrowser from './components/folder-browser'

export default class Container extends FolderBrowser {
    contentType(){
        return 'memo'
    }
    selectedTab(){
        return 'memorabilia'
    }
    extractPhotosFromTrack(track){
        return track.memorabilia
    }
}
