import React, { Component }            from 'react';
import PropTypes                       from 'prop-types';
import ProgressBar                     from 'react-progress-bar-plus';
import LoadingIndicator                from './components/loading-indicator';
import VerticalCenter                  from './components/vertical-center';

class SplashScreen extends Component {
    render(){
        return (
            <VerticalCenter style={{width:'100%', height:'100%'}}>
                <center><LoadingIndicator /></center>
                <ProgressBar percent={this.props.loadState} autoIncrement={true} intervalTime={300} spinner={false} />
            </VerticalCenter>
        )
    }
}

SplashScreen.defaultProps = {
    loadState: 0,
}

SplashScreen.propTypes = {
    loadState: PropTypes.number.isRequired,
}

export default SplashScreen