import React, {Component} from 'react'
import PropTypes from 'prop-types'
import GlobalMenuIcon from './global-menu-icon'
import BackNextSwitch from './back-next-switch'
import {getCurrentDateAsQueryString} from '../currentDate'
import _ from 'lodash'
import Player from './new-player/new-player'

export default class TopBar extends Component {
    constructor(props, context){
        super(props, context)

        this.find     = this.find.bind(this)
        this.cabinet  = this.cabinet.bind(this)
        this.infocard = this.infocard.bind(this)
        this.timeline = this.timeline.bind(this)
    }
    go(url){
        let {router} = this.context
        router.push(router.createLocation(url))
    }

    find(){
        this.go('/search')
    }
    cabinet(){
        this.go('/drawer?' + getCurrentDateAsQueryString())
    }
    infocard(){
        this.go('/info-card?' + getCurrentDateAsQueryString())
    }
    timeline(){
        this.go('/timeline?' + getCurrentDateAsQueryString())
    }
    getButton(path){
        let table = {
            '/drawer':'timeline',
            '/info-card':'cabinet',
            '/timeline':'infocard'
        }
        let found
        _.each(table, (button, match)=>{
            if (path.indexOf(match) === 0) found = button
        })
        return found
    }
    hideShadow(path){
        let table = {
            '/':true,
            '/drawer':true,
            '/timeline':true,
            '/timeline-months':true
        }
        return table[path]
    }
    render(){
        let button = this.getButton(this.props.path)
        const showShadow = !this.hideShadow(this.props.path)

        return (
            <div className="global-top-bar-wrapper">
              <div className="global-top-bar">
                <Player />
                <div className="find" onClick={this.find} />
                { button ?
                  <div className={button} onClick={this[button]} />
                  : null }
                { showShadow && <div className="shadow" /> }
              </div>
              <GlobalMenuIcon />
              <BackNextSwitch />
              <div className="find" onClick={this.find} />  
            </div>
        )
    }
}

TopBar.contextTypes = {
    router: PropTypes.object.isRequired
};
