import moment from 'moment'
import {getTrackById, getAlbumById, getFilmById, getVideoById, getVideoByContentfulId, getMoreById, getPlaylistById } from './drawer-items'

/*

   A: free user
        access level: free           // access control level of this user
        can cancel: false            // whether this plan can be cancelled
        has subscription info: false // has past receipts

   B: paid user  'active'
        access level: paid
        can cancel: true
        has subsciption info: true

   C: declined user (still active)
        access level: free
        can cancel: true
        has subsciption info: true

   D: cancelled user (still active)
        access level: paid
        can cancel: false
        has subsciption info: true

   E: declined user (inactive)
        access level: free
        can cancel: false
        has subsciption info: true

   F: cancelled user (inactive)
        access level: free
        can cancel: false
        has subsciption info: true
*/


//below vars determine access control etc. they will be updated from the server appropriately
//whether the user is restricted from viewing some content
//FOR RELEASE: should be set to true
let userIsFree = true
//whether the user has (or has had) an active subscription
//FOR RELEASE: should be set to false
let userHasSubscriptionInfo = false
//whether the user received a gift subscription
let isGift = false
let customerCoupon = ''
let userSubType = null

//status can be 'active', 'cancelled-active', 'declined-active', 'cancelled-inactive', 'declined-inactive'
//only relevant when userHasSubscriptionInfo === true
let userSubscriptionStatus = 'active'

let billingInfo = {
    last4: '0000',
    card: 'Visa',
    expirationDateText: '00/0000'
}

//the date the plan renews, will end, or ended (depending on the userSubscriptionStatus)
let relevantSubscriptionDate = 0

let userPlanId = 'NYA-UNLIMITED-MONTHLY'

export function currentPlanPrice(){
    const planId = userPlanId
    if (planId === 'NYA-UNLIMITED-MONTHLY' || planId === 'NYA-RUST-MONTHLY') return '1.99'
    else if (planId === 'NYA-UNLIMITED-YEARLY' || planId === 'NYA-RUST-ANNUAL') return '19.99'
    return '0.00'
}

export function userIsNotSubscribed() {
    return !userHasSubscriptionInfo
}

export function userPlan() {
    return userPlanId
}

export function userSubscriptionType() {
    return userSubType
}

export function determineUserStatusFromSubscriptionResponse(json) {
    const FREE = 'NYA-FREE',
          TRIAL = 'NYA-TRIAL',
          TRIAL2   = 'TRIAL-PERIOD',
          ACTIVE = 'ACTIVE',
          CANCELLED = 'CANCELED' //[sic]
          /* DECLINED = 'PAYMENT_DECLINED'*/

    const {planId, active, status, type} = json

    console.log('user subscription response', json)

    userPlanId = planId
    userSubType = type

    if (planId === FREE || planId === TRIAL || planId === TRIAL2) {
        userIsFree = true //free
        userHasSubscriptionInfo = false
        userSubscriptionStatus = null
        return
    }

    userHasSubscriptionInfo = true

    if (status === ACTIVE) {
        userSubscriptionStatus = 'active'
        isGift = json.gift || false
        customerCoupon = json.customerCoupon || ''
        userIsFree = false //paid
        relevantSubscriptionDate = json.dateSubscriptionWillRenew
    } else if (status === CANCELLED) {
        if (active) {
            userSubscriptionStatus = 'cancelled-active'
            userIsFree = false //paid
            relevantSubscriptionDate = json.dateSubscriptionWillEnd
        } else {
            userSubscriptionStatus = 'cancelled-inactive'
            userIsFree = true //free
            relevantSubscriptionDate = json.dateSubscriptionEnded
        }
    } else { //DECLINED
        if (active) {
            userSubscriptionStatus = 'declined-active'
            userIsFree = true //free
            relevantSubscriptionDate = json.dateSubscriptionWillEnd
        } else {
            userSubscriptionStatus = 'declined-inactive'
            userIsFree = true //free
            relevantSubscriptionDate = json.dateSubscriptionEnded
        }
    }

}

export function infoCardIsLockedByID(id){
    if (!userIsFree) return false
    const track = getTrackById(id)
    if (!track) return true
    return !track.free
}

export function photoCollectionIsLockedByTrackID(id){
    return infoCardIsLockedByID(id)
}

export function albumIsLockedByID(id) {
    if (!userIsFree) return false
    const album = getAlbumById(id)
    if (!album) return true
    return !album.free
}

export function filmIsLockedByID(id) {
    if (!userIsFree) return false
    const film = getFilmById(id)
    if (!film) return true
    return !film.free
}

export function videoIsLockedByID(id) {
    if (id === "qIuQOVBqRq6S2iMAg68oi") return false

    if (!userIsFree) return false
    const video = getVideoById(id)
    if (!video) return true
    return !video.free
}
export function videoIsLockedByContentfulID(id) {
    if (id === "qIuQOVBqRq6S2iMAg68oi") return false

    if (!userIsFree) return false
    const video = getVideoByContentfulId(id)
    if (!video) return true
    return !video.free
}

export function moreVideoIsLockedByID(id){
    if (!userIsFree) return false
    const more = getMoreById(id)
    if (!more) return true
    return !more.free
}

export function isFreeUser(){
    return userIsFree
}

export function setUserLastPaymentMethod(info) {
    const {last4, brand, exp_month, exp_year} = (info || {})
    if (!(last4 && brand)) return
    billingInfo = {
        last4, card: brand, expirationDateText: `${exp_month}/${exp_year}`
    }
}

export function userLastPaymentMethod(){
    return billingInfo || {}
}

// TODO: fix response values
export function userSubscriptionInformation(){
    const state = userSubscriptionStatus
    const date = moment(relevantSubscriptionDate * 1000).calendar()
    if (state === 'active') {
        return ({
            state,
            isGift,
            customerCoupon,
            renewalDateText: date,
            planPrice: currentPlanPrice()
        })
    } else {  //cancelled-active, declined-active cancelled-inactive, declined-inactive
        return ({
            state,
            endDateText: date
        })
    }
}


export function userCanCancelSubscription(){
    if (!userHasSubscriptionInfo) return false
    const state = userSubscriptionStatus
    return state === 'active' || state === 'declined-active'

}
export function hasSubscriptionInfo(){
    return userHasSubscriptionInfo
}

//whether the 'change payment method' button should be shown
export function canChangePaymentMethod(){
    if (!userHasSubscriptionInfo) return false
    const state = userSubscriptionStatus
    const active = state === 'active'
    const declinedActive = state === 'declined-active'
    const cancelledActive = state === 'cancelled-active'
    return active || declinedActive || cancelledActive
}


// whether the user can purchase a new subscription
export function userCanPurchaseNewSubscription(){
    if (!userHasSubscriptionInfo) return true
    const state = userSubscriptionStatus
    return state === 'cancelled-inactive' ||
           state === 'declined-inactive'
}
