import _ from 'lodash'
import moment from 'moment'
import store from '../store';

const parseImage = film => {
    return _.get(film, 'thumbnail.fields.file.url', '')
}

const isLiveStreaming = film => !!(film.liveStreamingUrlPrimary || film.liveStreamingUrlSecondary || film.liveStreamingUrlTertiary)

export const parseMovie = film => {
    // (VOD and live) Asset is availbe on Hearse Theater
    const availableAt = new Date(film.liveDate)
    // (only live) Live streaming will ends
    const end = new Date(film.liveStreamingExpireDate)
    film.isLiveStreaming = isLiveStreaming(film)
    film.availableAt = +availableAt
    film.startDate = moment(availableAt)
    film.endDate = film.isLiveStreaming ? moment(end) : moment(availableAt).add(1, 'days')
    film.videoID = parseInt(film.videoID, 10)
    film.imageURL = parseImage(film)

    return film
}

const getParsedMovies = () => {
    const now = moment()

    let items = store.getState().movieNightFilm.all
    items = items.map(parseMovie)
    items = _.sortBy(_.compact(items.map(film => {
        if (film.endDate.isBefore(now)) {
            return null
        }

        if (film.isLiveStreaming && film.startDate.isAfter(now)) {
            return null
        }

        return film
    })), 'availableAt')

    const lives = items.filter(item => item.isLiveStreaming)
    const vod = items.filter(item => !item.isLiveStreaming)

    //XXX pretend the first item starts NOW
    // items[4].liveDate = new Date().toString()
    // console.log(items);

    return [...lives, ...vod]
}

export const getMovies = () => {
    const _movies = getParsedMovies();
    const now = moment()
    _movies.forEach(movie => {
        movie.isAfterStartDate = now.isAfter(movie.startDate)
        movie.isBetweenStartDateAndEndDate = now.isBetween(movie.startDate, movie.endDate)
        const {startDate, endDate} = movie
        const startText = startDate.format("h[:]mma, MMMM Do, YYYY")
        const endText = endDate.format("h[:]mma, MMMM Do, YYYY")
        if (movie.isAfterStartDate) {
            movie.dateText = `Available until: ${endText}`
        } else {
            movie.dateText = `Available starting: ${startText}`
        }
    })
    return _movies
}
