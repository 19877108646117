import ieVersion                         from 'ie-version';
import {pageView}                        from './services/tracking';

const shouldRedirectForIE = !!ieVersion.version;

if (shouldRedirectForIE) {
    if (process.env.PREVIEW) {
        pageView('/preview')
        document.location.href = "/preview.html"
    } else {
        pageView('/ie')
        document.location.href = "/ie.html"
    }
}

import _                                 from 'lodash';
import React, { Component }              from 'react';
import ReactDOM                          from 'react-dom';
import { browserHistory }                from 'react-router';
import { DragDropContext }               from 'react-beautiful-dnd'
import AppRoutes                         from './routes';
import SplashScreen                      from './splash-screen';
import PreviewPage                       from './preview';
import {indexInitialData, useStubData}   from './drawer-items';
import preloadFilingCabinet              from './preload-filing-cabinet';
import {soundReady, preloadSoundSprite}  from './sound';
import store                             from './store';
import {sfxToLoad}                       from './drawer-sfx';
import NotSupported                      from './not-supported';
import fakeSubscription                  from './fake-subscription';
import {isIPad, isIPhone, isMobile}      from './platform';
import { changePlaylistItemsOrder }      from './actions/playlists'
import {
    hasAuth,
    isPaying,
    reloadPaymentProcess
}                                        from './services/api';
import {
    fetchInitialEntries,
    fetchExclusiveEntries
}                                        from './services/fetch';
import './ios-hacks';
import style from '../scss/main.scss';

console.log('NODE_ENV =', process.env.NODE_ENV)
console.log('CMS_ENV =',  process.env.CMS_ENV)
console.log('IE VERSION = ', ieVersion.version)
console.log('FAKING SUBSCRIPTION SERVICES =', fakeSubscription)

let history = browserHistory

let soundInitialized    = false
let soundLoaded         = false
let imagesLoaded        = false
let initialLoadComplete = false

//sound manager fails to initialize properly on iOS, but still works.
//so just pretend it has already loaded...
if (isIPad || isIPhone) {
 soundInitialized    = true
 soundLoaded         = true
}

let imagesPromise, soundReadyPromise, soundLoadedPromise;

const startLoading = () => {
    if (ieVersion.version && ieVersion.version < 11) {
        console.log("SKIPPING PRELOADING FOR IE")
        //ie 10 can't handle all this preloading...
        soundInitialized    = true
        soundLoaded         = true
        imagesLoaded        = true
        imagesPromise = soundReadyPromise = soundLoadedPromise = Promise.resolve(true)
        return
    }

    imagesPromise = preloadFilingCabinet().then(()=>{imagesLoaded = true})

    soundReadyPromise = soundReady().then(()=>{soundInitialized = true})

    soundLoadedPromise = soundReadyPromise.then(()=>{
        let toLoad = sfxToLoad().concat([['drawer-open', "/sounds/filecabinet_opening_1sec.mp3"]])
        return Promise.all(toLoad.map(pair=>preloadSoundSprite.apply(null, pair)))
    }).then(()=>{soundLoaded = true})
}

class App extends Component {
    constructor(props){
        super(props)

        this.getLoadState = this.getLoadState.bind(this)

        this.state = this.stateFromStore(store.getState())

        this.state.showingAuth = false
        this.onDragEnd = this.onDragEnd.bind(this)

        startLoading()
    }
    getLoadState() {
        const elms = [
            'tracksLoaded',
            // 'albumsLoaded',
            'imagesLoaded',
            // 'soundInitialized',
            'soundLoaded'
        ];

        const loaded = _.size(_.pickBy(_.pick(this.state, elms), _.identity))

        return loaded ? Math.floor( 100 / _.size(elms) ) * (loaded + 1) : -1
    }
    stateFromStore(state){
        return { tracksLoaded: useStubData ? true : state.tracks.loaded,
                 albumsLoaded: useStubData ? true : state.albums.loaded,
                 imagesLoaded, soundInitialized,
                 soundLoaded }
    }
    appIsLoaded(state=this.state){
        let {tracksLoaded, albumsLoaded,
             imagesLoaded, soundInitialized,
             soundLoaded } = state

        return tracksLoaded && albumsLoaded &&
               imagesLoaded && soundInitialized &&
               soundLoaded
    }
    componentDidMount(){
        if (isPaying()) {
            // refresh the page in * sec, this time should be enough for subscription to go through
            setTimeout(()=> {
                if (isPaying())
                reloadPaymentProcess(0, true)
            }, 5000) // 3 sec
        }

        let checkLoad = ()=>{
            if (initialLoadComplete) return;

            let state    = store.getState()
            let newstate = this.stateFromStore(state)

            if (this.appIsLoaded(newstate)){
                initialLoadComplete = true
                indexInitialData(state)
            }
            if (!_.isEqual(this.state, newstate)) {
                console.log('setting state: ', newstate)
                this.setState(newstate)
            }
        }

        // Check this probably could be move up to the begining and sourounded by a promise.all
        // i dont get why these need to be defined on a method at an specifi time, or is that just
        // a way to organize things? in a single method
        soundLoadedPromise.then(checkLoad)
        imagesPromise.then(checkLoad)
        store.subscribe(checkLoad) // check this, doesn't seem that necessary to suscribe something to the
        // store, check it get desuscribed

        let status = store.getState().status

        let checkAuth = () => {
            if (initialLoadComplete) return
            let newStatus = store.getState().status
            if (newStatus === status && status === 'failed') return
            status = newStatus
            console.log("FETCH STATUS = ", status)
            if (status !== 'failed') {
                checkLoad()
            }
        }
        store.subscribe(checkAuth)

        fetchInitialEntries()

        if (hasAuth()){
            fetchExclusiveEntries()
        }
    }
    onDragEnd(result) {
        if (result.destination) {
            store.dispatch(changePlaylistItemsOrder(result))
        }
    }
    render() {
        if (this.appIsLoaded()) {
            return (
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <AppRoutes store={store} history={history} />
                </DragDropContext>
            )
        } else {
            return <SplashScreen loadState={this.getLoadState()}/>
        }
    }
}

if (shouldRedirectForIE) {
    //do nothing
} else if (process.env.PREVIEW) {
    pageView('/')
    ReactDOM.render(<PreviewPage />, document.getElementById('app'));
} else if (isMobile && !isIPad) {
    pageView('/mobile-not-supported')
    ReactDOM.render(<NotSupported />, document.getElementById('app'));
} else {
    pageView('/start')
    ReactDOM.render(<App />, document.getElementById('app'));
}
