import FolderBrowser from './components/folder-browser'

export default class Container extends FolderBrowser {
    selectedTab(){
        return 'photos'
    }
    contentType(){
        return 'photos'
    }
    extractPhotosFromTrack(track){
        return track.photos
    }
}
