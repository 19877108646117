import React, {Component, Fragment}            from 'react'
import _                             from 'lodash'
import cn                            from 'classnames'
import { Formik, Form }              from 'formik'
import StripeCheckout                from 'react-stripe-checkout';
import { getUserInfo, giftAccount } from '../services/api';
import Input                         from '../components/input'
import moment from 'moment'
import NyaIcon from '../../images/NYA_icon_128x128.jpg'

class Ellipses extends Component {
    constructor(props, context){
        super(props, context)
        this.state = {count:1}
        this.update = this.update.bind(this)
    }
    componentDidMount(){
        this.interval = setInterval(this.update, 300)
    }
    componentWillUnmount(){
        clearInterval(this.interval)
    }
    update(){
        let count = (this.state.count % 4) + 1
        this.setState({count})
    }
    render(){
        let ellipses = '', hidden = ''
        for (let i = 0; i < 5; i++) {
            if (i < this.state.count)
                ellipses += '.'
            else
                hidden += '.'
        }
        return (
            <span className="ellipses">{ellipses}<span className="hidden">{hidden}</span></span>
        )
    }


}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export default class Gift extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            state:'initial',
            confirm: false,
            userInfo: getUserInfo(),
            token: null,
            successFormValues: {
                firstname: '',
                email: '',
                date: '',
                note: ''
            }
        }

        this.error = this.error.bind(this)
        this.initial = this.initial.bind(this)
        this.loading = this.loading.bind(this)
        this.success = this.success.bind(this)
        this.cancelOnClickHandler = this.cancelOnClickHandler.bind(this)
        this.confirmOnClickHandler = this.confirmOnClickHandler.bind(this)
        this.finishOnClickHandler = this.finishOnClickHandler.bind(this)
        
    }

    getContent(){ return this[this.state.state]() }

    cancelOnClickHandler() {
        this.setState(state => {
            return { confirm: false , state: 'initial'}
        });
    }

    confirmOnClickHandler(errors) {
        if (isEmpty(errors)) {
            this.setState(state => {
                return { confirm: true}
            });
        } 
    }

    finishOnClickHandler() {
        this.setState({ state: 'initial', confirm: false })
        this.props.router.push('/');
    }

    success() {
        const { successFormValues: { firstname, email, date, note } } = this.state
        return (
            <div className="content content--success">    
                <div className="info-confirm">
                    <div className="message-confirm">
                    Your gift has been successfully scheduled and will be delivered on the date below
                    </div>
                  {firstname && <div className="field">
                    <div className="label">First name</div>
                    <div className="value">{firstname}</div>
                  </div>}
                  {email && <div className="field">
                    <div className="label">Email</div>
                    <div className="value">{email}</div>
                  </div>}
                  {date && <div className="field">
                    <div className="label">Date</div>
                    <div className="value">{date}</div>
                  </div>}
                  {note && <div className="field">
                    <div className="label">Note</div>
                    <div className="value">{note}</div>
                  </div>}
                </div>
                <button className="button button--continue" type="button" onClick={this.finishOnClickHandler} >EXPLORE NYA</button>
            </div>
        )
    }

    initial() {
        const userInfo = getUserInfo()
        const email = (userInfo.user_metadata && userInfo.user_metadata.customEmail) || userInfo.email

        return (
                <Formik
                initialValues={{ firstname: '', email: '', date: moment().add(1, 'days').format('L'), note: '' }}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, actions) => {
                    giftAccount({
                        token: this.state.token.id,
                        firstname: values.firstname,
                        email: values.email,
                        date: values.date,
                        note: values.note
                    })
                    .then((result) => {
                        actions.setSubmitting(false)
                        this.setState({ 
                            state: 'success', 
                            successFormValues: {
                                firstname: values.firstname,
                                email: values.email,
                                date: values.date,
                                note: values.note
                            }
                        })
                    })
                    .catch((err) => {
                        actions.setSubmitting(false)
                        this.setState({ state: 'error'})
                    })
                }}
                validate={(values, props) => {
                    let errors = {}

                    if (!values.firstname) {
                        errors.firstname = 'Required'
                    } else if (values.firstname.length >= 30) {
                        errors.firstname = 'You have exceeded max number of characters'
                    } else if (!/^\S*$/.test(values.firstname)) {
                        errors.firstname = 'Firstname can\'t contain spaces'
                    }

                    if (!values.email) {
                        errors.email = 'Required'
                    } else if (values.email.length >= 40) {
                        errors.email = 'You have exceeded max number of characters'
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        errors.email = 'Invalid email address'
                    }

                    if (!values.date) {
                        errors.date = 'Required'
                    } else if (values.date.length >= 30) {
                        errors.date = 'You have exceeded max number of characters'
                    }

                    if (!values.note) {
                        errors.note = 'Required'
                    } else if (values.note.length >= 240) {
                        errors.note = 'You have exceeded max number of characters'
                    }

                  return errors
                }}
                render={props => (
                    <Form className="content content--initial">
                        <div className='fields-wrapper'>
                            <div className="top-message">
                                { !this.state.confirm && <p>Gift a 1 year NYA-Unlimited subcription for the low price of <strong>$19.99</strong>.</p> }
                                { !this.state.confirm && <p>Enter the name & email of the gift recipient,<br/>date for gift delivery, and a note and we will do the rest.</p> }
                                { !this.state.confirm && <p>Gift subcription will expire 1 year from the date of redemption.</p> }
                                { this.state.confirm && <p>Please double check the recipients email and your message.<br/>This can't be undone or edited later.</p> }
                            </div>
                            { !this.state.confirm ? 
                                <Fragment>
                                    <Input className='input' label='Recipient First Name' limit={30} isFormikField name='firstname'/>
                                </Fragment> : 
                                <div className="field">
                                    <div className="label">Recipient First Name</div>
                                    <div className="value">{props.values.firstname}</div>
                                </div>
                            }
                            { !this.state.confirm ? 
                                <Fragment>
                                    <Input className='input' label='Recipient Email' limit={40} isFormikField name='email'/>
                                </Fragment> : 
                                <div className="field">
                                    <div className="label">Recipient Email</div>
                                    <div className="value">{props.values.email}</div>
                                </div>
                            }
                            { !this.state.confirm ? 
                                <Fragment>
                                    <Input className='input' label='Date for Gift Delivery' isFormikField isDatePicker name='date' type='date'/>
                                </Fragment> : 
                                <div className="field">
                                    <div className="label">Date for Gift Delivery</div>
                                    <div className="value">{props.values.date}</div>
                                </div>
                            }
                            { !this.state.confirm ? 
                                <Fragment>
                                    <Input className='input' label='Note' limit={240} isFormikField isTextArea name='note'/>
                                </Fragment> : 
                                <div className="field">
                                    <div className="label">Note</div>
                                    <div className="value">{props.values.note}</div>
                                </div>
                            }
                        </div>
                        { !this.state.confirm && <div className="buttons-wrapper">
                            <button className="button button--continue" type="button" onClick={() => props.validateForm().then( errors => this.confirmOnClickHandler(errors))} >SUBMIT</button>
                        </div> }
                        { this.state.confirm && <div className="buttons-wrapper">
                            <button className="button clear button--back" onClick={this.cancelOnClickHandler}>GO BACK</button>
                            <StripeCheckout 
                                  token={(token) => this.onStripeTokenHandler(token, props.submitForm)}
                                  stripeKey={process.env.STRIPE_KEY}
                                  email={email}
                                  amount={1999}
                                  billingAddress={true}
                                  image={NyaIcon} >
                                <button className={cn("button button--continue", {'disabled': props.isSubmitting})} type="button" >PAY 19.99</button>
                            </StripeCheckout>
                            
                        </div>}
                    </Form>
                    )}
                />
        )
    }

    onStripeTokenHandler(token, submitForm){
        this.setState({state:'loading', token}, () => {
            submitForm();
        })
    }

    loading(){
        return (
            <div className="content content--message">
              <div className="message">loading<Ellipses /></div>
            </div>
        )
    }

    error(){
        return (
            <div className="content content--message">
              <div className="message">Sorry! We were unable to process your gift account.<br />Please try again.</div>
              <div className="buttons">
                <div className="button clear" onClick={this.cancelOnClickHandler}>CANCEL</div>
              </div>
            </div>
        )
    }

    render() {
        return (
            <div className="gift-panel panel">
              <div className="title">Gift</div>
              {this.getContent()}
            </div>
        )
    }
}
