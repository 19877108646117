import React, {Component} from 'react'
import _                  from 'lodash'
import Billboard          from './billboard'
import AlbumOfTheWeek     from './aotw'
import SongsOfTheWeek     from './sotw'
import Ad                 from './ad'
import {getNewsData}      from './article-data'
import {Link}             from 'react-router'
import { getPlaylistSOTW,
         getFreeAlbumID } from '../drawer-items'

export default class AdColumn extends Component {
    constructor(props){
        super(props)
        this.state = {
            playlistSOTW: '',
            freeAlbumID: ''
        }
    }
    componentDidMount() {
      const playlistSOTW = getPlaylistSOTW()
      const freeAlbumID = getFreeAlbumID()
      this.setState({ playlistSOTW: playlistSOTW.playlistId, freeAlbumID })
    }
    render() {
        const {aAds, bAds} = getNewsData()

        const ads = aAds.concat(bAds)

        const { playlistSOTW, freeAlbumID } = this.state
        const { page } = this.props
        const showButtons = page ? +page === 1 : false

        return (
            <div className="column ads">
              {
                showButtons ? playlistSOTW ? (
                  <Link to={`/playlist?id=${playlistSOTW}`}><SongsOfTheWeek /></Link>
                ) : <SongsOfTheWeek /> : undefined
              }
              {
                showButtons ? freeAlbumID ? (
                  <Link to={`/album?id=${freeAlbumID}`}><AlbumOfTheWeek /></Link>
                ) : <AlbumOfTheWeek /> : undefined
              }
              {
                showButtons ? this.props.showLinks ? (
                  <Link to="/news/top-40-tracks"><div className="ad top40" /></Link>
                ) : <Billboard which="top40" shrink /> : undefined
              }
              {
                showButtons ? this.props.showLinks ? (
                  <Link to="/news/top-10-albums"><div className="ad top10" /></Link>
                ) : <Billboard which="top10" shrink /> : undefined
              }
              {
                  ads.map(({height, url, link}, idx) => <Ad height={height} url={url} link={link} key={idx} />)
              }
            </div>
        )

    }
}
