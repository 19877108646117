import React, {Fragment, Component} from "react";
import Login                        from './services/login';
import {hasAuth}                    from './services/api';

class HOCRequireAuth extends Component {
    constructor(props) {
        super(props)
        this.authorize = this.authorize.bind(this)
    }
    componentDidMount() {
        this.authorize()
    }
    componentWillUpdate() {
        this.authorize()
    }
    authorize() {
        if (!hasAuth()) {
            Login()
        }
    }
    render(){
        return <Fragment>{this.props.children}</Fragment>
    }
}

export default HOCRequireAuth
