import React, {Component, Fragment} from 'react'
import cn                 from 'classnames'
import PropTypes          from 'prop-types'
import VerticalCenter     from './vertical-center'
import {getFreeAlbumID}   from '../drawer-items'
import {isFreeUser}       from '../user'
import PlaylistManager    from '../components/new-player/playlist-manager'
import {setUserSettings, resetNewsletterPreference, hasAuth} from '../services/api';
import { Link }           from 'react-router'
import { getFreeSong }           from '../drawer-items'
import Login                     from '../services/login';

export default class WarningMessage extends Component {
    constructor(props, context){
        super(props, context)

        this.onAudioSetup     = this.onAudioSetup.bind(this)
        this.onSubscribe      = this.onSubscribe.bind(this)
        this.onFreeAlbum      = this.onFreeAlbum.bind(this)
        this.onFreeSong       = this.onFreeSong.bind(this)
        this.onStopPlayback   = this.onStopPlayback.bind(this)
        this.onLogin          = this.onLogin.bind(this)
        this.onResumePlayback = this.onResumePlayback.bind(this)
        this.onTimesContrarian      = this.onTimesContrarian.bind(this)
        this.handleNewsletterChange = this.handleNewsletterChange.bind(this);
        this.handleNewsletterSubmit = this.handleNewsletterSubmit.bind(this);
        this.goToSubscriptionAndScrollToTop = this.goToSubscriptionAndScrollToTop.bind(this);

        this.state = {
            subscribed: false,
            showFreePopup: false
        }
    }
    onAudioSetup(){
        this.go('/audio-setup')
    }
    onSubscribe(){
        this.go('/account?screen=plans')
    }
    onFreeAlbum(){
      this.props.onClose()
      let {router} = this.context
      router.push(router.createLocation(`/album?id=${getFreeAlbumID()}`));
    }
    onFreeSong(){
        this.props.onClose()
        let {router} = this.context
        const freeSongs = getFreeSong()
        router.push(`/info-card?track=${freeSongs.id}`)
    }
    onTimesContrarian(){
        this.go('/news/1')
    }
    go(pathname){
        this.props.onClose()
        let {router} = this.context
        let location = router.createLocation(pathname)
        router.push(location)
    }
    onResumePlayback(){
        PlaylistManager.play()
        this.props.onClose()
    }
    onStopPlayback(){
        PlaylistManager.stopPlaying()
        this.props.onClose()
    }
    onLogin(){
      Login();
      this.props.onClose();
    }
    handleNewsletterChange(event) {
        this.setState({subscribed: event.target.value === "true"});
    }
    handleNewsletterSubmit(event) {
        this.setState({newsletter: 'loading'})
        setUserSettings({
            type: 'cdc_newsletter',
            values: { subscribed: this.state.subscribed },
        }).then(() => {
            this.props.onClose();
        }).catch(() => {
            resetNewsletterPreference();
            this.props.onClose();
        });
    }
    goToSubscriptionAndScrollToTop() {
        let {router} = this.context
        router.push('/account?screen=plans')
        document.querySelector('.account-info-page').scrollTop = 0
        this.props.onClose();
    }
    getContent(){
        let {which} = this.props
        if (which === 'audio') {
            return (
                <div className="content">If you experience any playback issues<br />
                  like skipping or excessive buffering,<br/>
                  please switch to <span className="link" onClick={()=>window.setAudioTo320()}>320</span> and visit the<br/>
                  <span className="link" onClick={this.onAudioSetup}>Audio Set Up</span> to learn how to improve<br />
                  your experience.
                </div>
            )
        } else if (which === 'missing-track') {
            return (
                <div className="content">Sorry.<br />
                  <br />
                  This song is not currently available.<br />
                  We are working on it.<br />
                  Please check back later.
                </div>
            )
        } else if (which === 'subscription') {
            return (
                <div className="content">NYA is currently free.<br />
                  <br />
                  Please enjoy and we will let<br />
                  you know when subscriptions are<br />
                  available for purchase.
                </div>
            )
        } else if (which === 'subscribe') {
            return (
                <div className="content">
                  Subscribers only beyond this point.
                  <div className="buttons">
                    <div className="buttons-left">
                      {!!hasAuth() &&
                        <div className="button blue big subscribe" onClick={this.onSubscribe}><div className="text">SUBSCRIBE</div></div>
                      }
                      {!hasAuth() &&
                        <Fragment>
                          <div className="button subscribe" onClick={this.onSubscribe}><div className="text">SUBSCRIBE</div></div>
                        <div className="button blue log-in" onClick={this.onLogin}><div className="text">LOG IN</div></div>
                        </Fragment>
                      }
                    </div>
                    <div className="button big explore" onClick={() => {this.setState({showFreePopup: true})}}><div className="text">LEARN WHAT'S FREE</div></div>

                      { this.state.showFreePopup &&
                        <div className='freeItems'>
                            <div className="button not-now" onClick={this.onTimesContrarian}><div className="text">Read the NYA Times-Contrarian</div></div>
                            <div className="button not-now" onClick={this.onFreeSong}><div className="text">Listen to the Song Of The Day</div></div>
                            <div className="button not-now" onClick={this.onFreeAlbum}><div className="text">Listen to our Featured Album</div></div>
                            <div className="button not-now" onClick={this.props.onClose}><div className="text">Continue to browse NYA</div></div>
                            <div className={"close"} onClick={() => {this.setState({showFreePopup: false})}} />
                        </div>
                      }
                  </div>
                </div>
            )
        }else if (which === 'subscribeTicketMonthly') {
            return (
                <div className="content">
                    <div className="text">
                        <label>Pre-Sale tickets are exclusive to NYA yearly subscribers.</label>
                    </div>
                  <div className="buttons">
                    {!!hasAuth() &&
                      <Fragment>
                        <div className="button not-now" onClick={this.props.onClose}><div className="text">NOT NOW</div></div>
                        <label className="button blue subscribe" to='/account?screen=plans' onClick={this.goToSubscriptionAndScrollToTop}><div className="text">SUBSCRIBE</div></label>
                      </Fragment>
                    }
                    {!hasAuth() &&
                      <Fragment>
                        <div className="button" to='/account?screen=plans' onClick={this.goToSubscriptionAndScrollToTop}><div className="text">SUBSCRIBE</div></div>
                        <label className="button blue" onClick={Login}><div className="text">LOG IN</div></label>
                      </Fragment>
                    }
                  </div>
                    <div className="text text--below">
                        <label><sup>*</sup>Monthly subscribers contact <Link to='/contact' onClick={this.props.onClose}>customer support</Link> for help</label>
                    </div>
                </div>
            )
        } else if (which === 'ticketsUnavailable') {
            return (
                <div className="content">
                    <div className="text">
                        <span>The NYA pre-sale code system is undergoing some maintenance.  Please check back later.  Sorry for any inconvenience.</span>
                    </div>
                </div>
            )
        } else if (which === 'appleSub') {
            return (
                <div className="content">
                    <div className="text">
                        <span>You must cancel your subscription through your Apple iTunes account before your NYA account can be deleted.</span>
                    </div>
                </div>
            )
        } else if (which === 'playback-interruption') {
            const free = isFreeUser(),
                  paid = !free
            return (
                <div className={cn('content', {free, paid})}>
                  <span className="message">Do you want to keep listening?</span>
                  <div className="buttons">
                    <div className="button" onClick={this.onStopPlayback}><span className="text">NO</span></div>
                    <div className="button blue" onClick={this.onResumePlayback}><span className="text">YES</span></div>
                  </div>
                  {free && (
                       <div className="subscribe">
                         <span className="link" onClick={this.onSubscribe}>Subscribe</span> and reduce the frequency<br/>of this message.</div>)}
                </div>
            )
        } else if (which === 'newsletter') {
            return (
                <div className="content">
                    <span className="message">Would you like to receive newsletters from NYA?</span>
                    <form className="form-container">
                        <div className="option">
                            <label>
                                <input type="radio" value={true} checked={this.state.subscribed} onChange={this.handleNewsletterChange}/>
                                <span className="check" />
                                YES
                            </label>
                        </div>
                        <div className="option">
                            <label>
                                <input type="radio" value={false} checked={!this.state.subscribed} onChange={this.handleNewsletterChange} />
                                <span className="check" />
                                NO
                            </label>
                        </div>
                    </form>
                    <span className="message">If you change your mind, you can manage this preference in your account settings.</span>
                    <div className="button" onClick={this.handleNewsletterSubmit}><div className="text">SUBMIT</div></div>
                </div>
            )
        } else if (which === "xstream") {
              return (
                <div className="content">
                  <div className='XStreamIcon' />
                  <div className="content-right">
                    <div>
                      <label className="paragraph">You are now leaving NYA and entering the Xstream by NYA Store powered by Orastream.</label>
                      <label className="paragraph">You will need to create or log in to your Orastream account to make a purchase.</label>
                      <label className="paragraph">NYA subscribers get High Res Audio at Low Res prices - Individual tracks (minimum 5) & Albums.</label>
                    </div>
                    <div className="buttons-wrapper">
                      <button className="button button--back" onClick={this.props.onClose}>GO BACK</button>
                      <button className="button button--continue" onClick={() => {
                        window.open(this.props.link)
                        this.props.onClose()
                      }}>CONTINUE</button>
                    </div>
                  </div>
                </div>
              )
        }else if (which === "freeDownload") {
              return (
                <div className="content">
                  <div className='XStreamIcon' />
                  <div className="content-right">
                    <div>
                      <label className="paragraph">You are now leaving NYA and entering the Xstream by NYA Store powered by Orastream.</label>
                      <label className="paragraph">NYA Subscribers, create or log in to your Orastream account to redeem your free download.</label>
                      <label className="paragraph">Thanks for being part of NYA!</label>
                    </div>
                    <div className="buttons-wrapper">
                      <button className="button button--back" onClick={this.props.onClose}>GO BACK</button>
                      <button className="button button--continue" onClick={() => {
                        window.open(this.props.link)
                        this.props.onClose()
                      }}>CONTINUE</button>
                    </div>
                  </div>
                </div>
              )
        } else if (which === "handStore") {
              return (
                <div className="content">
                  <div>
                    <label className="paragraph">You are now leaving NYA and entering the Greedy Hand Store.</label>
                    <label className="paragraph">This store is managed by our partners at Warner Brothers Records.</label>
                  </div>
                  <div className="buttons-wrapper">
                    <button className="button button--back" onClick={this.props.onClose}>GO BACK</button>
                    <button className="button button--continue" onClick={() => {
                      window.open(this.props.link)
                      this.props.onClose()
                    }}
                    >CONTINUE</button>
                  </div>
                </div>
              )
        } else {
            return (
              <div className="content"></div>
            )
        }
    }
    render(){
        const { isLeavingWarning, which } = this.props
        const {newsletter} = this.state

        const withoutTableStyling = which === "handStore" || which === "freeDownload" || which === "xstream"
        let cx = cn('warning-message', which, {'warning-message--leaving': isLeavingWarning, ['newsletter--loading']: newsletter === 'loading'})
        return (
            <VerticalCenter className="warning-page" onBGClick={which !== 'newsletter' ? this.props.onClose : null} withoutTableStyling={withoutTableStyling}>
              <div className={cx}>
                <div className={cn("close", {"close--leaving": isLeavingWarning})} onClick={this.props.onClose} />
                {this.getContent()}
              </div>
            </VerticalCenter>
        )
    }
}
WarningMessage.contextTypes = {
    router: PropTypes.object.isRequired
}
