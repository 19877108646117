import _ from 'lodash'
import {compareItems} from '../util/compare-release-date'

export function makeInitialState(){
    return {loaded: false, all:[], byId:{}, byYear: {}}
}

export function index(newData, state, dateKey='releaseDate'){
    let data = state.loaded ? _.extend({}, newData, state.byContentfulId) : newData;
    let byId = {}, byYear = {}, byContentfulId = data

    let all = Object.keys(data).map(id => {
        let it  = data[id]

        if (byId[it.id]) {
            console.error("found entry with duplicate id: ", it.id)
            return null;
        } else {
            byId[it.id] = it
        }

        let year = it[dateKey].year
        byYear[year] = byYear[year] || []
        byYear[year].push(it)

        return it
    })

    all = _.compact(all)

    all.sort(compareItems)

    return {loaded: true, all, byId, byYear, byContentfulId}
}
