import _                     from 'lodash'
import moment                from 'moment'
//import config                from '../config.json';
import superagent            from 'superagent';
import Auth                  from './auth';
import fake                  from '../fake-subscription';

const endpoint = process.env.MANAGEMENT_URL

const unauthenticatedUserInfo = JSON.stringify({
  clientID: "3b2A21B5d9a5Z18dB48f9c9bE4750d98",
  created_at: "2019-03-06T13:18:14.209Z",
  identities: [],
  locale: "en",
  update_at: "2019-03-06T13:18:14.209Z",
  user_id: "3b2A21B5d9a5Z18dB48f9c9bE4750d98",
  user_metadata: {
    verification_email_sent: true,
    firstLogin: false
  }
})

export const getAuth = () => localStorage.getItem('ade:id-token')
export const setAuth = token => localStorage.setItem('ade:id-token', token)
export const hasAuth = () => !!getAuth()

export const getOrastreamToken = () => localStorage.getItem('ade:orastream-token')
export const setOrastreamToken = token => localStorage.setItem('ade:orastream-token', token)

export const getTicketCode = (id) => localStorage.getItem(`ade:ticketCode-${id}`)
export const setTicketCode = (id, code) => localStorage.setItem(`ade:ticketCode-${id}`, code)

export const paymentProcessReloadDelay = 3000
export const isPaying = () => localStorage.getItem('_isPaying') === 'yes'
export const setIsPaying = () => localStorage.setItem('_isPaying', 'yes')
export const resetPaymentProcess = () => localStorage.removeItem('_isPaying')
export const reloadPaymentProcess = (delay = paymentProcessReloadDelay, reset = false) => {
  reset && resetPaymentProcess()
  setTimeout(() => document.location.reload(), delay)
}

export const getUserInfo = () => {
  const userInfo = localStorage.getItem('ade:user-info') || unauthenticatedUserInfo
  try {
    return JSON.parse(userInfo)
  } catch (err) {
    return {}
  }
}
export const setUserInfo = info => {
  let userInfo;
  try {
    userInfo = JSON.stringify(info)
  } catch (err) {
    userInfo = {}
  }
  localStorage.setItem('ade:user-info', userInfo)
}

export const hasSeenLeavingMessage = (which) => {
  let seen = !!localStorage.getItem(`ade:${which}`)
  localStorage.setItem(`ade:${which}`, true)
  return seen
}

export const hasSeenWelcomeMessage = () => {
  let seen = !!localStorage.getItem('ade:welcome-message-seen')
  localStorage.setItem('ade:welcome-message-seen', true)
  return seen
}

export const hasSeenNewsletterPreference = () => {
  let seen = !!localStorage.getItem('ade:newsletter-pref-seen')
  localStorage.setItem('ade:newsletter-pref-seen', true)
  return seen
}

export const resetNewsletterPreference = () => {
  let seen = localStorage.removeItem('ade:newsletter-pref-seen');
  return seen
}

export const query = (opts = {}) => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/query?search=' + opts.query)
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) return reject(err);
                else resolve(JSON.parse(res.text))
              })
  })
}

let queryCache = {}
export const queryCached = (opts = {}) => {
  let key = opts.query
  if (queryCache[key]) return Promise.resolve(queryCache[key]);
  let result = query(opts)
  result.then(items=>queryCache[key]=items)
  return result
}

export const getInitial = (opts = {limit:500}) => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/initial')
              .end((err, res)=>{
                if (err) return reject(err);
                else resolve(JSON.parse(res.text))
              })
  })
}

export const getAll = (opts = {limit:500}) => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/all')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) return reject(err);
                else resolve(JSON.parse(res.text))
              })
  })
}

export const getStreamingToken = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/streaming-token')
              .end((err, res)=>{
                if (err) return reject(err);
                else resolve(JSON.parse(res.text))
              })
  })
}

export const getSubscriptionStatusAndStreamingToken = () => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/subscriptions')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) return reject(err);
                else resolve(JSON.parse(res.text))
              })
  })
}

export const getUserSettings = () => {
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + '/api/account/settings')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) return reject(err);
                else resolve(JSON.parse(res.text))
              })
  });
}

export const setUserSettings = (settings) => {
  return new Promise((resolve, reject) => {
    superagent.put(endpoint + '/api/account/settings')
              .set('Authorization', 'Bearer ' + getAuth())
              .send(settings)
              .end((err/*, res*/)=>{
                if (err) return reject(err);
                else resolve("ok")
              })
  });
}

export const sendEmail = body => {
  return new Promise((resolve, reject)=>{
    superagent.post(endpoint + '/api/emails')
              .set('Authorization', 'Bearer ' + getAuth())
              .send(body)
              .end((err, res)=>{
                if (err) return reject(err);
                else resolve("ok")
              })
  })
}

export const deleteAccount = () => {
  if (fake) {
    return new Promise((res, rej)=>{
      setTimeout(res, 3000)
    })
  }
  return new Promise((resolve, reject)=>{
    superagent.delete(endpoint + '/api/account')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) return reject(err);
                else resolve("ok")
              })
  })
}

export const giftAccount = ({ token, firstname, email, date, note }) => {
  return new Promise((resolve, reject)=>{
    superagent.post(endpoint + '/api/subscriptions/gift')
              .set('Authorization', 'Bearer ' + getAuth())
              .send({
                form: {
                  recipientName: firstname,
                  recipientEmail: email,
                  deliveryDate: date,
                  message: note
                },
                source: token
              })
              .end((err/*, res*/)=>{
                if (err) return reject(err);
                else resolve("ok")
              })
  })
}

export const redeemCode = (code) => {
  return new Promise((resolve, reject)=>{
    superagent.put(endpoint + `/api/subscriptions/gift/${code}`)
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err/*, res*/)=>{
                resetPaymentProcess()
                if (err) return reject(err)
                reloadPaymentProcess()
                resolve("ok")
              })
  })
}

const fakePaymentInformation = {last4:"1234", brand:"Visa", expMonth:"00", expYear:"0000"};

export const fetchPaymentInformation = () => {
  if (fake) {
    return new Promise((res, rej)=>{
      setTimeout(()=>res(fakePaymentInformation), 3000)
    })
  }
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/subscriptions/card')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) {
                  if (err && err.status === 404) { //TODO: Temporal fix, api shouldn't send a 404 for an empty card scenario
                    resolve(JSON.parse(res.text));
                  }
                  return reject(err);
                }
                else resolve(JSON.parse(res.text))
              })
  })
}

export const updatePaymentInformation = ({id}) => {
  if (fake) {
    return new Promise((res, rej)=>{
      setTimeout(res, 3000)
    })
  }
  return new Promise((resolve, reject)=>{
    superagent.put(endpoint + '/api/subscriptions/card')
              .set('Authorization', 'Bearer ' + getAuth())
              .send({source:id})
              .end((err/*, res*/)=>{
                if (err) return reject(err);
                else resolve("ok")
              })
  })
}

export const cancelPlan = () => {
  if (fake) {
    return new Promise((res, rej)=>{
      setTimeout(res, 3000)
    })
  }
  return new Promise((resolve, reject)=>{
    superagent.delete(endpoint + '/api/subscriptions')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err/*, res*/)=>{
                if (err) return reject(err);
                else resolve("ok")
              })
  })
}

export const createOrUpdatePlan = ({id}, planId) => {
  if (fake) {
    return new Promise((res, rej)=>{
      setTimeout(res, 3000)
    })
  }
  return new Promise((resolve, reject)=>{
    superagent.post(endpoint + '/api/subscriptions')
              .set('Authorization', 'Bearer ' + getAuth())
              .send({source:id, planId})
              .end((err, res) => {
                resetPaymentProcess()
                if (err) return reject(err);
                reloadPaymentProcess()
                resolve(res.body)
              })

  })
}

const receiptStubs = [
    {date:'00/00/00', orderID:'12354678789-0', payment:'Visa **** **** ****1234', price:'$11.94'},
    {date:'00/00/00', orderID:'12354678789-0', payment:'Visa **** **** ****1234', price:'$11.94'},
    {date:'00/00/00', orderID:'12354678789-0', payment:'Visa **** **** ****1234', price:'$11.94'},
    {date:'00/00/00', orderID:'12354678789-0', payment:'Visa **** **** ****1234', price:'$11.94'}
]

function formatReciepts(json) {
  const {invoices} = json
  console.log('invoices', invoices)
  if (!invoices) return []
  return invoices.map(({number, amount, invoice_date, source = {}, planId, type: invoiceType})=>{
    const formattedDate  = moment(invoice_date * 1000).format('L')
    const formattedPrice = amount ? `${Math.floor(amount / 100)}.${amount % 100}` : '0.00'
    let type = ''

    switch (invoiceType) {
      case 'giftee':
        type = 'Gift'
        break

      case 'recipient':
        type = '*Gift'
        break

      default:
        type = planId && planId === 'NYA-UNLIMITED-YEARLY' ? 'Annual' : 'Monthly'
        break
    }


    return ({
      date: formattedDate,
      orderID: number,
      number: number || '\u2013',
      payment: `${source.brand || '****'} **** **** **** ${source.last4 || '****'}`,
      price: formattedPrice,
      type
    })
  })
}

export const fetchDataUsage = () => {
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + '/api/account/data/gdpr')
    .set('Authorization', 'Bearer ' + getAuth())
    .end((err, res) => {
      if (err) return reject(err);
      else resolve(res)
    })
  })
}

export const fetchReceipts = () => {
  if (fake) {
    return new Promise((res, rej)=>{
        setTimeout(()=>res(receiptStubs), 3000)
    })
  }
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + '/api/subscriptions/invoices')
              .set('Authorization', 'Bearer ' + getAuth())
              .end((err, res)=>{
                if (err) return reject(err);
                else resolve(formatReciepts(JSON.parse(res.text)))
              })
  })
}

export const fetchInitialTickets = () => {
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + '/api/tickets/list')
      .end((err, res) => {
        if (err) return reject(err);
        else resolve(res)
      })
  })
}

export const fetchExclusiveTickets = () => {
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + '/api/tickets')
      .set('Authorization', 'Bearer ' + getAuth())
      .end((err, res) => {
        if (err) return reject(err);
        else resolve(res)
      })
  })
}

export const fetchTicketCode = (ticketId) => {
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + `/api/tickets/${ticketId}/code`)
      .set('Authorization', 'Bearer ' + getAuth())
      .end((err, res) => {
        if (err) return reject(err);
        else resolve(res)
      })
  })
}

export const unlinkAccount = (email, username, password) => {
  return new Promise((resolve, reject) => {
    superagent.post(endpoint + `/api/account/unlink`)
      .set('Authorization', 'Bearer ' + getAuth())
      .send({email, username, password})
      .end((err, res) => {
        if (err) return reject(err);
        else resolve(res)
      })
  })
}

export const changeAccountSettings = (values) => {
  return new Promise((resolve, reject) => {
    superagent.put(endpoint + `/api/account/settings`)
      .set('Authorization', 'Bearer ' + getAuth())
      .send(values)
      .end((err, res) => {
        if (err) return reject(err);
        else resolve(res)
      })
  })
}

export const fetchPlaylists = () => {
  return new Promise((resolve, reject) => {
    superagent.get(endpoint + `/api/playlists`)
      .set('Authorization', 'Bearer ' + getAuth())
      .end((err, res) => {
        if (err) return reject(err);
        else resolve(res)
      })
  })
}

export const createPlaylist = (title, tracks = []) => {
  return new Promise((resolve, reject) => {
    superagent.post(endpoint + `/api/playlists`)
      .set('Authorization', 'Bearer ' + getAuth())
      .send({title, tracks})
      .end((err, res) => {
        if (err) return reject(err);
        else {
          window.displayNotification({type: 'playlistCreated'})
          resolve(res)
        }
      })
  })
}

export const editPlaylist = (id, updatedPlaylist, type) => {
  return new Promise((resolve, reject) => {
    superagent.put(endpoint + `/api/playlists/${id}`)
      .set('Authorization', 'Bearer ' + getAuth())
      .send(updatedPlaylist)
      .end((err, res) => {
        if (err) return reject(err);
        else {
          if (type === 'changeOrder') {
            window.displayNotification({type: 'playlistEdited'})
          } else {
            window.displayNotification({type: 'playlistRenamed'})
          }
          resolve(res)
        }
      })
  })
}

export const deletePlaylist = (id) => {
  return new Promise((resolve, reject) => {
    superagent.delete(endpoint + `/api/playlists/${id}`)
      .set('Authorization', 'Bearer ' + getAuth())
      .end((err, res) => {
        if (err) return reject(err);
        else {
          window.displayNotification({type: 'playlistDeleted'})
          resolve(res)
        }
      })
  })
}

export const addTracksToPlaylist = (playlistId, tracks = []) => {
  return new Promise((resolve, reject) => {
    superagent.post(endpoint + `/api/playlists/${playlistId}/tracks/add`)
      .set('Authorization', 'Bearer ' + getAuth())
      .send({tracks, playlistId})
      .end((err, res) => {
        if (err) return reject(err);
        else {
          window.displayNotification({type: 'songAdded'})
          resolve(res)
        }
      })
  })
}

export const removeTracksFromPlaylist = (playlistId, tracks = []) => {
  return new Promise((resolve, reject) => {
    superagent.post(endpoint + `/api/playlists/${playlistId}/tracks/remove`)
      .set('Authorization', 'Bearer ' + getAuth())
      .send({tracks, playlistId})
      .end((err, res) => {
        if (err) return reject(err);
        else {
          window.displayNotification({type: 'songRemoved'})
          resolve(res)
        }
      })
  })
}

export const getMovie = (movieId) => {
  return new Promise((resolve, reject)=>{
    superagent.get(endpoint + `/api/movie/${movieId}`)
              .end((err, res)=>{
                if (err) return reject(err);
                else resolve(res.body)
              })
  })
}

/* Local Variables:               */
/* web-mode-code-indent-offset: 2 */
/* End:                           */
