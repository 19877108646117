import React, {Component}      from 'react'
import cn from 'classnames'
import {infoCardIsLockedByID, albumIsLockedByID, filmIsLockedByID} from '../user'

export default class TitleTab extends Component {
    getPosition() {
        let {position} = this.props.item
        if (this.props.position) position = this.props.position
        return position
    }
    getLabel(){
        let {item} = this.props
        let {title, stickerStyle, labelImage, titleLabel} = item
        let position = this.getPosition()

        if(titleLabel) {
            return <img className="img-centered" src={titleLabel} />
        }
        else if (labelImage && position !== 'left') {
            return <img className="ima" src={labelImage} />
        } else {
            let long = title.length > 23
            if (item.isAlbum || item.isFilm) {
                long = title.length > 25
            }
            let tablabel = cn({'tab-label':true, [stickerStyle || 'no-sticker']:true, long})

            return <div className={tablabel}>{title}</div>
        }
    }
    render(){

        let {item, index, onClick, onMouseEnter, onMouseLeave} = this.props

        let {id, tab, sticker, stickerStyle, isAlbum, isFilm, isPlaylist} = item

        let disabled
        if (isAlbum) {
            disabled = albumIsLockedByID(id)
        } else if (isFilm) {
            disabled = filmIsLockedByID(id)
        } else {
            disabled = infoCardIsLockedByID(id)
        }

        let position = this.getPosition()

        if (isAlbum || isFilm || isPlaylist) position = 'center'

        let tabClass = cn('title-tab', {[position]:true, disabled})
        let tabimage = cn({'tab-image':true, [tab]:true, [position]:true})

        let stickerimage = null;
        if (sticker && position !== 'right') {
            stickerimage = cn({'sticker-image':true, [sticker]:true, [stickerStyle]:true})
        }

        return (
            <div className={tabClass} >
              <div className={tabimage}
                   onMouseEnter={disabled ? onMouseEnter : undefined} onMouseLeave={disabled ? onMouseLeave : undefined}
                   onClick={onClick} data-index={index}></div>
              {sticker ? <div className={stickerimage}></div> : null }
              {this.getLabel()}
            </div>
        )
    }
}
