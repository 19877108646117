export const ENTRIES_LOADED = 'ENTRIES_LOADED';
export const LOAD_ENTRIES = 'LOAD_ENTRIES';
export const LOAD_SUBSCRIBER_ENTRIES = 'LOAD_SUBSCRIBER_ENTRIES';
export const FAILED_TO_LOAD_ENTRIES = 'FAILED_TO_LOAD_ENTRIES';
export const LOAD_TRACKS = 'LOAD_TRACKS';
export const TRACKS_LOADED = 'TRACKS_LOADED';
export const FAILED_TO_LOAD_TRACKS = 'FAILED_TO_LOAD_TRACKS';
export const LOAD_ALBUMS = 'LOAD_ALBUMS';
export const ALBUMS_LOADED = 'ALBUMS_LOADED';
export const FAILED_TO_LOAD_ALBUMS = 'FAILED_TO_LOAD_ALBUMS';
export const LOAD_ARTISTS = 'LOAD_ARTISTS';
export const ARTISTS_LOADED = 'ARTISTS_LOADED';
export const FAILED_TO_LOAD_ARTISTS = 'FAILED_TO_LOAD_ARTISTS';
export const LOAD_RELATED = 'LOAD_RELATED';
export const RELATED_LOADED = 'RELATED_LOADED';
export const FAILED_TO_LOAD_RELATED = 'FAILED_TO_LOAD_RELATED';
export const ADD_PLAYLIST = 'ADD_PLAYLIST';
export const UPDATE_PLAYLIST = 'UPDATE_PLAYLIST';
export const DELETE_PLAYLIST = 'DELETE_PLAYLIST';
export const CHANGE_PLAYLIST_ITEMS_ORDER = 'CHANGE_PLAYLIST_ITEMS_ORDER';
export const SET_METADATA = 'SET_METADATA';
export const UPDATE_METADATA = 'UPDATE_METADATA';
export const RESET_METADATA = 'RESET_METADATA';
export const UPDATE_MOVIE = 'UPDATE_MOVIE';
