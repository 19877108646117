import React, {Component}       from 'react'
import _                        from 'lodash'
import SectionPage              from './components/section-page'
import {getUserInfo, sendEmail} from './services/api';
import cn                       from 'classnames'
import contactImg from '../images/section-page/contact.png'


class Select extends Component {
    constructor(props){
        super(props)

        this.state = {
            value: props.value,
            popup: false
        }

        this.clickItem = this.clickItem.bind(this)
        this.clickSelect = this.clickSelect.bind(this)
        this.closePopup = this.closePopup.bind(this)
    }
    clickSelect(e){
        e.preventDefault()
        e.stopPropagation()
        this.setState({popup:!this.state.popup})
    }
    clickItem(e){
        e.preventDefault()
        e.stopPropagation()
        let value = e.target.getAttribute('data-value')
        this.props.onChange({target: {value}})
        this.setState({popup:false})
    }
    selectedItemLabel(){
        let item = _.find(this.props.options, ({value})=>value===this.props.value)
        return item.label
    }
    closePopup() {
        this.setState({popup: false})
    }
    render(){
        return (
            <div className="select" tabIndex="0" onBlur={this.closePopup} onClick={this.clickSelect}>
              <div className="current-item">{this.selectedItemLabel()}<div className="arrow" /></div>
              {
                  this.state.popup &&
                  <div className="popup">
                    {
                        this.props.options.map(({label, value}, idx)=>{
                            let cx = cn('option', {selected:value === this.props.value})
                            return (
                                <div className={cx} onClick={this.clickItem} key={idx}
                                     data-value={value}>{label}<div className="check" />
                                </div>
                            )
                        })
                    }
                  </div>
              }
            </div>
        )
    }
}


const options = [
    {value: 'archivist', label:'Question for the Archivist'},
    {value: 'help',      label:'Customer Support'},
    {value: 'billing',   label:'Billing Issue'},
    {value: 'data',      label: 'Question about Data Usage'}
]

const link = <a href="/privacy" target="blank" >Privacy Policy</a>
const helpText = `Do you have a question about something in the Archive?\nSend a note in the form below\nand the archivist will get back to you.`
const archivistText = `Do you have a question or suggestion for the archivist?\nSend it along through the form below.\nPlease note: Your question/comment may be printed in the NYA Times-Contrarian.`
const billingText = `Do you have a billing issue or problem with your NYA subscription?\nSend a note in the form below and we will get back to you shortly.`;
const dataText = `Do you want to exercise your right to access/rectify/restrict/object to/request the erasure, delivery or transfer of your data as outlined in our`
const dataText2 = `?\n\nSend us a note and we will figure it out together.`
const successText = `SUCCESS:\nYour message was sent.\nThanks!`
const errorText = `FAILURE:\nYour message failed to send.\nPlease try again.`

export default class ContactContainer extends Component {
    constructor(props){
        super(props)
        let info    = getUserInfo()
        const email = (info.user_metadata && info.user_metadata.customEmail) || info.email

        const initialValue = options[parseInt(props.location.query.option) || 0].value;

        this.state = {
            submitEnabled:true,
            email: email || '',
            name: info.name || '',
            body: '',
            subject: initialValue,
            state: 'entry',
            option: 0
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.setKey   = this.setKey.bind(this)
    }

    setKey(key){
        return e => {
            this.setState({[key]:e.target.value})
        }
    }

    onSubmit(){

        let {name, email, body, subject, state} = this.state

        if (state === 'loading') return;

        if (!(name && email && body && subject)) return

        console.log('would email: ', {name, email, body, subject})

        this.setState({state:'loading'})

        sendEmail({
            name, 'from':email, body, subject, to:subject
        }).then(()=>{
            this.refs.body.value = ''
            this.setState({state:'ok', body: ''})
        }).catch(err=>{
            console.error('error sending email: ', err)
            this.setState({state:'error'})
        })

    }

    headerLabel(state, subject) {
        if ( state === 'loading' ) {
            return <span>Sending your message...</span>
        }
        else if ( state === 'error') {
            return <span>{errorText}</span>
        }
        else if ( state === 'ok') {
            return <span>{successText}</span>
        }
        else if (subject === 'archivist') {
            return <span>{archivistText}</span>
        } else if (subject === 'data') {
            return <span>{dataText} {link} {dataText2}</span>
        } else if ( subject === 'billing') {
            return <span>{billingText}</span>
        } else if ( subject === 'help') {
            return <span>{helpText}</span>
        }
    }
    render(){
        let { name, email, body, subject, state} = this.state

        let messageClass = cn('message', state)

        return (
            <SectionPage
                className="contact-page"
                headerImageUrl={contactImg}
                showUserMenu={true}>
                <div className="contact-form">
                    <div className={messageClass}>{this.headerLabel(state, subject)}</div>
                    <div className="form-wrapper">
                        <div>
                            <div className="label">Name</div>
                            <input type="text" ref="name" className="name" value={name} onChange={this.setKey('name')} />
                        </div>
                        <div>
                            <div className="label">Email</div>
                            <input type="text" ref="email" className="email" value={email} onChange={this.setKey('email')} />
                        </div>
                        <div>
                            <div className="label">Subject</div>
                            <Select options={options} value={subject} onChange={this.setKey('subject')} />
                        </div>
                        <div>
                            <div className="label">Message</div>
                            <textarea ref="body" className="body" value={body} onChange={this.setKey('body')} />
                        </div>
                        <div className="submit-wrapper">
                            <div className="submit" disabled={!(name && email && body && subject && state !== 'loading')} onClick={this.onSubmit}>
                                <div className="submit-label">{state === 'loading' ? 'SENDING...' : 'SUBMIT'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </SectionPage>
        )
    }
}
