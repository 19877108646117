import _ from 'lodash'

export const monthNames =  ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

export function validMonth(string){
    return _.includes(monthNames, string)
}
export function monthNumber(string){
    return _.indexOf(monthNames, string) + 1
}
export function monthName(number){
    return monthNames[number - 1]
}

