import marked from 'marked'
import React, { Component } from 'react'
import { Link } from 'react-router'
import cns from 'classnames'
import PropTypes from 'prop-types'
import VerticalCenter from '../components/vertical-center'
import { getChart } from '../topCharts'
import MusicPlayer from '../components/music-player'
import Audio from '../components/new-player/audio'
import PlaylistManager from '../components/new-player/playlist-manager'
import { getTrackById, getAlbumById } from '../drawer-items'
import { infoCardIsLockedByID, albumIsLockedByID } from '../user'

class Track extends Component {
    constructor(props, context){
        super(props, context)

        this.state = {
            hovered: false
        }
    }

    render() {
        let { itemIds, item, ind, title, lastWeek, totalWeeks,
            link, currentlyPlaying, onPlay, shrink, viewItem, isAlbumRow } = this.props

        if (_.isEmpty(item)) return null

        let {hovered} = this.state

        let scale = 35 / 66 /* for scaling down the music player inline */
        let style = {transform:`scale(${scale})`}

        let onClickHandler = (e) => {
            if (e.target.closest('.album-icon') || e.target.closest('.track-infocard-icon')) {
                viewItem(item.id)
                return
            }

            if (e.target.closest('.lastWeek') || e.target.closest('.totalWeeks')) {
                return
            }

            if (!shrink && e.target.closest('.title')) {
                onPlay(item.id, itemIds, ind)
                return
            }

            onPlay(item.id, itemIds, ind)
        }

        let onLockedClickHandler = e => {
            if (e.target.closest('.billboard-icon-wrapper')) {
                return
            }

            window.subs()
        }

        // Check if is available for free users
        const disabled = isAlbumRow ? albumIsLockedByID(item.id) : infoCardIsLockedByID(item.id);

        return (
            <div
                className={ind % 2 === 0 ? "row even" : "row odd"}
                onMouseEnter={(e) => {this.setState({hovered: true})}}
                onMouseLeave={(e) => {this.setState({hovered: false})}}
                onClick={!disabled ? onClickHandler : onLockedClickHandler}
            >
                { currentlyPlaying || hovered ?
                    <div className="music-wrapper-wrapper music-wrapper-wrapper--dummy">
                        <div className="music-wrapper" style={style}>
                            <MusicPlayer
                                disabled={disabled}
                                hovered={hovered}
                                item={item}
                                scaled={true}
                                isAlbum={isAlbumRow}
                                isInTrackListing
                                makeStateInSync
                                dummy
                            />
                        </div>
                    </div>
                    : <div className={ind == 0 ? "cell number top" : "cell number"}>{ind + 1}</div>
                }
                <div className="cell title" >
                    <div>{title}</div>
                    { !shrink && hovered &&
                        <Link to={link} className={isAlbumRow ? "billboard-icon-wrapper album-icon-wrapper" : "billboard-icon-wrapper track-infocard-icon-wrapper"}>
                            { isAlbumRow ? <img className="album-icon" src={item.albumViewImage} /> : <div className="track-infocard-icon"></div> }
                        </Link>
                    }
                </div>
                { !shrink &&
                    <div className="cell lastWeek">{lastWeek}</div>
                }
                { !shrink &&
                    <div className="cell totalWeeks">{totalWeeks}</div>
                }
            </div>
        )
    }
}

function externalizeHrefs(el) {
    let links = el.querySelectorAll('a')
    for (let i = 0; i < links.length; i++) {
        let a = links[i]
        if (location.hostname === a.hostname || !a.hostname.length) continue;
        a.setAttribute('target', '_blank')
    }
}

function getTop40() {
    const { topList } = getChart('Top40') || {}
    const items = topList.map(entry => {
        let { orastreamID, song: title, totalWeeks, yesterday, trackId} = entry
        yesterday = yesterday === null ? '--' : yesterday
        const link = '/info-card?track='+ trackId
        const item = {lastWeek: yesterday, totalWeeks, title, orastreamID, link, trackId}
        return item
    })
    return items
}

function getTop10() {
    const { topList } = getChart('Top10') || {}
    const items = topList.map(entry => {
        let { albumId, album: title, totalWeeks, lastWeek } = entry
        lastWeek = lastWeek === null ? '--' : lastWeek
        return { title, lastWeek, totalWeeks, link: '/album?id=' + albumId, albumId }
    })
    return items
}

function getTop10Byline() {
    const {updateString} = getChart('Top10') || {}
    return updateString || ''
}

function getTop40Byline() {
    const {updateString} = getChart('Top40') || {}
    return updateString || ''
}
export default class Billboard extends Component {
    constructor(props,  context){
        super(props, context)

        this.state = { currTrack: PlaylistManager.currentTrack() }
        this.audioCallback = this.audioCallback.bind(this)
        this.playlistCallback = this.playlistCallback.bind(this)
        this.viewInfoCard = this.viewInfoCard.bind(this)
        this.viewAlbumCard = this.viewAlbumCard.bind(this)
        this.onTrackElementClick = this.onTrackElementClick.bind(this)
        this.onAlbumElementClick = this.onAlbumElementClick.bind(this)
    }

    componentDidMount(){
        Audio.addCallback(this.audioCallback)
        PlaylistManager.addListener(this.playlistCallback)
        if (!this.refs.about) return
        externalizeHrefs(this.refs.about)
    }

    componentWillUnmount(){
        Audio.removeCallback(this.audioCallback)
        PlaylistManager.removeListener(this.playlistCallback)
    }

    audioCallback({state: playerStatus}) {
        this.setState({playerStatus})
    }

    playlistCallback({track: currTrack}){
        this.setState({currTrack})
    }

    viewInfoCard(id){
        let {router} = this.context
        router.push(router.createLocation(`/info-card?track=${id}`))
    }

    onTrackElementClick(id, tracks){
        if (!id) return

        const {currTrack, playerStatus} = this.state
        if (id === _.get(currTrack, 'id')) {

            if (playerStatus === 'LIVEPLAY') {
                PlaylistManager.pause()
            }

            if (playerStatus === 'PAUSED') {
                PlaylistManager.play()
            }

            return
        }

        let track  = getTrackById(id)

        let playableTracks = tracks.map((item) => {
            return getTrackById(item.id)
        })

        PlaylistManager._playItemInMiddleItems(track, playableTracks)
    }

    viewAlbumCard(id){
        let {router} = this.context
        router.push(router.createLocation(`/album?id=${id}`))
    }

    onAlbumElementClick(id){
        if (!id) return

        const {currTrack, playerStatus} = this.state
        if (id === _.get(currTrack, 'album.albumId')) {

            if (playerStatus === 'LIVEPLAY') {
                PlaylistManager.pause()
            }

            if (playerStatus === 'PAUSED') {
                PlaylistManager.play()
            }

            return
        }

        let album = getAlbumById(id)

        PlaylistManager.playAlbum(album)
    }

    render() {
        const { which, shrink } = this.props
        const { currTrack } = this.state

        const isTop40 = which === 'top40'
        const items  = isTop40 ? getTop40() : getTop10()
        const byline = isTop40 ? getTop40Byline() : getTop10Byline()

        const today     = isTop40 ? 'Today' : 'This Week'
        const yesterday = isTop40 ? 'Yesterday' : 'Last Week'
        const itemIds = items.map((item) => ({ id: isTop40 ? item.trackId : item.albumId }))


        return (
            <div className={cns("billboard " + which, {'billboard--shrink': shrink})}>
              <div className="billboard-contents">
                  { shrink ? (
                        <Link to={isTop40 ? "/news/top-40-tracks" : "/news/top-10-albums"}>
                            <div className={"header " + which} />
                        </Link>
                    ) : <div className={"header " + which} />

                  }
                  { !shrink &&
                    <div className="row labels">
                      <div className="cell number"><VerticalCenter>{today}</VerticalCenter></div>
                      <div className="cell title"><VerticalCenter>Title</VerticalCenter></div>
                      <div className="cell totalWeeks"><VerticalCenter>{yesterday}</VerticalCenter></div>
                      <div className="cell lastWeek"><VerticalCenter>Total Weeks</VerticalCenter></div>
                    </div>
                  }
                <div className="separator" />
                {   items.map( ({title,lastWeek,totalWeeks,link}, ind) => {
                        let currentlyPlaying = currTrack && (isTop40 ? currTrack.id : currTrack.album.albumId) === itemIds[ind].id
                        return (
                            <Track
                                key={"track"+ind}
                                ind={ind}
                                item={ !isTop40 ? getAlbumById(itemIds[ind].id) : getTrackById(itemIds[ind].id)}
                                shrink={shrink}
                                itemIds={itemIds}
                                title={ shrink && title.length > 26 ? title.slice(0, 23).trim() + '...' : title }
                                lastWeek={lastWeek}
                                totalWeeks={totalWeeks}
                                link={link}
                                isAlbumRow = { !isTop40 }
                                currentlyPlaying={currentlyPlaying}
                                onPlay={isTop40 ? this.onTrackElementClick : this.onAlbumElementClick}
                                viewItem={isTop40 ? this.viewInfoCard : this.viewAlbumCard}
                            />
                        )
                    })
                }
              </div>
              { !shrink &&
                  <div className="about" ref="about" dangerouslySetInnerHTML={{__html:marked(byline)}} />
              }
            </div>
        )
    }
}

Billboard.contextTypes = {
    router: PropTypes.object.isRequired
}
