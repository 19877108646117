import React, {Component}              from 'react'
import PropTypes                       from 'prop-types'
import normalizeUrl                    from 'normalize-url'
import {getListedTracksForInfoCards, getTrackById} from './drawer-items'
import InfoCardSlider                  from './components/info-card-slider'
import VerticalCenter                  from './components/vertical-center'
import FolderTabs                      from './components/folder-tabs'
import LyricsModal                     from './components/lyrics-modal'
import PostItAudio                     from './components/post-it-audio'
import VideosModal                     from './components/videos-modal'
import MusicPlayer                     from './components/music-player'
import BuyPopup                        from './components/buy-popup'
import PlaylistPopup                   from './components/playlist-popup'
import DateDisplay                     from './components/date-display'
import {LeftButton, RightButton}       from './components/browse-buttons'
import InfoCardAlbums                  from './components/info-card-albums'
import preloadInfoCard                 from './preload-infocard'
import SplashScreen                    from './splash-screen'
import _                               from 'lodash'
import cn                              from 'classnames'
import ReactTransitionGroup            from 'react-addons-transition-group'
import compareReleaseDate              from './util/compare-release-date'
import ScrollBars                      from 'react-scrollbar'
import {monthNumber}                   from './util/months'
import {setCurrentTrack,
        getCurrentDateAsQueryString}   from './currentDate'
import toTimestamp                     from './util/to-timestamp'
import {infoCardIsLockedByID}          from './user'
import PlaylistManager                 from './components/new-player/playlist-manager'
import {getThumbnail} from './services/vimeo'
import { getOrastreamToken } from './services/api';
import { updateMetadata } from './actions/metas'

import original_bs_version from '../images/info-card/stamps/original_bs_version.png'
import stamp_in_production from '../images/info-card/stamps/stamp_in_production.png'
import stamp_unreleased_demo from '../images/info-card/stamps/stamp_unreleased_demo.png'
import stamp_unreleased_instrumental from '../images/info-card/stamps/stamp_unreleased_instrumental.png'
import stamp_unreleased_live_ver from '../images/info-card/stamps/stamp_unreleased_live_ver.png'
import stamp_unreleased_livermont from '../images/info-card/stamps/stamp_unreleased_livemont.png'
import stamp_unreleased_mix from '../images/info-card/stamps/stamp_unreleased_mix.png'
import stamp_unreleased_orig_vers from '../images/info-card/stamps/stamp_unreleased_orig_vers.png'
import stamp_unreleased_song from '../images/info-card/stamps/stamp_unreleased_song.png'
import stamp_unreleased_version from '../images/info-card/stamps/stamp_unreleased_version.png'
import stamp_unreleased from '../images/info-card/stamps/stamp_unreleased.png'
import stamp_version1 from '../images/info-card/stamps/stamp_version1.png'
import stamp_version2 from '../images/info-card/stamps/stamp_version2.png'
import unfinished_recording from '../images/info-card/stamps/unfinished_recording.png'
import unreleased_2009_mix from '../images/info-card/stamps/unreleased_2009_mix.png'
import unreleased_original from '../images/info-card/stamps/unreleased_original.png'

const stampImages = {
    'original_bs_version.png': original_bs_version,
    'stamp_in_production.png': stamp_in_production,
    'stamp_unreleased_demo.png': stamp_unreleased_demo,
    'stamp_unreleased_instrumental.png': stamp_unreleased_instrumental,
    'stamp_unreleased_live_ver.png': stamp_unreleased_live_ver,
    'stamp_unreleased_livermont.png': stamp_unreleased_livermont,
    'stamp_unreleased_mix.png': stamp_unreleased_mix,
    'stamp_unreleased_orig_vers.png': stamp_unreleased_orig_vers,
    'stamp_unreleased_song.png': stamp_unreleased_song,
    'stamp_unreleased_version.png': stamp_unreleased_version,
    'stamp_unreleased.png': stamp_unreleased,
    'stamp_version1.png': stamp_version1,
    'stamp_version2.png': stamp_version2,
    'unfinished_recording.png': unfinished_recording,
    'unreleased_2009_mix.png': unreleased_2009_mix,
    'unreleased_original.png': unreleased_original,
}

class Spacer extends Component {
    render(){
        return <span className="spacer"></span>
    }
}

/* this is here to work around apparent bug in ReactCSSTransitionGroup
   every ONCE IN A WHILE it appears the transition group fails set the
   initial classes of the animation (which sets the initial positions) resulting
   in an animation glitch. hard to reproduce consistently and appears most frequently
   in firefox, occasionally in chrome, and have not yet seen it in safari.
   this animation is just a hacky workaround that sets the classes with timeouts
   to ensure the ordering.
*/
let currentInfoCardAnimation
class InfoCardAnimation extends Component {
    constructor(...args){
        super(...args)
        this.state = {animating: false, active:false}
    }
    trigger(done){
        if (currentInfoCardAnimation.match(/none$/)) {
            setTimeout(done, 10); return
        }

        let gap = 30
        this.setState({animating:true})
        setTimeout(()=>{
            this.setState({active:true})
        }, gap)
        setTimeout(()=>{
            this.setState({active:false, animating:false}, done)
        }, 1000)
    }
    componentWillEnter(done){
        this.mode = '-enter'
        this.trigger(done)
    }
    componentWillLeave(done){
        this.mode = '-leave'
        this.trigger(done)
    }
    getClassNames(){
        let {animating, active} = this.state
        let transitionClass = currentInfoCardAnimation

        if (animating) {
            return cn('info-card-animator-wrapper', {
                [transitionClass]:animating,
                [transitionClass+this.mode]:animating,
                [transitionClass+this.mode+'-active']:active
            })
        }
        else {
            return cn('info-card-animator-wrapper')
        }
    }
    render(){
        let cx = this.getClassNames()

        let entering = this.state.animating && this.mode === '-enter'
        let child = React.cloneElement(this.props.children, {entering})


        return (
            <div className={cx}>
              <VerticalCenter className={this.props.wrapperClass} key={this.props.itemKey}>
              {child}
              </VerticalCenter>
            </div>
        )
    }
}

function fontifyCopyrights(str) {

    if (!str) return str

    var c = String.fromCharCode(169)  //copyright symbol

    var ss = str.split(c)
    if (ss.length === 1) return str

    var idx=0, segments = [(<span key={idx++}>{ss.shift()}</span>)]
    while (ss.length) {
        segments.push(<span key={idx++} style={{"fontFamily":"DINPro"}}>{c}</span>)
        segments.push(<span key={idx++}>{ss.shift()}</span>)
    }

    return (<span>{segments}</span>)
}

// TODO: move this to lib?
function normalizeImageSource(src, def = "") {
    return normalizeUrl(src || def)
}

// TODO: move this to lib?
function normalizeDuration(dur) {
    try {
        const segments = dur.split(':')
        const m = _.get(segments, '[0]', 0)
        const s = _.get(segments, '[1]', 0)
        return `PT${m}M${('0'+s).slice(-2)}S`
    } catch (error) {
        return 'PT0M00S'
    }
}

class InfoCard extends Component {
    constructor(props, ctx){
        super(props, ctx)

        this.state = {
            showPopup: false,
            showPlaylistPopup: false
        }

        this.showPopup = this.showPopup.bind(this)
        this.hidePopup = this.hidePopup.bind(this)
        this.showPlaylistPopup = this.showPlaylistPopup.bind(this)
        this.hidePlaylistPopup = this.hidePlaylistPopup.bind(this)
    }
    componentDidMount() {
        const {
            track: {
                id: trackId,
                album,
                artist,
                title,
                lyrics,
                producer,
                releaseDate: { year } = {}
            } = {}
        } = this.props
        const script = []

        // TODO: move this to lib?
        console.log('%cthis.props.track', 'color:deeppink;', this.props.track)

        const siteDomain = 'https://neilyoungarchives.com'
        const siteName = 'Neil Young Archives'
        const entryTitle = title || '(no title)'
        const entryImage = normalizeImageSource(_.get(album, 'image'), `${siteDomain}/assets/img/neil-young-archives.jpg`)
        const entryUrl = `${siteDomain}/info-card?track=${trackId}`
        const artistName = _.get(_.head(artist), 'name')
        const albumId = _.get(album, 'albumId')
        const albumTitle = _.get(album, 'title')
        const albumTracks = _.get(album, 'tracks', [])

        const breadcrumbList = {
            '@context': 'http://schema.org/',
            '@type': 'BreadcrumbList',
            'itemListElement': [
                {
                    '@type': 'ListItem',
                    'position': 1,
                    'item': {
                        '@id': siteDomain,
                        'name': siteName,
                        'image': `${siteDomain}/assets/img/neil-young-archives.jpg`
                    }
                }
            ]
        }

        if (albumId) {
            breadcrumbList.itemListElement.push(
                {
                    '@type': 'ListItem',
                    'position': breadcrumbList.itemListElement.length + 1,
                    'item': {
                        '@id': `${siteDomain}/album?id=${albumId}`,
                        // originalRelease | Neil Young Archives
                        'name': _.compact([albumTitle, siteName]).join(' | '),
                        'image': entryImage
                    }
                }
            )
        }

        if (trackId) {
            breadcrumbList.itemListElement.push(
                {
                    '@type': 'ListItem',
                    'position': breadcrumbList.itemListElement.length + 1,
                    'item': {
                        '@id': entryUrl,
                        'name': `${entryTitle} | ${siteName}`,
                        'image': entryImage
                    }
                }
            )
        }

        /**
         * BreadcrumbList
         *
         * @items [home, album, track:self]
         */
        script.push(breadcrumbList)

        console.log('%cbreadcrumbList', 'color:deeppink;', breadcrumbList)

        const musicComposition = {
            '@context': 'http://schema.org',
            '@type': 'MusicComposition',
            '@id': entryUrl,
            'name': entryTitle,
            'composer': [
                {
                    '@type': 'Person',
                    'name': 'Neil Young',
                    '@id': siteDomain
                }
            ],
            'inLanguage': 'EN'
        }

        if (year) {
            musicComposition.datePublished = year
        }

        if (producer) {
            musicComposition.producer = {
                '@type': 'Person',
                'name': producer,
                '@id': entryUrl
            }
        }

        if (lyrics) {
            musicComposition.lyrics = {
                '@type': 'CreativeWork',
                'text': lyrics
            }
        }

        /**
         * MusicComposition
         *
         * @props [name, composer, producer, publishedAt, lyrics]
         */
        script.push(musicComposition)

        console.log('%cmusicComposition', 'color:deeppink;', musicComposition)

        const webPage = {
            '@context': 'http://schema.org',
            '@type': 'WebPage',
            'name': _.compact([entryTitle, artistName]).join(' | '),
            // TODO: Create contentful entry
            // 'description': 'same content as Description meta tag',
            'breadcrumb': _.compact(_.map(breadcrumbList.itemListElement, list => list.item.name.replace(` | ${siteName}`, ''))).join(' > '),
            // TODO: Create contentful entry
            // 'keywords': ['test', 'example'],
            // NOTE: significantLink are not expose to public
            // 'significantLink': [
            //     'https://neilyoungarchives.com/info-card/photos?track=t1964_0402_02',
            //     'https://neilyoungarchives.com/info-card/documents?track=t1964_0402_01',
            //     'https://neilyoungarchives.com/info-card/press?track=t1964_0402_01',
            //     'https://neilyoungarchives.com/info-card/memorabilia?track=t1964_0402_01',
            //     'https://neilyoungarchives.com/info-card?lyrics=1&track=t1964_0402_01'
            // ],
            'mainEntity': _.pick(musicComposition, ['@type', '@id', 'name', 'composer', 'inLanguage', 'producer', 'datePublished'])
        }

        if (albumId) {
            const relatedLink = albumTracks
                                .filter(track => track.id !== trackId)
                                .map(track => `${siteDomain}/info-card?track=${track.id}`)

            if (!_.isEmpty(relatedLink)) {
                relatedLink.shift(`${siteDomain}/album?id=${albumId}`)
                webPage.relatedLink = relatedLink
            }
        }

        console.log('%cwebPage', 'color:deeppink;', webPage)

        /**
         * WebPage
         *
         * @props [name, desc, breadcrumb, keywords (to add), significantLink, relatedLink, mainEntity (MusicComposition)]
         */
        script.push(webPage)

        if (albumId) {


            const musicAlbum = {
                '@context': 'http://schema.org/',
                '@type': 'MusicAlbum',
                'name': albumTitle,
                'image': entryImage,
                'url': entryUrl,
                // NOTE: Create contentful entry?
                // 'genre': '',
                'numtracks': albumTracks.length,
                'track': albumTracks.map(({ id, title, length }, inx) =>
                    ({
                        '@type': 'MusicRecording',
                        'position': inx + 1,
                        'name': title,
                        'url': `${siteDomain}/info-card?track=${id}`,
                        'duration': normalizeDuration(length)
                    }))
            }

            if (artistName) {
                musicAlbum.byArtist = {
                    '@type': 'MusicGroup',
                    'name': artistName
                }
            }

            /**
             * MusicAlbum
             *
             * @props [name, byArtist, img, url, genre (to add), numtracks, track]
             */
            script.push(musicAlbum)

            console.log('%cmusicAlbum', 'color:deeppink;', musicAlbum)
        }

        /**
         * MusicGroup
         *
         * @props [name*, alternateName*, logo*, image*, url (home?), ...]
         */
        // script.push(
        //     {
        //         "@context": "http://schema.org",
        //         "@type": "MusicGroup",
        //         "@id": "https://neilyoungarchives.com/album?id=A_064&photo=AA_A_064_01&tab=songs",
        //         "name": "The Squires", // artist
        //         "alternateName": "The Squires - Westgate High School", // artist -
        //         "logo": {
        //             "@type": "ImageObject",
        //             "url": "https://neilyoungarchives.com/images/23a8c1bcfe4dcc4caf09519d4e730c58.png"
        //         },
        //         "image": {
        //             "@type": "ImageObject",
        //             "url": "https://neilyoungarchives.com/album?id=A_064&photo=AA_A_064_01&tab=songs&x=0.29236326289437775&y=0.1938189038335227&zoom=1"
        //         },
        //         "url": "https://neilyoungarchives.com",
        //         "genre": [
        //             "Classic Rock"
        //         ],
        //         "sameAs": [
        //             "https://www.facebook.com/username",
        //             "https://twitter.com/username",
        //             "https://instagram.com/username",
        //             "https://www.youtube.com/user/username",
        //             "https://soundcloud.com/username",
        //             "https://plus.google.com/username"
        //         ],
        //         // musicians
        //         "member": [
        //             {
        //                 "@type": "OrganizationRole",
        //                 "member": {
        //                     "@type": "Person",
        //                     "name": "Neil Young",
        //                     "sameAs": "https://yourwebsite.com/bio/"
        //                 },
        //                 "roleName": [
        //                     "guitar",
        //                     "vocals",
        //                     "harmonica"
        //                 ]
        //             },
        //             {
        //                 "@type": "OrganizationRole",
        //                 "member": {
        //                     "@type": "Person",
        //                     "name": "Allan Bates",
        //                     "sameAs": "https://yourwebsite.com/bio/"
        //                 },
        //                 "roleName": [
        //                     "guitar"
        //                 ]
        //             },
        //             {
        //                 "@type": "OrganizationRole",
        //                 "member": {
        //                     "@type": "Person",
        //                     "name": "Ken Koblun",
        //                     "sameAs": "https://yourwebsite.com/bio/"
        //                 },
        //                 "roleName": [
        //                     "guitar"
        //                 ]
        //             },
        //             {
        //                 "@type": "OrganizationRole",
        //                 "member": {
        //                     "@type": "Person",
        //                     "name": "Ken Smyth",
        //                     "sameAs": "https://yourwebsite.com/bio/"
        //                 },
        //                 "roleName": [
        //                     "drums"
        //                 ]
        //             },
        //             {
        //                 "@type": "OrganizationRole",
        //                 "member": {
        //                     "@type": "Person",
        //                     "name": "Barry Taylor",
        //                     "sameAs": "https://yourwebsite.com/bio/"
        //                 },
        //                 "roleName": [
        //                     "engineer"
        //                 ]
        //             }
        //         ]

        //     }
        // )

        const meta = [
            { property: 'og:url', content: entryUrl },
            { property: 'og:title', content: `${entryTitle} | ${siteName}` },
            { property: 'og:image', content: entryImage },
        ]

        if (lyrics) {
            meta.push({ property: 'og:description', name: 'description', content: lyrics })
        }

        updateMetadata({ title, meta, script })
    }
    buyLinks(){
        let {track} = this.props
        let buyLinks = []

        if (track.album && track.album.purchaseOptions) {
            if (track.album.purchaseOptions.pono) {
                const token = getOrastreamToken();
                const tokenString = token ? `?user_jwt=${token}` : '';
                buyLinks.push({title: 'high-res from\nXstream by NYA', link: track.album.purchaseOptions.pono + tokenString, type: 'xstream'});
            }

            if (track.album.purchaseOptions.reprise) {
                buyLinks.push({title: 'from neil young\'s\ngreedy hand store', link: track.album.purchaseOptions.reprise, type: 'handStore'});
            }
        }

        return buyLinks.length > 0 ? buyLinks : null
    }
    showPlaylistPopup(){
        this.setState({showPlaylistPopup:true})
    }
    hidePlaylistPopup(){
        this.setState({showPlaylistPopup:false})
    }
    showPopup(){
        this.setState({showPopup:true})
    }
    hidePopup(){
        this.setState({showPopup:false})
    }
    render() {
        let { track, entering } = this.props
        let { showPopup, showPlaylistPopup } = this.state
        let { color, title, videos, orastream,
             unreleasedStamp, disclaimer,
             primaryTrack, linkedTrack, moreInfo,
             musiciansInProgress, id,
             releaseDateText, releaseDate, lyrics,
             audioFile, postItIcon } = track
        let { year, month, day } = releaseDate
        let cx = cn({card:true, [color]:true})
        let { router } = this.context

        const locked = infoCardIsLockedByID(id)

        let date = releaseDateText || `${month}/${day}/${year}`

        let hasVideos = videos && videos.length
        let hasAudio  = !!orastream
        let hasLyrics = !!lyrics

        let buyLinks    = this.buyLinks()
        let hasBuyLinks = buyLinks && buyLinks.length > 0

        let hasPostIt = !!(linkedTrack || moreInfo)
        let hasLinkBack = !hasPostIt && !!(primaryTrack)

        let { publisher, session, performer, producer, musicians, label, freeToDownload } = track

        const token = getOrastreamToken();
        const freeDownloadLinks = [
          {title: 'get free high-res track\nfrom Xstream by NYA store', link: `https://nya.orastream.com/?claim_jwt=${token}`, type: 'xstream'}
        ]
        const freeDownloadButton = {title: 'get free high-res track\nfrom Xstream by NYA store', link: `https://nya.orastream.com/?claim_jwt=${token}`, type: 'freeDownload'}

        let hasAsterix = (date.indexOf('*') > -1)
        if (hasAsterix) date = date.replace(/\*/g,'')

        publisher = fontifyCopyrights(publisher)

        track.showAreYouStillListeningModal = true

        return (
            <div className={cx}>
              <FolderTabs track={track} selected="title" disabled={locked} />
              <div className="folder-content">
                <div className="folder-image"></div>
                <div className="card-sticker">
                  { unreleasedStamp && <img className="stamp" src={stampImages[unreleasedStamp]} /> }
                  <div className="section song">
                    <ScrollBars>
                      { typeof title === 'string' && title.trim()}
                      <div className="publisher">{publisher}</div>
                    </ScrollBars>
                  </div>
                  <div className="section performer">
                    <ScrollBars>
                      { typeof performer === 'string' && performer.trim()}
                    </ScrollBars>
                  </div>
                  <div className="section date">
                    <Spacer />
                    {date}
                    { hasAsterix && <div className="asterix">*</div> }
                  </div>
                  <div className="section session">
                    <ScrollBars>
                      {typeof session === 'string' && session.trim()}
                    </ScrollBars>
                  </div>
                  <div className="section producer-slash-label">
                    <div className="section producer">
                      <ScrollBars>
                        { typeof producer === 'string' && producer.trim()}
                      </ScrollBars>
                    </div>
                    <div className="section label"><Spacer />{label}</div>
                  </div>
                  <div className="section musicians">
                    <ScrollBars>{musicians}</ScrollBars>
                    { musiciansInProgress && <div className="in-progress-stamp" /> }
                  </div>
                  { disclaimer && <div className="disclaimer">{disclaimer}</div> }
                </div>
                <div className="buttons">
                    { hasAudio &&
                        <MusicPlayer makeStateInSync playInMiddle showAreYouStillListeningModal
                            item={track}
                            disabled={locked}
                        />
                    }
                    { hasAudio &&
                        <div className='plusButton' onClick={this.showPlaylistPopup}>
                            { showPlaylistPopup &&
                                <div className="playlist-popup-wrapper">
                                    <PlaylistPopup
                                        hidePopup={this.hidePlaylistPopup}
                                        style={{top: '-25px', left: '-30px'}}
                                        tracks={[track.id]}
                                    />
                                </div>
                            }
                        </div>
                    }
                    <div className="rsdSearchButton" onClick={() => {router.push('/find-store')}}></div>
                    { freeToDownload &&
                        <div className="freeDownload" onClick={this.showPopup}>
                            { showPopup &&
                                <div className="buy-popup-wrapper">
                                    <BuyPopup
                                        freeTrack
                                        buyLinks={freeDownloadLinks}
                                        hidePopup={this.hidePopup}
                                        buttonLink={freeDownloadButton}
                                    />
                                </div>
                            }
                        </div>
                    }
                    { (!freeToDownload && hasBuyLinks) &&
                        <div className="buy" onClick={this.showPopup}>
                            { showPopup &&
                                <div className="buy-popup-wrapper">
                                    <BuyPopup
                                        buyLinks={buyLinks}
                                        hidePopup={this.hidePopup}
                                    />
                                </div>
                            }
                        </div>
                    }
                    { !!hasVideos && <div className={cn('video', {disabled:locked})} onClick={locked ? window.subs : this.props.onVideoClick} /> }
                    { hasLyrics && <div className="lyrics" onClick={this.props.onLyricsClick} /> }
                </div>
                <InfoCardAlbums track={track} entering={entering} onCoverClick={this.props.onCoverClick} />
                { hasLinkBack && <div className="postit-return" onClick={this.props.onPostItReturnClick}/> }
                { hasPostIt && <div className="postit-link" onClick={this.props.onPostItClick}/> }
                { audioFile &&
                    <div
                        className={cn("postit-audio", {"postit-audio--default": !postItIcon})}
                        onClick={this.props.onPostItAudioClick}>
                        { postItIcon && <img src={postItIcon} /> }
                    </div>
                }
                </div>
            </div>
        )
    }
}

InfoCard.contextTypes = {
    router: PropTypes.object.isRequired
}

function getIndex(id){
    let items = getListedTracksForInfoCards()
    return _.findIndex(items, (item)=>{return item.id === id})
}
function getPct(id){
    let items = getListedTracksForInfoCards()
    let idx = getIndex(id)
    return (idx / (items.length - 1)) * 100
}
function getId(pct){
    let items = getListedTracksForInfoCards()
    return items[Math.floor((pct / 100) * (items.length - 1))].id
}

let assetsLoaded = false

export default class InfoCardContainer extends Component {
    constructor(props, b){
        super(props, b)

        let {query} = props.location
        let id = this.getTrackIdFromQuery(query)

        setCurrentTrack(getTrackById(id))

        let position = getPct(id)
        let index    = getIndex(id)

        let showLyrics = query.lyrics === '1'
        let showVideos = query.videos === '1'
        let showInfo   = query.info   === '1'
        let showPostIt = query.postit === '1'
        let videoThumbnails = []

        this.state = {position, id, index, showLyrics,
                      showInfo, showPostIt, showVideos, assetsLoaded, videoThumbnails}

        if (!assetsLoaded) {
            this.loadingPromise = preloadInfoCard().then(()=>{ assetsLoaded = true })
        }

        this.onScroll          = this.onScroll.bind(this)
        this.onLeft            = this.onLeft.bind(this)
        this.onRight           = this.onRight.bind(this)
        this.viewOnTimeline    = this.viewOnTimeline.bind(this)
        this.viewTrack         = this.viewTrack.bind(this)
        this.showAlbum         = this.showAlbum.bind(this)
        this.showLyrics        = this.showLyrics.bind(this)
        this.hideLyrics        = this.hideLyrics.bind(this)
        this.showVideos        = this.showVideos.bind(this)
        this.hideVideos        = this.hideVideos.bind(this)
        this.showInfo          = this.showInfo.bind(this)
        this.hideInfo          = this.hideInfo.bind(this)
        this.hidePostIt        = this.hidePostIt.bind(this)
        this.backToCabinet     = this.backToCabinet.bind(this)
        this.onDragStart       = this.onDragStart.bind(this)
        this.onDragEnd         = this.onDragEnd.bind(this)
        this.searchByDate      = this.searchByDate.bind(this)
        this.postItClick       = this.postItClick.bind(this)
        this.postItAudioClick  = this.postItAudioClick.bind(this)
        this.postItReturnClick = this.postItReturnClick.bind(this)

        this.onPlaylistManagerEvent = _.debounce(this.onPlaylistManagerEvent.bind(this), 1200)

    }
    getTrackIdFromQuery(q){

        if (_.has(q, 'track') || _.has(q, 'currTrack')) {
            return q.track || q.currTrack
        } else if (_.has(q, 'year') && _.has(q, 'month')) {
            let {year, month, day} = q

            day   = day || 1
            month = monthNumber(month)
            year  = parseInt(year, 10)
            day   = parseInt(day, 10)

            let date  = {year,month,day}
            let track = this.findNearestTrack(date)

            return track.id
        }

        return getListedTracksForInfoCards()[0].id

    }
    componentWillMount(){
        if (assetsLoaded !== this.state.assetsLoaded) {
            this.setState({assetsLoaded})
        } else if (!assetsLoaded) {
            this.loadingPromise.then(()=>{ this.setState({assetsLoaded}) })
        }
    }
    componentDidMount(){
        this.getThumbnailsUrls(this.state.id)
        PlaylistManager.addListener(this.onPlaylistManagerEvent)
    }
    componentWillUnmount(){
        PlaylistManager.removeListener(this.onPlaylistManagerEvent)
    }
    componentWillUpdate(nextProps, nextState){
        let {track, info} = nextProps.location.query
        if  (!info && this.state.showInfo) this.setState({showInfo:false})
        if (track && track !== this.state.id && track !== nextState.id) {
            this.viewTrack(track, 0)
        }
    }
    findNearestTrack(date){
        let target = toTimestamp(date)
        let items  = getListedTracksForInfoCards()
        let best, bestDiff;

        for (let i = 0; i < items.length; i++){
            let item = items[i]
            if (!item.releaseDate) continue

            let time = toTimestamp(item.releaseDate)
            let diff = Math.abs(target - time)

            if (!best || diff < bestDiff) {
                best     = item
                bestDiff = diff
            } else if (diff > bestDiff) {
                break
            }
        }

        return best
    }
    searchByDate(date){
        let best = this.findNearestTrack(date)
        let curr = getTrackById(this.state.id)
        let dir  = compareReleaseDate(best.releaseDate, curr.releaseDate) || -1
        this.viewTrack(best.id, dir)
    }
    onLeft(){
        if (this.state.animating) return
        this.move(-1)
    }
    onRight(){
        if (this.state.animating) return
        this.move(+1)
    }
    move(dir){
        let items = getListedTracksForInfoCards()
        let max   = items.length - 1
        let index = Math.max(0, Math.min(max, dir + this.state.index))
        let id    = items[index].id
        this.viewTrack(id, dir)
    }
    onPlaylistManagerEvent({state, track}){
        if (this.context.router.getCurrentLocation().pathname !== '/info-card') return
        if (state !== 'WILLPLAY') return
        this.viewTrack(track.id, PlaylistManager.isPrevOrNext())
    }
    viewTrack(id, dir, position=getPct(id)){
        let index = getIndex(id)
        let item  = getTrackById(id)

        let changed = id !== this.state.id

        let pushState = dir !== 0 //hack to work around post it notes linked tracks

        const keepLyrics = this.state.showLyrics && item.lyrics
        const keepInfo = this.state.showInfo && item.moreInfo
        const keepPostIt = this.state.showPostIt && item.audioFile
        const keepVideos = this.state.showVideos && (item.videos && item.videos.length > 0)

        this.setState({
            position,
            id,
            index,
            lastDirection: dir,
            dragging: false,
            animating:true,
            showInfo: keepInfo,
            showPostIt: keepPostIt,
            showLyrics: keepLyrics,
            showVideos: keepVideos,
        }, ()=>{ if (changed) this.updateUrl(id, pushState, keepLyrics, keepInfo, keepVideos) })

        this.getThumbnailsUrls(id);

        setTimeout(()=>{ this.setState({animating:false}) }, 1100)

    }
    postItClick(e){
        e.preventDefault()
        e.stopPropagation()

        let track = getTrackById(this.state.id)

        let {moreInfo, linkedTrack} = track

        if (moreInfo) {
            this.showInfo()
        } else {
            let {router} = this.context
            router.push(router.createLocation(`/info-card?track=${linkedTrack.id}`))
        }
    }
    postItAudioClick(e){
        e.preventDefault()
        e.stopPropagation()

        this.showPostIt()
    }
    postItReturnClick(e){
        e.preventDefault()
        e.stopPropagation()

        this.context.router.goBack()
    }
    onDragStart(){
        this._startPosition = this.state.position
        this.setState({dragging: true})
    }
    onScroll(position){
        this._endPosition = position

        let id     = getId(position)
        let track  = getTrackById(id)

        this.setState({position, sliderDate: track.releaseDate})
    }
    onDragEnd(){
        let direction = this._endPosition < this._startPosition ? -1 : 1
        let position  = this._endPosition

        let id    = getId(position)

        this.viewTrack(id, direction, position)
    }
    updateUrl(id, pushState=false, keepLyrics, keepInfo, keepVideos){
        this.updateQuery({track:id, year:null, month:null, day:null, lyrics: keepLyrics, info: keepInfo, videos: keepVideos}, pushState)
        setCurrentTrack(getTrackById(id))
    }
    updateQuery(params, pushState=false){
        let router   = this.context.router
        let pathname = this.props.location.pathname
        let query    = _.clone(this.props.location.query)

        Object.keys(params).forEach(key => {
            let value = params[key]
            if (typeof value === 'undefined' || value === null || value === false) {
                delete query[key]
            } else {
                query[key] = value
            }
        })

        let location = router.createLocation({pathname, query})
        //query parameters are duplicated if this is not deleted for some reason
        delete location.search

        if (pushState) router.push(location)
        else           router.replace(location)
    }
    backToCabinet(){
        let {router} = this.context
        router.push(router.createLocation('/drawer'))
    }
    viewOnTimeline(){
        let {router} = this.context
        let location = router.createLocation('/timeline-months?' + getCurrentDateAsQueryString(true))
        router.push(location)
    }
    showAlbum(albumId){
        let {router} = this.context
        router.push(router.createLocation(`/album?id=${albumId}`))
    }
    showInfo(){
        this.setState({showLyrics: false, showVideos: false, showInfo:true, showPostIt:false})
        this.updateQuery({lyrics:null, videos:null, info:'1', postit:null})
    }
    hideInfo(){
        this.setState({showInfo: false})
        this.updateQuery({info: null})
    }
    showPostIt(){
        this.setState({showLyrics: false, showVideos: false, showInfo:false, showPostIt:true})
        this.updateQuery({lyrics:null, videos:null, info:null, postit:'1'})
    }
    hidePostIt(){
        this.setState({showPostIt: false})
        this.updateQuery({postit: null})
    }
    showLyrics(){
        this.setState({showLyrics: true, showVideos: false, showInfo:false, showPostIt:false})
        this.updateQuery({lyrics: '1', videos:null, info:null, postit:null})
    }
    hideLyrics(){
        this.setState({showLyrics: false})
        this.updateQuery({lyrics: null})
    }
    showVideos(){
        this.setState({showLyrics: false, showVideos: true, showInfo:false, showPostIt:false})
        this.updateQuery({lyrics:null, videos: '1', info:null, postit:null})
    }
    hideVideos(){
        this.setState({showVideos: false})
        this.updateQuery({videos: null})
    }
    getThumbnailsUrls(trackId) {
        // This method fetchs from vimeo api the urls for thumbnails, it is loaded here so there is no awaiting when its time to show the videos modal
        let track = getTrackById(trackId)
        let videos = track.videos.filter(v => v) // Check if values are defined, could be null if videos were deleted from contentful

        if(videos.length > 0) {
            let thumbnailsPromises = videos.map(v => getThumbnail(v.vimeoId))
            Promise.all(thumbnailsPromises).then( results => {
                this.setState({
                    videoThumbnails: results
                })
            })
        }
    }
    renderContent(){
        let {id, position, index, showLyrics,
             sliderDate, dragging, animating,
             showVideos, lastDirection, showInfo, showPostIt } = this.state

        let items     = getListedTracksForInfoCards()
        let item      = getTrackById(id)

        let listed    = index > -1
        let moreLeft  = listed && index > 0
        let moreRight = listed && index < items.length - 1

        let transition = (lastDirection < 0 ? 'left' :
                          (lastDirection > 0 ? 'right' : 'none'));

        currentInfoCardAnimation = `info-card-animate-${transition}`;

        let wrapperClass = (animating?transition:'')+' wrapper'

        return (
            <div id="info-card" className="info-card-section">
              <div className="info-card-content">
                <ReactTransitionGroup>
                  <InfoCardAnimation key={item.id} wrapperClass={wrapperClass} itemKey={item.id}>
                    <InfoCard track={item}
                              onCoverClick={this.showAlbum}
                              onVideoClick={this.showVideos}
                              onLyricsClick={this.showLyrics}
                              onPostItClick={this.postItClick}
                              onPostItAudioClick={this.postItAudioClick}
                              onPostItReturnClick={this.postItReturnClick} />
                  </InfoCardAnimation>
                </ReactTransitionGroup>
              </div>
              <LeftButton onClick={this.onLeft} show={moreLeft} />
              <RightButton onClick={this.onRight} show={moreRight} />
              <div className="bottom-panel">
                <div className="bottom-panel-center" />
                <div className="timeline-button" onClick={this.viewOnTimeline} />
                <div className="slider-container">
                  <InfoCardSlider key="the-slider" pct={position}
                                  onScroll={this.onScroll}
                                  dragStart={this.onDragStart}
                                  dragEnd={this.onDragEnd}
                                  dragging={dragging}
                                  disabled={animating}
                                  date={sliderDate} />
                </div>
                <div className="date-container">
                  <DateDisplay date={item.releaseDate} onSearch={this.searchByDate} />
                </div>
              </div>
              { showPostIt ? <PostItAudio track={item} onClose={this.hidePostIt} /> : null }
              { showInfo   ? <LyricsModal track={item}
                                          markdown={true}
                                          content="info"
                                          onClose={this.hideInfo} /> : null }
              { showLyrics ? <LyricsModal track={item} onClose={this.hideLyrics} /> : null }
              { showVideos ? <VideosModal track={item} onClose={this.hideVideos} thumbnails={this.state.videoThumbnails} /> : null }
            </div>
        )
    }
    renderLoading(){
        return <SplashScreen loadState={-1}/>
    }
    render(){
        if (this.state.assetsLoaded) {
            return this.renderContent()
        } else {
            return this.renderLoading()
        }
    }
}

InfoCardContainer.contextTypes = {
    router: PropTypes.object.isRequired
}
