import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Auth from '../services/auth'

export default class AccountPulldown extends Component {
    constructor(props, context){
        super(props, context)

        this.onClick           = this.onClick.bind(this)
        this.clickAccount      = this.clickAccount.bind(this)
        this.clickSubscription = this.clickSubscription.bind(this)
    }
    clickAccount(){
        let {router} = this.context
        let pathname = '/account'
        let location = router.createLocation({pathname})
        delete location.search

        router.push(location)
    }
    clickSubscription(){
        const {router} = this.context
        router.push(router.createLocation('/account?screen=subscription'))
    }
    onClick(){
        this.props.onClose()
    }
    componentDidMount(){
        document.addEventListener('click', this.onClick)
    }
    componentWillUnmount(){
        document.removeEventListener('click', this.onClick)
    }
    render() {
        return (
            <div className="account-pulldown">
              <div className="account" onClick={this.clickAccount}>ACCOUNT</div>
              <div className="subscription" onClick={this.clickSubscription}>SUBSCRIPTION</div>
              <div className="logout" onClick={Auth.logout}>LOG OUT</div>
            </div>
        )
    }
}

AccountPulldown.contextTypes = {
    router: PropTypes.object.isRequired
}
