import React, {Component, Fragment}  from 'react'
import Helmet                        from 'react-helmet';
import MainContainer                 from './main-container';
import DrawerContainer               from './drawer';
import InfoCardContainer             from './info-card';
import TimelineYearsContainer        from './timeline-years';
import TimelineMonthsContainer       from './timeline-months';
import PhotosContainer               from './photos';
import DocumentsContainer            from './documents';
import PressContainer                from './press';
import MemorabiliaContainer          from './memorabilia';
import DrawerFront                   from './drawer-front';
import AlbumModal                    from './album-modal';
import GlobalMenu                    from './global-menu';
import VideoPlayerContainer          from './video-player';
import LiveStreamContainer           from './live';
import LiveStreamTeaserContainer     from './live-teaser';
import FAQContainer                  from './faq';
import CreditsContainer              from './credits';
import AudioSetupContainer           from './audio-setup';
import SearchContainer               from './search';
import SearchRecordStoreDayContainer from './search-record-store-day';
import News                          from './news/news';
import Newspaper                     from './news/newspaper';
import NewsArticleContainer          from './news/article';
import Top40Tracks                   from './news/top-40-tracks';
import Top10Albums                   from './news/top-10-albums';
import NewsletterContainer           from './newsletter';
import ContactContainer              from './contact';
/* import AccountContainer              from './account';*/
import AccountInfo                   from './account-info/index';
import MovieNight                    from './movie-night/movie-night';
import Page404                       from './404.js';
import TermsContainer                from './terms.js';
import PrivacyPolicyContainer        from './privacy-policy.js';
import TestContainer                 from './test';
import { Provider, connect }         from 'react-redux';
import { Router, Route, IndexRoute,
         applyRouterMiddleware}      from 'react-router';
import { useScroll }                 from 'react-router-scroll';
import { showLiveStream,
         showLiveStreamTeaser}       from './toggleLiveStreams'
import HOCRequireAuth                from './hoc-require-auth'
import HOCRemoveAuth                 from './hoc-remove-auth'

const routes = (
  <Route path="/" component={MainContainer}>
    <IndexRoute component={DrawerFront} />
    <Route component={HOCRequireAuth}>
      <Route path="/video" component={VideoPlayerContainer} />
      {showLiveStream && <Route path="/live" component={LiveStreamContainer} />}
      {showLiveStreamTeaser && <Route path="/live" component={LiveStreamTeaserContainer} />}
    </Route>

    <Route component={HOCRemoveAuth}>
      <Route path="/info-card/photos" component={PhotosContainer} />
      <Route path="/info-card/documents" component={DocumentsContainer} />
      <Route path="/info-card/press" component={PressContainer} />
      <Route path="/info-card/memorabilia" component={MemorabiliaContainer} />
      <Route path="/account" component={AccountInfo} />
      <Route path="/drawer" component={DrawerContainer} />
      <Route path="/menu" component={GlobalMenu} />
      <Route path="/info-card" component={InfoCardContainer} />
      <Route path="/timeline" component={TimelineYearsContainer} />
      <Route path="/timeline-months" component={TimelineMonthsContainer} />
      <Route path="/album" component={AlbumModal} />
      <Route path="/playlist" component={AlbumModal} />
      <Route path="/film" component={AlbumModal} />
      <Route path="/movie-night" component={MovieNight} />
      <Route path="/news" component={Newspaper}>
        <Route path="top-40-tracks" component={Top40Tracks} />
        <Route path="top-10-albums" component={Top10Albums} />
        <Route path=":page/article" component={NewsArticleContainer} />
        <Route path=":page" component={News} />
      </Route>
      <Route path="/news-article" component={NewsArticleContainer} />
      <Route path="/newsletter" component={NewsletterContainer} />
      <Route path="/faq" component={FAQContainer} />
      <Route path="/credits" component={CreditsContainer} />
      <Route path="/audio-setup" component={AudioSetupContainer} />
      <Route path="/terms" component={TermsContainer} />
      <Route path="/privacy-policy" component={PrivacyPolicyContainer} />
      <Route path="/contact" component={ContactContainer} />
      <Route path="/search" component={SearchContainer} />
      <Route path="/find-store" component={SearchRecordStoreDayContainer} />
      <Route path="/404" component={Page404} />
      <Route path="/test" component={TestContainer} />
      <Route path="*" component={Page404} />
    </Route>
  </Route>
)

class AppRoutes extends Component {
    render(){
        const {store, history, metas} = this.props
        return (
            <Provider store={store}>
              <Fragment>
                <Helmet {...metas}/>
                <Router history={history} routes={routes} render={applyRouterMiddleware(useScroll())}/>
              </Fragment>
            </Provider>
        )
    }
}

const mapStateToProps = function (state) {
  const { metas } = state
  return { metas }
}

export default connect(mapStateToProps)(AppRoutes)