import React, {Component}                               from 'react';
import {inverseScale, setTimelineScale, timelineScale } from '../scale';
import { getAlbumById }                                 from '../drawer-items'
import HorizontalSlider                                 from './horizontal-slider';
import TimelineOverview                                 from './timeline-overview';
import _                                                from 'lodash'
import hasClass                                         from 'has-class'

/* shared code between timeline and timeline-months components
 * naming is a bit wierd in places since it was written before
 * there were two timelines (uses year or years where item or items
 * would make more sense)*/

let topBarHeight    = 110
let bottomBarHeight = 110
//get height of the content area

function measureHeight(){
    return (inverseScale() * (window.innerHeight)) - (topBarHeight + bottomBarHeight)
}
//get width of the content area
function measureWidth() {
    return inverseScale() * window.innerWidth
}

function end(event){
    if (event.stopPropagation()) event.stopPropagation()
    if (event.preventDefault()) event.preventDefault()

    return false;
}

class Page extends Component {
    constructor(props,b){
        super(props,b);
        this.mouseDown  = this.mouseDown.bind(this)
        this.mouseMove  = this.mouseMove.bind(this)
        this.mouseUp    = this.mouseUp.bind(this)
        this.touchStart = this.touchStart.bind(this)
        this.touchMove  = this.touchMove.bind(this)
        this.touchEnd   = this.touchEnd.bind(this)
        this.onBGClick  = this.onBGClick.bind(this)
    }
    shouldComponentUpdate(newprops){
        return newprops.item               !== this.props.item             ||
               newprops.original           !== this.props.original         ||
               newprops.additional         !== this.props.additional       ||
               newprops.selectedAlbumId    !== this.props.selectedAlbumId  ||
               newprops.currentItemId      !== this.props.currentItemId    ||
               newprops.currentItemIndex   !== this.props.currentItemIndex ||
               newprops.currentTrackId     !== this.props.currentTrackId   ||
               newprops.currentAlbumId     !== this.props.currentAlbumId   ||
               newprops.highlightedAlbumId !== this.props.highlightedAlbumId
    }
    _mouseMap(){
        return {mousemove: this.mouseMove, mouseup:this.mouseUp}
    }
    _touchMap(){
        return {touchmove:this.touchMove, touchend:this.touchEnd}
    }
    mouseDown(e){
        this._addHandlers(this._mouseMap());
        this._lastOffset = e.clientX
        this._didMove = false
        return end(e)
    }
    mouseMove(e){
        let offs = this._lastOffset - e.clientX

        this._lastOffset = e.clientX
        this._didMove = true

        this.props.scrollByPixels(offs)

        /* console.log('drag: ', offs) */
        return end(e)
    }
    mouseUp(e){
        this._removeHandlers(this._mouseMap())
        this.props.pixelScrollEnded && this.props.pixelScrollEnded()
        return end(e)
    }
    touchStart(e){
        if (e.touches.length > 1) return
        this._addHandlers(this._touchMap());
        this._lastOffset = e.touches[0].clientX
        this._didMove = false
        return end(e)
    }
    touchMove(e){
        if (e.touches.length > 1) return
        let clientX = e.touches[0].clientX
        let offs = this._lastOffset - clientX

        this._lastOffset = clientX
        this._didMove = true

        this.props.scrollByPixels(offs)

        /* console.log('drag: ', offs) */
        return end(e)
    }
    touchEnd(e){
        this._removeHandlers(this._touchMap())
        this.props.pixelScrollEnded && this.props.pixelScrollEnded()
        return end(e)
    }
    _addHandlers(map){
        _.each(map, (value, key)=>{
            document.addEventListener(key, value, false)
        })
    }
    _removeHandlers(map){
        _.each(map, (value, key)=>{
            document.removeEventListener(key, value, false)
        })
    }
    onBGClick(e){
        //ignore drags
        if (this._didMove) return

        //bail if it is not one of the page backgrounds
        if (!(hasClass(e.target, 'year') || hasClass(e.target, 'month')))
            return

        this.props.onBGClick(e)

        return end(e)
    }
    render(){
        let {id} = this.props.item

        return (<div className="page" key={id}
                     onClick={this.onBGClick}
                     onMouseDown={this.mouseDown}
                     onTouchStart={this.touchStart}
                >
                {this.props.getPageContents(this.props)}
        </div>)
    }
}


/* for the space to the left of the first item, and to the right of last item */
/* (having these in makes the math come out simpler) */
class PageSpacer extends Component {
    render(){
        let {pageWidth, pageHeight} = this.props
        let width = pageWidth, height = pageHeight
        let style = {width:width+'px', height:height+'px'}
        return <div key={-1} className="page-spacer" style={style} >X</div>
    }
}


export class Pages extends Component {
    constructor(props, b){
        super(props, b)

        let {pageHeightToWidth} = props
        let years = this.props.items

        let state = Pages.measure(years, pageHeightToWidth)

        state.years = years;

        this.state = state

        this.onAlbumSelect       = this.onAlbumSelect.bind(this)
        this.onResize            = this.onResize.bind(this)
        this.scrollByPixels      = this.scrollByPixels.bind(this)
        this.hideBubbleAndTracks = this.hideBubbleAndTracks.bind(this)
    }
    shouldComponentUpdate(newprops, newstate){

        let {pageW, pageH, width, height} = this.state

        return newstate.pageW  !== pageW  ||
               newstate.pageH  !== pageH  ||
               newstate.width  !== width  ||
               newstate.height !== height ||
               newprops.original           !== this.props.original           ||
               newprops.additional         !== this.props.additional         ||
               newprops.pct                !== this.props.pct                ||
               newprops.currentItemId      !== this.props.currentItemId      ||
               newprops.currentItemIndex   !== this.props.currentItemIndex   ||
               newprops.currentTrackId     !== this.props.currentTrackId     ||
               newprops.currentAlbumId     !== this.props.currentAlbumId     ||
               newprops.overviewAlbumId    !== this.props.overviewAlbumId    ||
               newprops.highlightedAlbumId !== this.props.highlightedAlbumId ||
               newprops.selectedAlbumId    !== this.props.selectedAlbumId    ||
               newprops.overviewMoreLeft   !== this.props.overviewMoreLeft   ||
               newprops.overviewMoreRight  !== this.props.overviewMoreRight 

    }
    onResize(){
        this.measure()
    }
    measure(){
        let {years}             = this.state
        let {pageHeightToWidth} = this.props

        this.setState(Pages.measure(years, pageHeightToWidth))
    }
    componentWillMount(){
        this.measure()
    }
    componentDidMount(){
        window.addEventListener('resize', this.onResize, false)
    }
    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize, false)
    }
    onAlbumSelect(e){
        let id = e.target.getAttribute('data-id')
        this.props.onAlbumSelect(getAlbumById(id))
    }
    scrollByPixels(pixels){
        let scale = inverseScale()

        let px    = pixels * scale

        let width = this.state.pageW

        let percentage = px / width

        this.props.scrollByPagePercentage(percentage)
    }
    //page pixel offset for a given percentage
    getOffset(pct){
        let { pageW } = this.state
        let {years} = this.state
        let width;

        width = pageW

        let totalWidth = (years.length - 1) * width
        let position   = (pct / 100) * totalWidth
        let offset     = position % width

        /*
         * this function contains a workaround for some kind of fence-posty bug,
         * where when resizing the window slightly and the position is right
         * near a 'page' boundary, the offset will flip back and forth
         * from approx. 1 page width to approx. 0.
         * it appears to be some sort of off-by-one error right
         * when we get to the edge of scrolling a single page.
         * so we check the difference between the currentIndex and
         * it's 'raw' value, i.e. how far into the page we have scrolled.
         *
         * this bug is easy to reproduce if you return a whole
         * number from one of the timeline's getCenteringAdjustment functions
         * (e.g. 0 or pagePercentage) and then slowly resize the window
         * from the left
         **/

        let idx = this.currentIndex(pct)
        let calc;
        {
            let {years} = this.state
            let idxRaw = Math.max(0, (years.length - 1) * (pct / 100))
            calc = idxRaw - idx
        }

        let diff = offset - width
        /* handle the fencepost error */

        if (Math.abs(diff) < 5 && Math.abs(calc) < 0.1) {
            /* console.log("tripped")*/
            return diff;
        }

        return offset
    }
    currentIndex(pct){
        let {years} = this.state
        let len = years.length - 1
        return Math.floor(len * (pct / 100))
    }
    //years to display for a given percentage
    getYears(pct){
        let {years, pageCount} = this.state
        let idx    = Math.max(0, this.currentIndex(pct) - 1)
        let result = years.slice(idx, idx + pageCount)
        return result
    }
    getPages(pct){
        let {pageWidth, pageHeight} = this.props
        let {pageW, pageH} = this.state
        return this.getYears(pct).map((y, idx)=>{
            if (y.spacer) {
                return (<PageSpacer key={y.id}
                                    pageWidth={pageWidth}
                                    pageHeight={pageHeight}
                        />)
            } else {
                return (<Page key={y.id}
                              item={y}
                              scrollByPixels={this.scrollByPixels}
                              pixelScrollEnded={this.props.pixelScrollEnded}
                              getPageContents={this.props.getPageContents}

                              selectedAlbumId={this.props.selectedAlbumId}
                              currentItemId={this.props.currentItemId}
                              currentItemIndex={this.props.currentItemIndex}
                              currentTrackId={this.props.currentTrackId}
                              currentAlbumId={this.props.currentAlbumId}
                              highlightedAlbumId={this.props.highlightedAlbumId}

                              original={this.props.original}
                              additional={this.props.additional}

                              onBGClick={this.hideBubbleAndTracks}
                              pageW={pageW}
                              pageH={pageH}
                              index={idx}
                              pageWidth={pageWidth}
                              pageHeight={pageHeight}
                              onAlbumSelect={this.onAlbumSelect} />)
            }
        })
    }
    getSlider(offset, scale){
        let {sliderMin, sliderMax, pct,
             sliderAffordance,
             sliderOnEnd, sliderOnScroll} = this.props

        let iscale = 1/scale
        let offs   = -offset  * iscale
        let width  = (measureWidth() * iscale) - 3 /* -3 to flush white line right */

        return (
            <HorizontalSlider
                affordance={sliderAffordance}
                left={offs} width={width}
                min={sliderMin} max={sliderMax}
                pct={pct} onScroll={sliderOnScroll}
                dragEnd={sliderOnEnd}
            />
        )
    }
    getOverview(offset, scale){
        if (!this.props.showOverview) return null

        let iscale = 1/scale
        let offs   = -offset  * iscale

        // minus 200 to keep indicator from getting cut off at right
        let width  = (measureWidth() * iscale) - 200

        return (
            <TimelineOverview
                left={offs} width={width}
                onClickTrack={this.props.onOverviewClickTrack}
                onClickAlbum={this.props.onOverviewClickAlbum}
                onClickClose={this.props.onOverviewClickClose}
                onClickPrev={this.props.onOverviewPrev}
                onClickNext={this.props.onOverviewNext}
                moreLeft={this.props.overviewMoreLeft}
                moreRight={this.props.overviewMoreRight}
                albumId={this.props.overviewAlbumId} />
        )
    }
    hideBubbleAndTracks(){
        this.props.hideBubbleAndTracks()
    }
    render(){

        let {pageWidth}        = this.props
        let {pageH, pageCount} = this.state

        let pct = this.props.pct

        let width  = pageCount * pageWidth,
            height = pageH

        let scale = timelineScale(), 
            xform = `scale(${scale})`

        let offset = -this.getOffset(pct)

        let style = {position: 'relative', left: offset,
                     width:width+'px', height:height+'px', transform:xform}
        return (
            <div style={style} className="pages">
              {this.getPages(pct)}
              {this.getSlider(offset, scale)}
              {this.getOverview(offset, scale)}
            </div>
        )
    }
}

Pages.getVisiblePageCount = function(items, pageHeightToWidth) {
    let {width, pageW} = this.measure(items, pageHeightToWidth)
    return width / pageW
}

Pages.measure = function(items, pageHeightToWidth) {

    //pageH      /* height of a page in viewport pixels */
    //pageW      /* width of a page in viewport pixels */
    //totalW     /* total width of all pages */
    //pageCount  /* visible number of pages */

    let width     = measureWidth()
    let height    = measureHeight()
    let pageH     = height
    let pageW     = pageH * pageHeightToWidth
    let totalW    = items.length * pageW
    let pageCount = Math.ceil(width / pageW) + 2


    return {width,height, pageH, pageW, totalW, pageCount}
}

function measurePageScale(){
    let width  = 720
    let height = 980
    let pageHeightToWidth = width / height
    let state = Pages.measure([], pageHeightToWidth)
    let {pageH} = state
    let scale = pageH / height
    setTimelineScale(scale)
}
window.addEventListener('resize', measurePageScale)
measurePageScale()
