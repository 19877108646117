import superagent from 'superagent'

export const getThumbnail = (videoId) => {
    return new Promise((resolve, reject) => {
        superagent.get('https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/' + videoId)
        .end((err, res)=>{
            if (err) reject(err)
            return(JSON.parse(res.text))
        }).then((data) => {
            resolve(data.body.thumbnail_url)
        })
    })
}