import React, {Component} from 'react'
import cn from 'classnames'
import { Formik, Form }   from 'formik'
import { connect }         from 'react-redux';
import { createPlaylist, addTracksToPlaylist } from '../services/api';
import Modal              from './modal'
import Input              from './input'
import { userIsNotSubscribed } from '../user'
import { processState }   from '../util/stateProcessing'
import { addPlaylistAction, updatePlaylistAction }    from '../actions/playlists'

class PlaylistPopup extends Component {
    constructor(props, ctx){
        super(props, ctx)

        this.state = {
            showAddPlaylistModal: false
        }

        this.hidePopup = this.hidePopup.bind(this)
    }
    componentDidMount() {
        const { customPlaylists } = this.props
        if (customPlaylists.length >= 10) {
            this.setState({modalerror: 'You have exceeded the max number of playlist'})
        }
    }
    componentWillMount(){
        document.addEventListener('mousedown', this.hidePopup)
    }
    hidePopup(e){
        if (e.target.dataset.isplaylistitem) {
          return
        }
        // if (e.target.closest('.playlist-popup')) {
        //   return
        // }

        e.preventDefault()
        e.stopPropagation()

        this.unbind()
        this.props.hidePopup()
    }
    componentWillUnmount(){
        this.unbind()
    }
    unbind(){
        document.removeEventListener('mousedown', this.hidePopup)
    }
    render(){
        const { tapeLeft, customPlaylists } = this.props
        const { showAddPlaylistModal } = this.state

        const onCloseModal = () => {
            this.setState({showAddPlaylistModal: false})
            this.props.hidePopup()
        }

        return (
            <div className={cn("playlist-popup", {'playlist-popup--left': tapeLeft})} style={this.props.style}>
              <div className="header">Add to playlist</div>
              <div className="contentWrapper">
                  <div className="new" data-isplaylistitem="1" onClick={() => {
                    if (userIsNotSubscribed()) {
                        window.subs()
                        return
                    }
                    document.removeEventListener('mousedown', this.hidePopup)
                    this.setState({showAddPlaylistModal: true})
                }}>
                    <div className="button" data-isplaylistitem="1" />
                    <div className="text" data-isplaylistitem="1">New playlist</div>
                  </div>
                  <ul className="playlists">
                    { customPlaylists.map(({ title, _id }, idx) => (
                        <li
                          key={`item-${idx}`}
                          data-isplaylistitem="1"
                          onClick={() => {
                            addTracksToPlaylist(_id, this.props.tracks)
                                .then(result => {
                                    this.props.dispatch(updatePlaylistAction(result.body))
                                    this.props.hidePopup()
                                })
                                .catch(err => {
                                    console.error(err)
                                    this.props.hidePopup()
                                })
                          }}
                          className="playlist"
                        >{title}</li>
                    )) }
                  </ul>
              </div>
              <Modal
                isOpen={showAddPlaylistModal}
                onRequestClose={onCloseModal}
                shouldCloseOnEsc={false}
              >
                  <Formik
                    initialValues={{ title: '' }}
                    onSubmit={(values, actions) => {
                        createPlaylist(values.title, this.props.tracks)
                            .then((result) => {
                                actions.setSubmitting(false)

                                this.props.dispatch(addPlaylistAction(result.body))
                                onCloseModal()
                            })
                            .catch(err => {
                                console.log('error', err)
                                actions.setSubmitting(false)
                                actions.setFieldError('title', 'Something went wrong')
                            })
                    }}
                    validate={(values, props) => {
                        let errors = {}

                        if (!values.title) {
                            errors.title = 'Required'
                        } else if (values.title.length >= 20) {
                            errors.title = 'You have exceeded max number of characters'
                        } else if (customPlaylists.length >= 10) {
                            errors.title = 'You have exceeded the max number of playlist'
                        }

                      return errors
                    }}
                    render={props => {
                        if (this.state.modalerror) {
                            props.setFieldError('title', this.state.modalerror)
                            this.setState({modalerror: null})
                        }
                        return (
                            <Form className="content">
                              <Input
                                className='input'
                                label='PLAYLIST NAME'
                                limit={20}
                                isFormikField
                                name='title'
                                setAutofocus
                              />
                              <div className="buttons-wrapper">
                                <div className="button button--back" onClick={onCloseModal}>CANCEL</div>
                                <button
                                    className={cn("button button--continue", {'disabled': props.isSubmitting || !props.isValid})}
                                    type="submit"
                                >SAVE</button>
                              </div>
                            </Form>
                        )
                    }}
                    />
              </Modal>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    const processedState = processState(state)
    const { items } = processedState
    return { customPlaylists: items.filter((item) => item.isPlaylist && item.type !== 'playlist') };
};

export default connect(mapStateToProps)(PlaylistPopup);
