import React, {Component} from 'react';
import cn                 from 'classnames'
import { Formik, Form }   from 'formik'
import CabinetFolder      from './cabinet-folder';
import EmptyFolder        from './empty-folder';
import LazyRender         from './lazy-render';
import MusicPlayer        from './music-player'
import mapRange           from '../util/map-range';
import Modal              from './modal'
import Input              from './input'
import { createPlaylist } from '../services/api'
import { getCustomPlaylists, addPlaylistToCabinet } from '../drawer-items';
import { userIsNotSubscribed } from '../user'
import { addPlaylistAction }    from '../actions/playlists'

let _lastIndex = -1
export default class Items extends Component {
    constructor(props,b){
        super(props,b)

        this.state = {selectedIndex: _lastIndex,
                      previousIndex: -1,
                      windowWidth: window.innerWidth,
                      windowHeight:window.innerHeight,
                      showAddPlaylistModal: false
                  }

        this.setScroll  = this.setScroll.bind(this)
        this.onTabClick = this.onTabClick.bind(this)
        this.viewMore   = this.viewMore.bind(this)
        this.onResize   = this.onResize.bind(this)
        this.closeItem  = this.closeItem.bind(this)
        this.getItems   = this.getItems.bind(this)

        this.mouseEnterItem = this.mouseEnterItem.bind(this)
        this.mouseLeaveItem = this.mouseLeaveItem.bind(this)
        
        this.showAddPlaylistModal = this.showAddPlaylistModal.bind(this)
    }
    setScroll(percentage){
        this.refs.list.setScroll(percentage)
    }
    shouldComponentUpdate(newprops, newstate){
        return true // TODO
        if (newstate.showAddPlaylistModal !== this.state.showAddPlaylistModal) {
            return true
        }

        if (newprops.pct !== this.props.pct) this.setScroll(newprops.pct);

        if (newstate.selectedIndex !== this.state.selectedIndex) {
            return true
        }
        if (newstate.previousIndex !== this.state.previousIndex) {
            return true
        }

        if (newstate.windowWidth !== this.state.windowWidth ||
            newstate.windowHeight !== this.state.windowHeight) {
                return true
        }

        if (newstate.hovering !== this.state.hovering) return true

        return false;
    }
    onResize(){
        let windowWidth  = window.innerWidth
        let windowHeight = window.innerHeight
        this.setState({windowWidth, windowHeight})
    }
    componentDidMount(){
        window.addEventListener('resize', this.onResize, false)
        const playlists = getCustomPlaylists()
        if (playlists.length >= 10) {
            this.setState({modalerror: 'You have exceeded the max number of playlist'})
        }
    }
    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize)
        clearTimeout(this.lastSelectionTimeout)
    }
    _setIndex(newidx){
        let oldidx = this.state.selectedIndex

        MusicPlayer.stop()

        this.setState({selectedIndex: newidx, previousIndex:oldidx})
        _lastIndex = newidx;

        clearTimeout(this.lastSelectionTimeout)
        this.lastSelectionTimeout = setTimeout(()=>{
            this.setState({previousIndex: -1})
        }, 1 * 1000)
    }
    closeItem(e){
        e.preventDefault()
        e.stopPropagation()
        this._setIndex(-1)
    }
    onTabClick(e){
        e.preventDefault()
        e.stopPropagation()

        let {selectedIndex} = this.state
        let element         = e.target
        let index           = parseInt(element.getAttribute('data-index'), 10)

        if (selectedIndex === index) {
            this._setIndex(-1)
        } else {
            this._setIndex(index)
        }
    }
    viewMore(e){
        e.preventDefault()
        e.stopPropagation()

        let {items} = this.props
        let element = e.target
        let index   = parseInt(element.getAttribute('data-index'), 10)
        let item    = items[index]

        this.props.viewMore(item)
    }
    showAddPlaylistModal(){
        if (userIsNotSubscribed()) {
            window.subs()
            return
        }
        this.setState({showAddPlaylistModal: true})
    }
    getItems(start, end) {
        let propsItems = this.props.items
        /* the lazy render is outset vertically by 2 item height at
           top and 1 item at bottom to hide the pop-in / pop-out that
           happens when scrolling over an expanded folder */
        /* this one is to give the top item room to show it's tab */
        /* these are to give room for the pop-in/pop-out to happen out-of-window */
        let items = [{spacer:true}, {spacer:true}, {spacer:true}, ...this.props.items]

        let selected   = this.state.selectedIndex 
        let lastSelect = this.state.previousIndex

        return mapRange(items, start, end, (item, idx) =>{

            let index = idx - 3 /* adjust index for the item spacers at the top */

            let {id, playlistId, _id} = item
            let key = item.spacer ? `spacer-${idx}` : (item.isAlbum ? `album-${id}` : (item.isPlaylist ? `playlist-${id || playlistId}` : `track-${id}`))

            /* is this item currently open */
            let showSelect     = (index === selected)
            /* is this item in the process of closing */
            let showDeselect   = (index === lastSelect)
            /* is this item behind an open item */
            let showPrevSelect = (index === selected - 1 || index === lastSelect - 1)
            /* is this item the last one -> pass onAddPlaylist handler, also makes the button appear on Cabinet Folder component */
            let onAddPlaylist = _id === items[items.length - 2]._id ? this.showAddPlaylistModal : null    // 2 offset for bottom folder spacers
            // TODO PLAYLISTS: Implement onAddPlaylistHandler
            if (item.spacer) {
                if (item.folder) {
                    return <EmptyFolder key={key} folder={item.folder}/>
                } else {
                    return <div key={key} className="item-spacer"></div>
                }
            } else {
                return <CabinetFolder
                           key={key}
                           viewMore={this.viewMore}
                           closeThis={this.closeItem}
                           selected={showSelect}
                           deselecting={showDeselect}
                           prevSelected={showPrevSelect}
                           index={index}
                           item={item}
                           onMouseEnter={undefined /*this.mouseEnterItem*/}
                           onMouseLeave={undefined /*this.mouseLeaveItem*/}
                           onClick={this.viewMore}
                           onAddPlaylist={onAddPlaylist}
                           />
            }
        })
    }
    mouseEnterItem(){
        this.setState({hovering:true})
    }
    mouseLeaveItem(){
        this.setState({hovering:false})
    }
    render(){

        const {hovering, showAddPlaylistModal} = this.state

        let selected   = this.state.selectedIndex
        let lastSelect = this.state.previousIndex
        let playlists = getCustomPlaylists()

        let somethingSelected = selected !== -1
        let itemsclass = cn({items:true, 'something-selected':somethingSelected, hovering});

        let width  = this.props.measureWidth() 
        let height = this.props.measureHeight() + 180 /* 60 = item height */

        /* adjust indexes for the item spacers */
        let lz_selected = somethingSelected ? selected   + 3 : -1
        let lz_previous = lastSelect !== -1 ? lastSelect + 3 : -1

        let contentStyle={width: width + 'px', height: height + 'px'}
        const onCloseModal = (e) => { 
            this.setState({showAddPlaylistModal: false})
        }

        return (
            <div className="scroll-hider" style={contentStyle}>
              <div className={itemsclass}>
                <div style={{overflowX:'hidden'}}>
                  <LazyRender ref="list"
        /* the lazy render is outset vertically by 2 item height at
           top and 1 item at bottom to hide the pop-in / pop-out that
           happens when scrolling over an expanded folder */
        /* this one is to give the top item room to show it's tab */
        /* these are to give room for the pop-in/pop-out to happen out-of-window */
                              childCount={this.props.items.length + 3}
                              selected={lz_selected}
                              previous={lz_previous}
                              getItems={this.getItems}
                              items={[{spacer:true}, {spacer:true}, {spacer:true}, ...this.props.items]}
                              className="lazy-list"
                              maxHeight={height} onScroll={this.props.onScroll}>
                  </LazyRender>
                  <Modal
                    isOpen={showAddPlaylistModal}
                    onRequestClose={onCloseModal}
                    shouldCloseOnEsc={false}
                  >
                      <Formik
                        initialValues={{ title: '' }}
                        onSubmit={(values, actions) => {
                            createPlaylist(values.title)
                                .then((result) => {
                                    actions.setSubmitting(false)

                                    this.props.dispatch(addPlaylistAction(result.body))
                                    //this.props.updateDrawerComponent()
                                    onCloseModal()
                                    const container = document.querySelector("#lazyRenderContainer")
                                    container.scrollTop = container.scrollHeight
                                })
                                .catch(err => {
                                    console.error(err)
                                    actions.setSubmitting(false)
                                    actions.setFieldError('title', 'Something went wrong')
                                })
                        }}
                        validate={(values, props) => {
                            let errors = {}

                            if (!values.title) {
                                errors.title = 'Required'
                            } else if (values.title.length >= 20) {
                                errors.title = 'You have exceeded max number of characters'
                            } else if (playlists.length >= 10) {
                                errors.title = 'You have exceeded the max number of playlist'
                            }
                          return errors
                        }}
                        render={props => {
                            if (this.state.modalerror) {
                                props.setFieldError('title', this.state.modalerror)
                                this.setState({modalerror: null})
                            }
                            return (
                                <Form className="content">
                                  <Input
                                    className='input'
                                    label='PLAYLIST NAME'
                                    limit={20}
                                    isFormikField
                                    name='title'
                                    setAutofocus
                                  />
                                  <div className="buttons-wrapper">
                                    <div className="button button--back" onClick={onCloseModal}>CANCEL</div>
                                    <button
                                        className={cn("button button--continue", {'disabled': props.isSubmitting || !props.isValid})}
                                        type="submit"
                                    >SAVE</button>
                                  </div>
                                </Form>
                            )
                        }}
                        />
              </Modal>
                </div>
              </div>
            </div>
        )
    }
}
