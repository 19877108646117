import { ENTRIES_LOADED, TRACKS_LOADED } from '../constants';
import {makeInitialState, index} from './utils'

const initialState = makeInitialState()

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.tracks) return index(action.entries.tracks, state);
            break;
        case TRACKS_LOADED:
            if(action.tracks) return index(action.tracks, state);
            break;
    }
    return state;
}
