import React, {Component} from 'react'
import {amazonClick}      from '../services/tracking'
import cns from 'classnames'

export default class BuyPopup extends Component {
    constructor(props, ctx){
        super(props, ctx)

        this.hidePopup = this.hidePopup.bind(this)
        this.clickLink = this.clickLink.bind(this)
    }
    componentWillMount(){
        document.querySelector('#app').addEventListener('mousedown', this.hidePopup)
    }
    hidePopup(e){
        if (e.target.getAttribute('data-is-buy-item')) return

        e.preventDefault()
        e.stopPropagation()

        this.unbind()
        this.props.hidePopup()
    }
    componentWillUnmount(){
        this.unbind()
    }
    clickLink(e){
        e.preventDefault()
        e.stopPropagation()
        let link = e.target.getAttribute('data-link')
        const type = e.target.dataset.type
        if (!link) {
            console.error('missing link on buy popup')
            return
        }
        if (type) {
            window.displayWarningMessage({
                type,
                link
            })
            this.props.hidePopup()
            return
        }
        let isAmazon = e.target.getAttribute('data-is-amazon')
        if (isAmazon) {
            amazonClick(link)
        }
        window.open(link)
    }
    unbind(){
        document.querySelector('#app').removeEventListener('mousedown', this.hidePopup)
    }
    render(){
        const { buyLinks, freeTrack, isTrack, buttonLink, playlistBackground } = this.props
        return (
            <div className={cns("buy-button-popup", {
              'buy-button-popup--track': isTrack,
              'buy-button-popup--bigBackground': freeTrack,
              'buy-button-popup--playlistBackground': playlistBackground
            })} style={this.props.style}>
              <div className="links">
              {
                  buyLinks.map(({title, link, isAmazon, type, isCustomItem, customItemClick}, idx) => {
                    return (
                        <div
                            key={idx}
                            data-link={link}
                            data-is-buy-item="1"
                            data-is-amazon={isAmazon}
                            data-type={type}
                            onClick={customItemClick || this.clickLink}
                            className={`item-${idx}`}
                        >{!(freeTrack || isCustomItem) && 'Buy '}{title}</div>
                    )
                  })
              }
              {buttonLink && <label
                data-is-buy-item="1"
                className="button"
                onClick={this.clickLink}
                data-link={buttonLink.link}
                data-type={buttonLink.type}
              >FREE DOWNLOAD</label>}
              </div>
            </div>
        )
    }
}
