import _ from 'lodash';
import store from '../store';
import {parseArticle} from './parse-article'
import compareReleaseDate from '../util/compare-release-date.js'

function tryParseContrarianAd(data){

    if (!_.has(data, 'fields')) return
    if (!_.has(data, 'fields.image')) return

    const image = data.fields.image
    if (!_.has(image, 'fields.file.url')) return
    if (!_.has(image, 'fields.file.details.image.height')) return

    const url    = image.fields.file.url
    const height = image.fields.file.details.image.height
    const link   = data.fields.link || ''

    return {url, height, link}
}

let _aAds, _bAds

setInterval(()=>{
    if (!_aAds) return
    _aAds = _.shuffle(_aAds)
    _bAds = _.shuffle(_bAds)
}, 4 * 60 * 60 * 1000) //4 hours

function parseNewsPage(data) {
    if (!_aAds) _aAds = _.shuffle(_.compact(data.aAds.map(tryParseContrarianAd)))
    if (!_bAds) _bAds = _.shuffle(_.compact(data.bAds.map(tryParseContrarianAd)))

    data.aAds = _aAds
    data.bAds = _bAds
    return data;
}

let newsData = undefined;

export function getNewsData() {
    if (!newsData) {
        newsData = parseNewsPage(store.getState().newsPage.page)
    }
    return newsData
}

let pagesData = undefined;

export function getPagesData() {
    if (!pagesData) {
        pagesData = store.getState().contrarianPage.all
    }
    return pagesData
}

let articles = undefined;

function sortArticles(a, b) {
    if (a.sticky) return -1
    if (b.sticky) return 1
    let order = compareReleaseDate(a.releaseDate, b.releaseDate) * -1
    if (order === 0) {
        if      (a.order > b.order) return -1
        else if (b.order > a.order) return  1
        else                        return  0
    } else {
        return order
    }
}

function ensureArticles() {
    if (!articles) {
        const all = store.getState().newsArticle.all.filter(article => !(article.hiddenEnv && article.hiddenEnv.includes('desktop')))
        const pages = ['page-1', 'page-2', 'page-3', 'page-4', 'page-5', 'page-6', 'page-7']

        articles = pages.reduce((acc, page) => {
            const left  = _.compact(_.map(_.filter(all, it=>it.column === 'left' && it.page === page), parseArticle))
            const center = _.compact(_.map(_.filter(all, it=>it.column === 'center' && it.page === page), parseArticle))
            const right = _.compact(_.map(_.filter(all, it=>it.column === 'right' && it.page === page), parseArticle))
            const fourth = _.compact(_.map(_.filter(all, it=>it.column === 'fourth' && it.page === page), parseArticle))
            const fifth = _.compact(_.map(_.filter(all, it=>it.column === 'fifth' && it.page === page), parseArticle))
            const sixth = _.compact(_.map(_.filter(all, it=>it.column === 'sixth' && it.page === page), parseArticle))
            left.sort(sortArticles)
            center.sort(sortArticles)
            right.sort(sortArticles)
            fourth.sort(sortArticles)
            fifth.sort(sortArticles)
            sixth.sort(sortArticles)

            return Object.assign(acc, {[page]: {left, center, right, fourth, fifth, sixth}})
        }, {})
    }
}

export function getArticleData(page){
    ensureArticles(page)
    return articles
}
export function getArticleById(id, page) {
    ensureArticles(page)
    return store.getState().newsArticle.all.find(article => article.id.toLowerCase() === id.toLowerCase())
}
