import React, {Component} from 'react'
import PropTypes          from 'prop-types'
import HorizontalSlider   from '../horizontal-slider'
import cn                 from 'classnames'
import Audio              from './audio'
import PlaylistManager    from './playlist-manager'

class Rotator extends Component {
    render() {
        let {amount, offset} = this.props
        offset = offset || 0
        let degrees = ((amount + offset)* 90) - 45
        let style = {transform: `rotate(${degrees}deg)`}
        return (
            <div className="rotator" style={style}>{this.props.children}</div>
        )
    }
}

function bitrate_to_value(b) {
    let r
    //0 to 800 first half
    if (b <= 800) {
        r = (b / 800) * 0.5
    //800 to 6000 next 1/3
    } else if (b <= 6000) {
        r = ((b - 800) / (6000 - 800)) * (0.6666 / 2) + 0.5
    //6000 to 7000 last 1/6
    } else {
        r = (b - 6800) / (7000 - 6800) * (0.3333 / 2) + (0.5 + (0.6666 / 2))
    }
    return Math.max(0, Math.min(1.0, r))
}

function fmt_1000(n) {
    if (n < 10) return '00' + n
    if (n < 100) return '0' + n
    return '' + n
}

function fmt_bitrate(b){
    if (typeof b !== 'number') return b
    if (b < 1000) return b
    return Math.floor(b / 1000)  + ',' + fmt_1000(b % 1000)
}

class Meter extends Component {
    render(){
        let {bitrate} = this.props
        let angle = bitrate_to_value(bitrate)
        return (
            <div className="meter">
              <div className="arc-mask">
                <Rotator amount={angle} offset={-0.5}><div className="meter-arc" /></Rotator>
              </div>
              <div className="meter-top"/>
              <Rotator amount={angle}><div className="meter-needle" /></Rotator>
              <div className="readout">{fmt_bitrate(bitrate)}</div>
              <div className="meter-bottom" />
            </div>
        )
    }
}


class PlayerControls extends Component {
    shouldComponentUpdate(newprops){
        return newprops.playing !== this.props.playing ||
               newprops.loading !== this.props.loading ||
               newprops.prevDisabled !== this.props.prevDisabled ||
               newprops.nextDisabled !== this.props.nextDisabled ||
               newprops.disabled !== this.props.disabled
    }
    render(){
        let {playing, loading,  disabled, prevDisabled, nextDisabled} = this.props
        let controls = cn('player-controls', {disabled})

        let play = !loading && !playing
        let pause = !loading && playing

        let prevClass = cn('prev-button', {disabled: disabled || prevDisabled})
        let nextClass = cn('next-button', {disabled: disabled || nextDisabled})

        let cx = cn('play-pause-button', {play, pause, loading, disabled})

        return (
            <div className={controls}>
              <div className={prevClass} onClick={this.props.onPrev} />
              <div className={cx} onClick={this.props.onPlayPause} />
              {loading && <div className="loading-indicator-wrapper"><div className="loading-indicator" /></div> }
              <div className={nextClass} onClick={this.props.onNext} />
            </div>
        )
    }
}

class VolumeControl extends HorizontalSlider {
    render(){
        let pct = this.unscale(this.props.pct)
        let pStyle = { width: `${pct}%` }
        return (
            <div className="volume-control">
              <div className="slider-background" />
              <div ref="line" className="slider-container"><div className="slider-progress" style={pStyle} /></div>
              <div ref="thumb" style={this.getThumbStyle()} className="slider-thumb">
                <div className="slider-thumb-image"
                     onMouseDown={this.mouseDown} onTouchStart={this.touchStart} />
              </div>
            </div>
        )
    }
}

class ScrubControl extends HorizontalSlider {
    render(){

        let progress = this.unscale(this.props.pct) + '%'

        return (
            <div ref="line" className="progress-bar">
              <div className="progress" style={{width:progress}}>
                <div className="black-bar" />
                <div ref="thumb" className="thumb"
                     onMouseDown={this.mouseDown} onTouchStart={this.touchStart}>
                  <div className="black-thumb" />
                </div>
              </div>
            </div>
        )
    }
}

class QualitySwitch extends Component {
    constructor(props) {
        super(props)

        this.state = {quality: Audio.getQuality() ? 'high' : 'low'}

        this.onClick  = this.onClick.bind(this)
        this.setTo320 = this.setTo320.bind(this)

        window.setAudioTo320 = this.setTo320
    }
    setTo320(){
        if (Audio.getQuality()) this.onClick()
    }
    onClick(){
        let quality = this.state.quality === 'high' ? 'low' : 'high'
        this.setState({quality})
        Audio.toggleQuality()
    }
    render() {
        let {quality} = this.state
        let cx = cn("quality-switch", quality)
        return (
            <div className={cx} onClick={this.onClick} />
        )
    }
}

export default class Player extends Component {
    constructor(a, b){
        super(a, b)

        let track = PlaylistManager.currentTrack()
        let {prevDisabled, nextDisabled} = PlaylistManager.prevNextDisabled()

        this.state = {
            track, prevDisabled, nextDisabled,
            volume: 100, playing:false, loading: false,
            state:null, samprate:0, bitrate:0, position:0,
            elapsed:'--:--', remaining:'--:--'
        }

        this.setVolume        = this.setVolume.bind(this)
        this.playPause        = this.playPause.bind(this)
        this.callback         = this.callback.bind(this)
        this.playlistCallback = this.playlistCallback.bind(this)

        this.scrubDragStart   = this.scrubDragStart.bind(this)
        this.scrubDragMove    = this.scrubDragMove.bind(this)
        this.scrubDragEnd     = this.scrubDragEnd.bind(this)

        this.clickArt   = this.clickArt.bind(this)
        this.clickTrack = this.clickTrack.bind(this)

        this.onKeyPress = this.onKeyPress.bind(this)

        this.pauseIfNeeded  = this.pauseIfNeeded.bind(this)
        this.resumeIfNeeded = this.resumeIfNeeded.bind(this)

        window.pauseAudioIfNeeded  = this.pauseIfNeeded
        window.resumeAudioIfNeeded = this.resumeIfNeeded
    }
    componentDidMount(){
        Audio.addCallback(this.callback)
        PlaylistManager.addListener(this.playlistCallback)
        document.addEventListener('keypress', this.onKeyPress)
    }
    componentWillUnmount(){
        Audio.removeCallback(this.callback)
        PlaylistManager.removeListener(this.playlistCallback)
        document.removeEventListener('keypress', this.onKeyPress)
    }
    onKeyPress(e) {
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') return
        if (e.code === "Space") {
            if (this.state.track) this.playPause()
        }
    }
    playlistCallback(info){
        console.log('got playlist callback: ', info)
        if (info.state === 'STOPPED') {
            this.setState({track:null, playing:false, loading: false,
                           quality: 0, samprate:0, bitrate:0, position:0, elapsed:'--:--', remaining:'--:--'})
            return
        }

        if (info.state === 'WILLPLAY') {
            this.setState({loading:true, track:info.track,
                           prevDisabled:info.atStart, nextDisabled: info.atEnd})
        } else if (info.state === 'LIVEPLAY' && !this.state.playing) {
            this.setState({playing:true, loading:false, track:info.track})
        } else {
            this.setState({track:info.track})
        }
    }
    pauseIfNeeded(){
        if (!this.state.playing) return
        Audio.pause()
        this.setState({playing:false, temporarilyPaused:true})
    }
    resumeIfNeeded(){
        if (!this.state.temporarilyPaused) return
        Audio.play()
        this.setState({playing:true, temporarilyPaused:false})
    }
    playPause(){
        let {playing, loading} = this.state
        if (loading) return
        playing = !playing
        if (playing) Audio.play();
        else         Audio.pause()
        this.setState({playing})
    }
    scrubDragStart(){
        this.setState({scrubbing:true, scrubState:this.state.position * 100})
    }
    scrubDragMove(pct){
        this.setState({scrubState:pct})
    }
    scrubDragEnd(){
        Audio.seek(this.state.scrubState / 100)
        setTimeout(()=>{
            this.setState({scrubbing:false})
        }, 1500)
    }
    setVolume(pct){
        this.setState({volume:pct})
        Audio.setVolume(pct/100)
    }
    callback(playerState) {
        if (playerState.state === 'PAUSED' && this.state.state === 'PAUSED') return
        if (playerState.state !== this.state.state) {
            console.log('CHANGING PLAYER STATE:', playerState.state)
        }
        if (playerState.state === 'LOADING' || playerState.state === 'READY') {
            //don't want to lose our position etc, so just update the 'state' value
            this.setState({state:playerState.state})
            return
        }
        let {state, samprate, bitrate, position, elapsed, remaining, quality } = playerState

        if (state === 'LIVEPLAY') {
            this.setState({playing: true, loading:false, state, samprate, bitrate,
                           position, elapsed, remaining, quality })
        } else {
            this.setState({state, samprate, bitrate, position,
                           elapsed, remaining, quality, playing: false })
        }
    }
    clickArt(){
        let {track} = this.state
        if (!track) return
        let {router} = this.context
        let album = track.album
        router.push(router.createLocation(`/album?id=${album.id}`))
    }
    clickTrack(){
        let {track} = this.state
        if (!track || track.listOnly) return
        let {router} = this.context
        router.push(router.createLocation(`/info-card?track=${track.id}`))
    }
    render(){
        let {volume, playing, loading,
             elapsed, remaining, bitrate,
             samprate, position, quality,
             scrubbing, scrubState,
             track,
             prevDisabled, nextDisabled
        } = this.state
        /* console.log('bandwidth = ', bandwidth, ' quality = ', quality, ' samprate = ', samprate)*/

        let disabled = !track

        let title, backgroundImage, performer
        if (track) {
            title = track.title
            backgroundImage = `url('${track.playerImage}')`
            performer = track.performer
        } else {
            title = ''
            backgroundImage = ''
            performer = ''
        }

        let imageStyle = {backgroundImage}

        let sample = samprate ? samprate.replace(/b|k/g,'') : '--'

        if (quality === 10) sample = `FULL ${sample} RESOLUTION`

        let progress = scrubbing ? scrubState : position * 100

        let currBitrate = bitrate_to_value(bitrate)
        let currFill = quality === 10 ? 1.0 : currBitrate

        if (!Audio.getQuality()) currFill = quality / 10.0;

        let bwbgStyle = {height:currFill * 100 + '%'}
        return (
            <div className="new-player-wrapper">
              <div className="new-player">
                <div className="currently-playing">
                  <div className="bandwidth-background-wrapper">
                    <div className="bandwidth-background" style={bwbgStyle} />
                  </div>
                  <div className="info-wrapper">
                      <div className="track-title"
                       onClick={this.clickTrack}>{title}</div>
                      <div className="track-performer">{performer}</div>
                      <div className="time-indicators">
                        <div className="left">{elapsed}</div>
                        <div className="center">{sample}</div>
                        <div className="right">{remaining}</div>
                      </div>
                  </div>
                  <div className="album-artwork"
                       onClick={this.clickArt} style={imageStyle} />
                  <ScrubControl
                      dragStart={this.scrubDragStart}
                      onScroll={this.scrubDragMove}
                      dragEnd={this.scrubDragEnd}

                      pct={progress}
                  />
                </div>
                <div className="middle-layer" />
                <Meter bitrate={bitrate} />
                <PlayerControls
                    disabled={disabled}
                    prevDisabled={prevDisabled}
                    nextDisabled={nextDisabled}
                    loading={loading}
                    playing={playing}
                    onPlayPause={disabled ? undefined : this.playPause}
                    onPrev={disabled || prevDisabled ? undefined : Audio.prev}
                    onNext={disabled || nextDisabled ? undefined : Audio.next}
                />
                <QualitySwitch />
              </div>
              <VolumeControl onScroll={this.setVolume} pct={volume} />
            </div>
        )
    }
}

Player.contextTypes = {
    router: PropTypes.object.isRequired
}
