import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import StripeCheckout from 'react-stripe-checkout';
import { userCanPurchaseNewSubscription, isFreeUser } from '../user';
import { getFreeDownloadSong } from '../drawer-items'
import { getPlanBenefits, getPlanProperty } from '../plans';
import spinnerFrame01 from '../../images/loading-indicator/frame_01.png'
import nyaIcon from '../../images/NYA_icon_128x128.jpg'
import Input from '../components/input'
import Login from '../services/login';
import { registerButtonClick } from '../services/tracking';
import { fetchExclusiveEntries } from '../services/fetch';
import {
    getUserInfo,
    createOrUpdatePlan,
    hasAuth,
    redeemCode,
    isPaying,
    setIsPaying,
    paymentProcessReloadDelay
} from '../services/api';

// TODO: Ensure `paymentFail` returns reletive error msg

class Line extends Component {
    render(){
        return (
            <div className="description-line"><div className="check" /><div className="line">{this.props.children}</div></div>
        )
    }
}

class RadioButton extends Component {
    render(){
        const {id, checked, onChange, value, disabled} = this.props
        return (
            <div className="custom-radio">
              <input type="radio"
                id={id}
                disabled ={disabled}
                checked={checked}
                onChange={onChange}
                value={value} name={value} />
              <div className="toggle" />
            </div>
        )
    }
}

class CheckBox extends Component {
    render(){
        const {checked, onChange, name} = this.props
        return (
            <div className="custom-checkbox">
                  <input type="checkbox"
                         checked={checked}
                         onChange={onChange}
                         name={name} />
                  <div className="image" />
            </div>
        )
    }
}

/*
   initial -> select -> paying -> paymentOK
                          +-----> paymentFail -> initial
*/

export default class PlansPanel extends Component {
    constructor(props, ctx){
        super(props, ctx)

        const state = props.view === 'select' ? 'select' : 'initial'

        const queryCode = props.router.location.query.code
        this.state = {
            state,
            annualPlan: true,
            acceptedTerms: false,
            giftcode: queryCode || ''
        }

        this.initialClickPurchase  = this.initialClickPurchase.bind(this)
        this.planSelectionOnChange = this.planSelectionOnChange.bind(this)
        this.acceptedTermsOnChange = this.acceptedTermsOnChange.bind(this)
        this.selectGotToken        = this.selectGotToken.bind(this)
        this.clickExplore          = this.clickExplore.bind(this)
        this.backToPlans           = this.backToPlans.bind(this)
        this.giftcodeOnChange = this.giftcodeOnChange.bind(this)
        this.sendCodeHandler = this.sendCodeHandler.bind(this)
        this.triggerAuth0 = this.triggerAuth0.bind(this)
        this.triggerConversionEvent = this.triggerConversionEvent.bind(this)
    }
    componentDidMount() {
        if(this.props.view === 'select') this.triggerAuth0()
    }
    componentWillUpdate(newprops/*, newstate*/) {
        if (newprops.view !== this.props.view) {
            let state
            if (newprops.view === 'select') state = 'select'
            if (newprops.view === undefined) state = 'initial'
            if (state) this.setState({state})
        }

        if(newprops.view === 'select') this.triggerAuth0()
    }
    componentWillUnmount() {
        clearTimeout(this.emit)
    }
    triggerConversionEvent() {
        const currency = 'USD'
        const value = this.state.annualPlan ? 19.99 : 1.99

        registerButtonClick({ value, currency })
    }
    fetch() {
        fetchExclusiveEntries()
            .catch(() => {
                document.location.href = '/'
                document.location.reload()
            })
    }

    triggerAuth0(){
        if (!hasAuth()) {
            clearTimeout(this.emit)
            this.emit = setTimeout(Login, 100)
        }
    }
    getContent(){
        return this[this.state.state]()
    }

    renderPlanBenefits(planId) {
        const benefits = getPlanBenefits(planId)
        return <div className="details section">
            {
                benefits.map((benefit, idx) => {
                    return <Line key={idx}>{benefit}</Line>
                })
            }
        </div>
    }

    initial(){
        const buttonLabel = userCanPurchaseNewSubscription() ? getPlanProperty('buyButtonText') : 'MANAGE'
        return (
            <div className="content initial">
              <div className="pane free">
                <div className="header section" />
                <div className="description section">
                  <span className="plan-price">$0 /month</span><br />
                </div>
                {this.renderPlanBenefits('NYA-FREE')}
              </div>
              <div className="pane rust">
                <div className="header section" />
                <div className="description section">
                  <span className="plan-price">$19.99 /year</span>
                  <span className="plan-price">$1.99 /month</span>
                  <span><small>{getPlanProperty('banner')}</small></span>
                  <div className="button-bottom">
                    <div className="button" onClick={this.initialClickPurchase}>{buttonLabel}</div>
                  </div>
                </div>
                {this.renderPlanBenefits('NYA-UNLIMITED')}
              </div>
            </div>
        )
    }

    initialClickPurchase(){
        if (userCanPurchaseNewSubscription()) {
            if (hasAuth()) {
                this.props.router.replace({ pathname: '/account', query: { screen: 'plans', view: 'select' }})
            } else {
                Login('/account?screen=plans&view=select')
            }
        } else {
            this.props.router.replace({ pathname: '/account', query: { screen: 'subscription' }})
        }
    }

    sendCodeHandler() {
        this.setState({ state: 'paying' }, () => {
            redeemCode(this.state.giftcode).then(
                res => this.setState({ state: 'paymentOK' }),
                err => this.setState({ state: 'paymentFail' })
            )
        })
    }

    _getPurchaseButton(){
        const {acceptedTerms, annualPlan, giftcode} = this.state
        if (isPaying()) {
            return null
        }
        if (acceptedTerms) {
            if (giftcode !== '' && annualPlan) {
                return <div className="button" onClick={this.sendCodeHandler}>SUBMIT</div>
            } else {
                const price = annualPlan ? 1999 : 199
                const userInfo = getUserInfo()
                const email = (userInfo.user_metadata && userInfo.user_metadata.customEmail) || userInfo.email

                return (
                <StripeCheckout token={this.selectGotToken}
                                stripeKey={process.env.STRIPE_KEY}
                                email={email}
                                billingAddress={true}
                                amount={price}
                                image={nyaIcon} >
                    <div className="button">SUBMIT</div>
                </StripeCheckout>
                )
            }
        } else {
            return (<div className="button disabled">SUBMIT</div>)
        }
    }

    planSelectionOnChange(event){
        const annualPlan = event.target.value === "annual"
        this.setState({annualPlan})
    }

    giftcodeOnChange(value) {
        const giftcode = value
        this.setState({ giftcode, annualPlan: true })
    }

    acceptedTermsOnChange(){
        this.setState({acceptedTerms:!this.state.acceptedTerms})
    }

    select(){
        const {annualPlan, acceptedTerms, giftcode} = this.state
        if (!isFreeUser() && !giftcode) {
            this.setState({state: 'initial'})
            return null
        }

        return (
            <div className="content select">
                { isFreeUser() ?
                    <React.Fragment>
                        <div className="select-header">Select Subscription</div>
                        <div className="radio-left">
                            <RadioButton
                                id="monthlyOption"
                                disabled={giftcode !== ''}
                                checked={!annualPlan}
                                onChange={this.planSelectionOnChange}
                                value="monthly" />
                            <div className="radio-description">
                            <div className="term">Monthly</div>
                            <div className="price">$1.99 per month<br />(no pre-sale ticket access)</div>
                            </div>
                        </div>
                        <div className="radio-right">
                            <RadioButton
                                id="annualOption"
                                checked={annualPlan}
                                onChange={this.planSelectionOnChange}
                                value="annual"/>
                            <div className="radio-description">
                                <div className="term">Annual</div>
                                <div className="price">$19.99 per year<br />(includes pre-sale ticket access)</div>
                                <Input className='input--giftcode input' placeholder="Enter Gift Code" limit={8} value={this.state.giftcode} onChange={this.giftcodeOnChange} name='code'/>
                            </div>
                        </div>
                        <div className="terms">
                            <CheckBox
                                checked={acceptedTerms}
                                onChange={this.acceptedTermsOnChange}
                                name="terms" />
                            <div className="terms-blurb">
                            By clicking submit, you authorize us to charge you the price above and applicable tax for the duration that you have selected. After this period ends, your subscription will automatically be renewed and you will be charged the standard price for that subscription until you cancel. This does not apply to gift subscriptions which will expire 1 year to the date after redemption. You can see when you subscription will renew next, or learn how to cancel, via your account page. Your subscription is subject to our <a href="/terms.html" target="_blank">Terms</a> and to our <a href="/privacy.html" target="_blank">Privacy Policy</a>
                            </div>
                        </div>
                        <div className="buttons-bottom">
                            {this._getPurchaseButton()}
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="message subscribed">
                            Sorry, but it looks like you're trying to claim a gift but you already have a subscription
                        </div>
                        <div className="buttons-bottom">
                            <div className="button clear" onClick={this.clickExplore}>EXPLORE NYA</div><div style={{height:'65px'}} />
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }

    selectGotToken(token){
        const planType = this.state.annualPlan ? 'NYA-UNLIMITED-YEARLY' : 'NYA-UNLIMITED-MONTHLY'
        this.setState({ state: 'paying' }, () => {
            createOrUpdatePlan(token, planType).then(
                res => this.setState({ state: 'paymentOK' }),
                err => this.setState({ state: 'paymentFail' })
            )
        })
    }

    paying(){
        //
        setIsPaying()

        return (
            <div className="content">
              <div className="message">
                Creating your subscription...
              </div>
            </div>
        )
    }

    paymentOK(){
        const {router} = this.context
        let freeSongs = getFreeDownloadSong()
        this.triggerConversionEvent()
        return (
            <div className="content">
              <div className="message">
                Thank you for purchasing NYA Unlimited subscription. <br />
                Reload in {paymentProcessReloadDelay / 1000} seconds...
              </div>
              <img className="spinner" src={spinnerFrame01} alt='spinner' />
              { (freeSongs && !this.state.giftcode) &&
                <div className="buttons-bottom-150">
                  <div className="button clear" onClick={() => {router.push(`/info-card?track=${freeSongs[0].id}`)}}>FREE TRACK</div>
                </div>
              }
              <div className="buttons-bottom">
                <div className="button" onClick={this.clickExplore}>EXPLORE NYA</div>
              </div>
            </div>
        )
    }

    paymentFail(){
        return (
            <div className="content">
              <div className="message">
                Sorry. Your payment information didn't go through.
              </div>
              <div className="buttons-bottom">
                <div className="button clear" onClick={this.clickExplore}>EXPLORE NYA</div><div style={{height:'65px'}} />
                <div className="button" onClick={this.backToPlans}>BACK TO PLANS</div>
              </div>
            </div>
        )
    }

    clickExplore(){
        document.location.href = '/'
    }

    backToPlans(){
        this.props.router.replace({ pathname: '/account', query: { screen: 'plans' }})
        this.setState({ state:'initial', annualPlan:true, acceptedTerms:false, giftcode: '' })
    }

    render() {
        return (
            <div className="panel plans-panel">
              <div className="title">CREATE PLAN</div>
              {this.getContent()}
            </div>
        )
    }
}

PlansPanel.contextTypes = {
    router: PropTypes.object.isRequired
}
