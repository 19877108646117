import React, {Component} from 'react';
import ScrollBars         from 'react-scrollbar'
import PropTypes          from 'prop-types'
import cn from 'classnames'

function formatArtists(artists){
    return artists.map(artist => artist && artist.name ? artist.name : '').join(' ')
}

function formatDay(n){
    if (n<10) return '0' + n
    else      return ''  + n
}

export default class BubbleInfo extends Component {
    constructor(props){
        super(props)

        this.clickBoxSetItem = this.clickBoxSetItem.bind(this)
        this.buyBook         = this.buyBook.bind(this)
    }
    getTrackContent(props){

        let {object, onView, onViewAlbum }      = props
        let {title, performer, releaseDate,
             releaseDateText, id, album}        = object
        let {month, day, year}                  = releaseDate

        let date = releaseDateText || `${month}/${day}/${year}`

        let backgroundImage = `url(${album && album.popupImage})`


        return (
            <div className="content">
              <div className="track-title">{title}</div>
              <div className="track-performer">{performer}</div>
              <div className="track-date">{date}</div>
              <div className="track-buttons">
                <div className="view-button" data-id={id}
                     onClick={onView}></div>
              </div>
              {album &&
               <div className="track-album"
                    data-id={album.id}
                    onClick={onViewAlbum}
                    style={{backgroundImage}} /> }
            </div>
        )
    }
    getAlbumContent(props){
        let {object, highlightedAlbumId,
             onView, onTracks} = props

        let {id, displayDate, artists, title, unreleased, hasTracksForTimeline } = object
        let {month, day, year} = displayDate

        let button = id === highlightedAlbumId ? 'hide' : 'show'
        let cx = button + '-button'

        /* test data for layout */
        /* artists = [{name: 'Neil Young'}]
         * title = 'Everybody Knows This is Nowhere'
         * month = 12; day=5; year=1966*/

        let showDate = !unreleased

        return (
            <div className="content">
              <div className="album-title">{title}</div>
              <div className="album-artist">{formatArtists(artists)}</div>
              {
                  showDate ?
                  <div className="album-date">{month}/{formatDay(day)}/{year}</div>
                  : null
              }
              <div className="album-buttons">
                <div className="view-button" data-id={id}
                     onClick={onView}></div>
                {
                    hasTracksForTimeline &&
                    <div className={cx} data-id={id}
                         onClick={onTracks}></div>
                }
              </div>
            </div>
        )
    }
    getFilmContent(props) {
        let {object, onView} = props
        let {id, releaseDate, title, director, releases} = object
        let {month, day, year} = releaseDate
        let date = releases || `${month}/${formatDay(day)}/${year}`
        return (
            <div className="content">
              <div className="album-title">{title}</div>
              <div className="album-artist">{director}</div>
              <div className="album-date">{date}</div>
              <div className="film-buttons">
                <div className="view-button" data-id={id}
                     onClick={onView}></div>
              </div>
            </div>
        )
    }
    clickBoxSetItem(e){
        e.preventDefault()
        e.stopPropagation()

        let {router} = this.context
        let location;
        let el = e.target
        let id = el.getAttribute('data-id')
        if (id[0] === 'F' || id[0] === 'f') {
            location = `/film?id=${id}`
        } else {
            location = `/album?id=${id}`
        }
        router.push(router.createLocation(location))
    }
    buyBook(e) {
        window.open(this.props.object.buyLink)
    }
    getBoxSetContent(props) {
        let {object} = props
        let {title, releaseDate} = object
        let {month, day, year} = releaseDate,
            artist = 'Neil Young'

        return (
            <div className="content">
              <div className="album-title">{title}</div>
              <div className="album-artist">{artist}</div>
              <div className="album-date">{month}/{formatDay(day)}/{year}</div>
              <div className="item-listing">
                {
                    object.items.map((item,idx)=>{
                        let backgroundImage = `url(${item.timelineImage})`
                        return (<div key={idx}
                                     data-id={item.id}
                                     onClick={this.clickBoxSetItem}
                                     style={{backgroundImage}}
                                     className="item" />)
                    })
                }
              </div>
            </div>
        )
    }
    getBookContent(props) {
        let {object} = props
        let {title, author, displayDate, description} = object
        return (
            <div className="content">
              <div className="book-title">{title}</div>
              <div className="book-author">{author}</div>
              <div className="book-date">{displayDate}</div>
              <div className="book-buy-button" onClick={this.buyBook} />
              <div className="description-wrapper">
                   <ScrollBars>{description}</ScrollBars>
              </div>
            </div>
        )
    }
    getContent(props){
        let {type} = props.object

        if      (type === 'album')  return this.getAlbumContent(props);
        else if (type === 'film')   return this.getFilmContent(props);
        else if (type === 'track')  return this.getTrackContent(props);
        else if (type === 'boxset') return this.getBoxSetContent(props);
        else if (type === 'book')   return this.getBookContent(props)

        return null
    }
    render(){
        let {side, spacing, onClose, object} = this.props
        let {type} = object

        let roomOnRight = side !== 'left'

        //don't have designs for the left side yet.
        if (type === 'boxset') roomOnRight = true;

        let bubble = cn({
            'bubble-info': true,
            'display-right': roomOnRight,
            'display-left': !roomOnRight,
            [type+'-popup']:true
        })

        spacing = '100%'
        let style = roomOnRight ? {left:spacing} : {right: spacing}

        if (this.props.coveringAmount && roomOnRight) {
            style = {left: `${this.props.coveringAmount}%`}
        }

        return (
            <div className={bubble} style={style}>
              <div className="content-wrapper">
                {this.getContent(this.props)}
                <div className="bubble-close" onClick={onClose} />
              </div>
            </div>
        )
    }
}

BubbleInfo.contextTypes = {
    router: PropTypes.object.isRequired
}
