import {
    ENTRIES_LOADED, FAILED_TO_LOAD_ENTRIES,
} from '../constants';

const initialState = "pending"

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            return "loaded"
        case FAILED_TO_LOAD_ENTRIES:
            return "failed"
    }
    return state;
}
