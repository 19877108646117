import React, {Component} from 'react'
import SectionPage        from './components/section-page'
import store              from './store'
import marked             from 'marked'
import termsImg from '../images/section-page/terms.png'

export default class TermsContainer extends Component {
    getContent(){
        let index = store.getState().pages.byId
        // terms and conditions are too large for one entry.
        return index.terms.content + (index.terms2.content || '')
    }
    render(){
        return (
            <SectionPage className="terms-page"
                         headerImageUrl={termsImg}
                         html={marked(this.getContent())} />
        )
    }
}
