import _ from 'lodash'
import { ENTRIES_LOADED } from '../constants';

const initialState = {all:[], byId:{}}

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.extraVideoaudio) {
                let byId = {}, byContentfulId = {}
                let all = _.map(action.entries.extraVideoaudio, video=>{
                    byId[video.id] = video
                    byContentfulId[video._id] = video
                    return video
                })
                return {all, byId, byContentfulId}
            }
            break;
    }
    return state;
}
