import React, {Component} from 'react';
import cn from 'classnames'
import PlaylistManager from './new-player/playlist-manager'
import Audio           from './new-player/audio'
import ringOverlay from '../../images/music-player/ring-overlay.png'

let width = 82, height = 83

export default class MusicPlayer extends Component {
    constructor(props){
        super(props)
        this.isLoaded = false

        this.url = this.props.url

        this.pct = 0
        this.state = { isPlaying: false, isLoading: false }

        this.onClick      = this.onClick.bind(this)
        this.playerUpdate = this.playerUpdate.bind(this)
        this.imageLoaded  = this.imageLoaded.bind(this)

        this.callback = this.callback.bind(this)
    }
    componentDidMount() {
        Audio.addCallback(this.callback)
        PlaylistManager.addListener(this.callback)

        this._mounted = true

        this.overlay = new Image()

        this.overlay.onload = this.imageLoaded
        this.overlay.src = ringOverlay
    }

    componentWillUnmount() {
        Audio.removeCallback(this.callback)
        PlaylistManager.removeListener(this.callback)

        this.ctx     = null
        this.overlay = null

        this._mounted = false
    }

    callback({state, track}) {
        const currentTrack = PlaylistManager.currentTrack()

        // Do not update state, since this callback is called multiple times if this is not the currently playing track on the list
        // album row applies only for a list of albums that has a music player component. e.g. top 10 list.
        if (this.props.isAlbum) {
            if ((currentTrack && currentTrack.album.id) !== this.props.item.id) return
        } else {
            if ((currentTrack && currentTrack.trackId) !== this.props.item.id) return
        }

        if (this.props.makeStateInSync) {
            if (state === 'WILLPLAY') {
                this.setState({ isLoading: true })
            } else if (state === 'LIVEPLAY') {
                this.setState({ isLoading: false, isPlaying: true})
            } else if (state === 'PAUSED') {
                this.setState({ isLoading: false, isPlaying: false})
            }
        } else {
            if (state === 'WILLPLAY') {
                if (track !== this.props.item) return
                this.setState({ isLoading:true })
            } else if (state === 'LIVEPLAY' && this.state.isLoading) {
                this.setState({ isLoading:false })
            }
        }
    }

    playerUpdate(player){
        if (this.state.isPlaying !== player.isPlaying) {
            this.setState({isPlaying:player.isPlaying})
        } else {
            this.setPercentage(player.percentage)
        }
    }

    onClick(e){
        const { item, playInMiddle, showAreYouStillListeningModal, isAlbum, dummy } = this.props

        if (dummy) {
            return
        }

        const currTrack = PlaylistManager.currentTrack()
        const { isPlaying } = this.state

        e.preventDefault()
        e.stopPropagation()

        if (!isAlbum && !item.orastream ) {
            window.displayWarningMessage('missing-track')
            return
        }

        if (isAlbum) {
            if( (currTrack && currTrack.album.id) === item.id)
                if (isPlaying) {
                    Audio.pause()
                } else {
                    Audio.play()
                }
            else {
                PlaylistManager.playAlbum(item)
            }
            return
        }

        if ((currTrack && currTrack.trackId) === item.id) {
            if (isPlaying) {
                Audio.pause()
            } else {
                Audio.play()
            }
        } else {
            if (playInMiddle) {
                PlaylistManager.playTrackInMiddle(item, showAreYouStillListeningModal)
            } else {
                PlaylistManager.playFromTrackChronologically(item)
            }
        }
    }

    setPercentage(pct){
        this.pct = pct
        if (this.isLoaded) this.updateArc()
    }

    imageLoaded(){
        if (!this._mounted) return

        let canvas = this.refs.canvas

        if (!canvas) {
            requestAnimationFrame(this.imageLoaded)
            return
        }

        let ctx = canvas.getContext('2d')
        ctx.strokeStyle = 'white'
        ctx.lineWidth = 5

        this.ctx = ctx
        this.updateArc()

        this.overlay.onload = null
        this.isLoaded       = true
    }

    updateArc(){
        let ctx      = this.ctx
        let pct      = this.pct
        let radians  = (pct / 100) * 2 * Math.PI
        let start    = -.25 * 2 * Math.PI
        let diameter = 62
        let radius   = diameter / 2
        let x        = width / 2
        let y        = height / 2


        ctx.clearRect(0,0,width,height)

        ctx.globalCompositeOperation = 'source-over'
        {
            ctx.beginPath()
            ctx.arc(x,y,radius,start,start+radians)
            ctx.stroke()
        }

        ctx.globalCompositeOperation = 'source-in'
        {
            let image = this.overlay
            let w = image.width, h = image.height
            let x = (width  / 2) - (w / 2)
            let y = (height / 2) - (h / 2)

            ctx.drawImage(image, x, y)
        }

    }

    render(){
        let { scaled, disabled, hovered } = this.props
        let { isPlaying, isLoading } = this.state

        let playing     = isPlaying && !isLoading
        let paused      = !isPlaying && !isLoading || hovered
        let loading     = isLoading
        let background  = cn({ background:true, playing, paused, loading })

        let player = cn('music-player', {scaled, disabled})

        return (
            <div className={player}>
              <div className="content-wrapper" onClick={disabled ? window.subs : this.onClick}>
                <div className={background} />
                { loading && <div className="loading-indicator-wrapper"><div className="loading-indicator" /></div> }
                <canvas ref="canvas" width={width} height={height} className="canvas"></canvas>
              </div>
            </div>
        )
    }
}
