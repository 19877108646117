import React, {Component, Fragment} from 'react';
import cn from 'classnames';


const Link = (props) => {

    let selected = false;
    selected = props.page === props.current

    // This little hack is to keep top 40 and top 10 out of the newspaper's navigation system
    const location = document.location.href;
    if (props.page === 1) selected = (location.search('news/1') !== -1) && selected
    if (props.page === 8) selected = location.search('top-40-tracks') !== -1
    if (props.page === 9) selected = location.search('top-10-albums') !== -1
    //------------------------

    const cx = cn('link', { selected: selected })
    return (<div className={cx} onClick={e => props.OnClickHandler(props.page)}>
        {props.children}
    </div>)
}

const routes = {
    1: '/news/1',
    2: '/news/2',
    3: '/news/3',
    4: '/news/4',
    5: '/news/5',
    6: '/news/6',
    7: '/news/7',
    8: '/news/top-40-tracks',
    9: '/news/top-10-albums'
}

class NewsNavbar extends Component {
    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick(page) {
        const { router } = this.props;
        const route = routes[page]
        router.push(router.createLocation(route))
    }

    render() {
        const { page, router, pagesData } = this.props;

        let orderedPages = pagesData.sort((a,b) => a.order - b.order).slice(0)

        // Inject Top 40 and Top10 Links after Home Link
        orderedPages.splice(1, 0, {
            title: 'top-40',
            order: orderedPages.length + 1,
            columnsTitles: [{ title: 'Top 40', highlighted: false }]
        },
        {
            title: 'top-10',
            order: orderedPages.length + 2,
            columnsTitles: [{ title: 'Top 10', highlighted: false }]
        })
        
        const linksWithoutDivider = [orderedPages.length - 1, 0, 1]

        return (
            <div className="news-navbar">
                {
                    orderedPages.map((p,idx) => (
                            <Fragment key={p.title}>
                                <Link page={p.order} current={page} OnClickHandler={this.onClick} router={router} >
                                    { p.columnsTitles.map(c => <span className={c.highlighted ? 'highlighted' : ''} key={c.title}>{c.title}</span>)}
                                </Link>
                                { !linksWithoutDivider.includes(idx) && <div className="divider"><span>|</span></div> }
                            </Fragment>
                        )
                    )
                }
            </div>
        )
    }
}

export default NewsNavbar;