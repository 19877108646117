import cn                 from 'classnames'
import React, {Component} from 'react';
import {isFreeUser}       from '../user';

function fmt_date({year, month, day}){
    return `${month}/${day}/${year}`
}

export default class TimelineEventInfo extends Component {
    constructor(props){
        super(props)

        this.buttonClick   = this.buttonClick.bind(this)
        this.moreInfoClick = this.moreInfoClick.bind(this)
    }
    moreInfoClick(e){
        e.preventDefault()
        e.stopPropagation()

        let {event}    = this.props
        let {moreInfo} = event

        if (moreInfo && moreInfo.trim().length) {
            this.props.showMoreInfo(event)
        } else {
            console.error("don't know what to do with button click for event: ", event)
        }
    }
    buttonClick(e){
        e.preventDefault()
        e.stopPropagation()

        let {event} = this.props
        let {video, photos, moreInfo} = event

        if (video) {
            this.props.showVideo(video)
        } else if (photos && photos.length) {
            this.props.showPhotos(event)
        } else if (moreInfo && moreInfo.trim().length) {
            this.props.showMoreInfo(event)
        } else {
            console.error("don't know what to do with button click for event: ", event)
        }
    }
    render(){

        let {description, date, dateText,
             buttonText, moreInfo, video, photos} = this.props.event

        let hasMoreInfo = moreInfo && moreInfo.trim().length
        let hasOther    = video || (photos && photos.length)

        let hasButton, hasSecondButton, secondButtonText
        
        hasButton       = hasMoreInfo || hasOther
        hasSecondButton = hasMoreInfo && hasOther

        if (hasSecondButton) {
            secondButtonText = buttonText
            buttonText       = 'more info'
        }

        let buttonFn       = hasSecondButton ? this.moreInfoClick : this.buttonClick
        let secondButtonFn = this.buttonClick

        let dateStr = dateText || fmt_date(date)

        if (isFreeUser()) {
            buttonFn = secondButtonFn = window.subs
        }

        return (
            <div className="timeline-event-info">
              <div className="background" />
              <div className="content">
                <div className="description">{description}</div>
                <div className="date">{dateStr}</div>
                { hasButton ?
                  <div className="button" onClick={buttonFn}>
                    <div className="label">{buttonText}</div>
                  </div> : null }
                { hasSecondButton ?
                  <div className="button second-button" onClick={secondButtonFn}>
                    <div className="label">{secondButtonText}</div>
                  </div> : null }
              </div>
                <div className="close" onClick={this.props.onClose} />
            </div>
        )
    }
}
