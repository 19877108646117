import React, {Component}                                  from 'react'
import StripeCheckout                                      from 'react-stripe-checkout';
import PropTypes                                           from 'prop-types'
import {getUserInfo, updatePaymentInformation,
       cancelPlan}                                         from '../services/api';
import {hasSubscriptionInfo, userSubscriptionInformation,
        userCanCancelSubscription, canChangePaymentMethod, userSubscriptionType} from '../user';
//import config                                              from '../config.json';

import NyaIcon from '../../images/NYA_icon_128x128.jpg'

/*

   initial -> changingBilling -> changingBillingOK --------> (redirect)
      |            +-----------> changingBillingFail
      |
      +-----> cancelConfirmation --> cancelling --> cancellingOK --------> (redirect)
                 +---> initial           +--------> cancellingFail
*/

class Subscription extends Component {
    constructor(props, ctx) {
        super(props, ctx)

        this.state = {state:'initial'}

        this.initialGotToken       = this.initialGotToken.bind(this)
        this.initialClickCancel    = this.initialClickCancel.bind(this)
        this.initialClickViewPlans = this.initialClickViewPlans.bind(this)
        this.cancelClickGoBack     = this.cancelClickGoBack.bind(this)
        this.cancelClickConfirm    = this.cancelClickConfirm.bind(this)
    }
    componentDidUpdate(){
        switch (this.state.state) {
            case 'changingBillingOK':
            case 'cancellingOK':
                setTimeout(()=>{ document.location.href = '/' }, 3000)
                return
            case 'changingBillingFail':
            case 'cancellingFail':
            default:
                //do nothing
        }
    }
    getContent(){
        return this[this.state.state]()
    }
    goToItunesStore() {
        window.open('https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/manageSubscriptions', '_blank');
    }
    initial(){
        const { state } = userSubscriptionInformation()
        return hasSubscriptionInfo() && state !== 'cancelled-inactive' ? this.initialPaid() : this.initialFree()
    }
    _paidOverview(){
        const {state, isGift, planPrice = '0.00', renewalDateText = '', endDateText = '', customerCoupon = ''} = userSubscriptionInformation()
        if (state === 'active' && customerCoupon === 'csh0fOx7') {
            return (
                <div className="plan-info">
                  You have a lifetime NYA Membership
                </div>
            )
        } else if (state === 'active' && isGift) {
            return (
                <div className="plan-info">
                  Your gift subscription will expire at the end of the current subscription period <b>{renewalDateText}</b>.
                </div>
            )
        } else if (state === 'active') {
            return (
                <div className="plan-info">
                  Your plan will automatically renew on <b>{renewalDateText}</b> and <br />
                  you will be charged ${planPrice}.
                </div>
            )
        } else if (state === 'cancelled-active') {
            return (
                <div className="plan-info">
                  You have cancelled your subscription;<br />
                  this cancellation will take effect at the end<br />
                  of the current subscription period <b>{endDateText}</b>
                </div>
            )
        } else if (state === 'declined-active') {
            return (
                <div className="plan-info">
                  Your last payment failed,<br />
                  your account will be cancelled on <b>{endDateText}</b>
                </div>
            )
        // NOTE: this would never shown as we display NYA FREE copy for cancelled and inactivated subscriptions
        } else if (state === 'cancelled-inactive') {
            return (
                <div className="plan-info">
                  You have cancelled your subscription;<br />
                  this cancellation took effect: <b>{endDateText}</b>
                </div>
            )
        }

        //declined-inactive
        return (
            <div className="plan-info">
                Your payment failed and your account<br />
                became inactive on <b>{endDateText}</b>
            </div>
        )
    }
    initialPaid(){
        const userInfo = getUserInfo()
        const email = (userInfo.user_metadata && userInfo.user_metadata.customEmail) || userInfo.email
        return (
            <div className="content">
              <div className="plan-name">NYA Unlimited</div>
              {this._paidOverview()}
              {
                  canChangePaymentMethod() && userSubscriptionType() === 'stripe' &&
                  <StripeCheckout token={this.initialGotToken}
                                  stripeKey={process.env.STRIPE_KEY}
                                  email={email}
                                  billingAddress={true}
                                  image={NyaIcon} >
                    <div className="button clear change-payment">CHANGE PAYMENT METHOD</div><br />
                  </StripeCheckout>
              }
              {
                userSubscriptionType() === 'apple' &&  
                <div 
                    onClick={this.goToItunesStore} 
                    className="button clear change-payment"
                >
                    MANAGE
                </div>
              }
              <div className="button clear view-plans" onClick={this.initialClickViewPlans}>VIEW PLANS</div><br />
              { userCanCancelSubscription() &&
                <div 
                    className="cancel-plan" 
                    onClick={userSubscriptionType() === 'apple' ? window.appleSubWarning : this.initialClickCancel}
                >
                    CANCEL SUBSCRIPTION
                </div>
              }
            </div>
        )
    }
    initialFree(){
        return (
            <div className="content">
              <div className="plan-name">NYA FREE</div>
              <div className="plan-info"> </div>
              <div className="buttons-bottom-150">
                <div className="button" onClick={this.initialClickViewPlans}>CHANGE PLAN</div><br />
              </div>
            </div>
        )
    }
    initialGotToken(token){
        this.setState({state:'changingBilling'}, ()=>{
            updatePaymentInformation(token).then(
                ()=>{this.setState({state:'changingBillingOK'})},
                ()=>{this.setState({state:'changingBillingFail'})}
            )
        })
    }
    initialClickCancel(){
        this.setState({state:'cancelConfirmation'})
    }
    initialClickViewPlans(){
        const {router} = this.context
        router.push(router.createLocation('/account/?screen=plans'))
    }
    changingBilling(){
        return (
            <div className="content">
              <div className="message">
                Updating your billing...
              </div>
            </div>
        )
    }
    changingBillingOK(){
        return (
            <div className="content">
              <div className="message">
                Your payment information has been updated.
              </div>
            </div>
        )
    }
    changingBillingFail(){
        return (
            <div className="content">
              <div className="message">
                We're sorry, there was an error updating your billing information.<br />
                Please try again.
              </div>
            </div>
        )
    }
    cancelConfirmation(){
        return (
            <div className="content">
              <div className="plan-name">NYA Unlimited</div>
              <div className="message">
                Are you sure you want to cancel your NYA Unlimited Subscription?
              </div>
              <div className="buttons-bottom-100">
                <div className="button clear"
                     style={{marginBottom:'40px'}}
                     onClick={this.cancelClickGoBack}>GO BACK</div><br />
                <div className="button" onClick={this.cancelClickConfirm}>YES, CANCEL</div>
              </div>
            </div>
        )
    }
    cancelClickGoBack(){
        this.setState({state:'initial'})
    }
    cancelClickConfirm(){
        this.setState({state:'cancelling'}, ()=>{
            cancelPlan().then(
                ()=>{this.setState({state:'cancellingOK'})},
                ()=>{this.setState({state:'cancellingFail'})}
            )
        })
    }
    cancelling(){
        return (
            <div className="content">
              <div className="message">
                Cancelling...
              </div>
            </div>
        )
    }
    cancellingOK(){
        return (
            <div className="content">
              <div className="message">
                Your Plan has been cancelled.
              </div>
            </div>
        )
    }
    cancellingFail(){
        return (
            <div className="content">
              <div className="message">
                We're sorry, there was an error cancelling your plan.<br />
                Please try again.
              </div>
            </div>
        )
    }
    render(){
        return (
            <div className="subscription-panel panel">
              <div className="title">YOUR SUBSCRIPTION</div>
              {this.getContent()}
            </div>
        )
    }
}

Subscription.contextTypes = {
    router: PropTypes.object.isRequired
}

export default class SubscriptionPanel extends Component {
    render(){
        return <Subscription />
    }
}
