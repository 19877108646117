import React, {Component} from 'react'
import AdColumn           from './ad-column'
import Billboard          from './billboard'

export default class Top10Albums extends Component {
    render(){
        return (
          <div className="main-content article">
            <div className="column article">
              <Billboard which="top10" />
            </div>
            <AdColumn showLinks />
          </div>
        )
    }
}
