import React, {Component} from 'react'
import cn from 'classnames'
import {getMovies} from './movies'
import {Button, URLOpener} from './common.js'

class Item extends Component {
  constructor(props) {
    super(props)

    this.onWatchFilm = this.onWatchFilm.bind(this)
  }

  onWatchFilm() {
    const film = this.props.item
    if (!this.props.canViewFilm(film)) {
        window.subs()
        return
    }
    return this.props.watchFilm(film)
  }

    render() {
        let {item} = this.props
        let {isLiveStreaming, live, title, director, dateText, time, isAfterStartDate, buyLink, isBetweenStartDateAndEndDate, imageURL} = item
        let style = {backgroundImage:`url(${imageURL})`}

        let showDate = true
        if (live) showDate = false

        let label = isLiveStreaming ? "live" : "film"

        return (
            <div className={cn('item', {isAfterStartDate})}>
              <div className="image" style={style} onClick={this.onWatchFilm}/>
              <div className="description">
                <div className="title" onClick={this.onWatchFilm}>{title}</div>
                <div className="info">
                  <div className="line">Directed by {director}</div>
                  <div className="line">Running time: {time}</div>
                  {showDate && <div className="line">{dateText}</div>}
                </div>
              </div>
              <div className="buttons">
                {isAfterStartDate && isBetweenStartDateAndEndDate && <Button action={this.onWatchFilm} title={`watch ${label}`} blue={true} />}
                {buyLink && <Button action={URLOpener(buyLink)} title="buy film" />}
              </div>
            </div>
        )
    }
}

function getItems(movies, watchFilm, canViewFilm){
    let inserted = false
    let idx = 0
    let items = []
    movies.forEach(movie => {
        if (!movie.isAfterStartDate && !inserted) {
            inserted = true
            items.push(<div key={idx++} className="separator" />)
        }
        items.push(<Item key={idx++} item={movie} watchFilm={watchFilm} canViewFilm={canViewFilm}/>)
    })
    return items
}


export default class Schedule extends Component {
    render(){
        const { watchFilm, onClose, canViewFilm } = this.props
        const movies = getMovies()
        return (
            <div className="schedule">
              <div className="title-image" />
              {
                  getItems(movies, watchFilm, canViewFilm)
              }
              <div className="close-button" onClick={onClose} />
            </div>
        )
    }
}
