/*global _OS:true */
import _ from 'lodash'
import {isIPad} from '../../platform'

let quality = true //high quality
if (isIPad) quality = false

var player={
    _elapsed: 0
};

window.onbeforeunload=function(){
    if(player.timer) clearInterval(player.timer)
	player.stop(true)
}
window.onload=function(){
    _OS.init();

    _OS.service = 'orastream'

    _OS.max = quality ? 9999 : 320
    _OS.mode = quality ? 1 : 2

    player.level = 1;
    player.toggled = player._toggled = false;
    _OS.on('next', player.next)
    _OS.on('volume',function(v){
    });
}

function GET(url){
    let req = new XMLHttpRequest()
    req.open('GET', url)
    req.send()
}

player.report=function () {
    var apiUrl = 'https://www.orastream.com/api/'

    // JWT to be provided
    if(player._elapsed>33 && _OS.token) {
        var _url = apiUrl + 'v2/songs/' + player.queued[player.currentTrackIndex] + '/listen?token='
                 + _OS.token +'&played='+player._elapsed+'&client_id=neilyoungarchives';
        player._elapsed = 0;
        // added for reporting streams
        GET(_url)
        console.log('report listen:',_url);
    }

    // user_id and JWT to be provided
    if(!player._device_reported /* && _OS.user_id */ && _OS.token) {
        var p = 'Unknown'
        if(/Windows/.test(navigator.userAgent)) p = 'Windows'
        if(/Intel Mac OS X/.test(navigator.userAgent)) p = 'Mac'
        if(/Linux/.test(navigator.userAgent)) p = 'Linux'
        var _url = apiUrl + 'users/devices' + '?platform=' + p + '&type=Web&version=1.0&client_id=neilyoungarchives'
        if(_OS.user_id) _url += '&uuid=' + p + _OS.user_id;
        if(_OS.token) _url += '&token=' + _OS.token;
        //$$.get(_url);
        GET(_url)
        console.log('report device:',_url);
        player._device_reported = true;
    }
}

player.updateTrackInfo=function(index,isPrevOrNext){
    if (index === -1) {
        clearInterval(player.timer)
        player.onTrackSelect && player.onTrackSelect(-1, isPrevOrNext)
    }else
        player.onTrackSelect && player.onTrackSelect(player.currentTrackIndex, isPrevOrNext);
}

player.select=function(){
    if(player.timer) clearInterval(player.timer)
	player.timer=setInterval(player.update,500);
    player.updateTrackInfo(player.currentTrackIndex)
    player.load(player.currentTrackIndex);
}

player._prev=function(){
    if(!player.currentTrackIndex)  player.currentTrackIndex=0;
    player.report();
    player.stop(true);
    player.isPrevOrNext = -1

    if(player.currentTrackIndex-1>=0) {
    	player.currentTrackIndex--;
    	player.select();
    }
}

player._next=function(){
    if(!player.currentTrackIndex)  player.currentTrackIndex=0;
    player.report();
    player.stop(true);
    player.isPrevOrNext = 1

    if(player.currentTrackIndex+1<player.queued.length) {
    	player.currentTrackIndex++;
    	player.select();
    }
}

player.next=function(){
    if(!player.currentTrackIndex)  player.currentTrackIndex=0;
    player.report();

    if(player.currentTrackIndex+1<player.queued.length) {
    	player.currentTrackIndex++;
    	player.select();
    	setTimeout(function(){_OS.stopped=false;},3000);
    }
    else{
    	player.stop();
    	setTimeout(function(){player.updateTrackInfo(-1);},1000);
    }
}

player.enqueue = function(items, key) {
    let q = _.map(items, key)
    _OS.updateCaching(q);
    player.queued=q;
}

player.load=function(index){
    _OS.load(index,0)
}

player.play=function(flag){
    _OS.play(flag)
}

player.seek=function(value){
    _OS.scrub(parseFloat(value))
}

player.stop=function(isPrevOrNext){
    player.updateTrackInfo(-1, isPrevOrNext);
    if(player.timer) clearInterval(player.timer)
	player.timer=null;
    player.toggled=player._toggled=false;
    _OS.stop();
}

player.update=function(){
    var json={};
    _OS.updatePlayer(json);
    if(!json.position) json.position=0;
    if(!json.buffered) json.buffered=0;
    if(!json.bitrate) json.bitrate='--'
    if(!json.state) json.state='LOADING'

    // added preview check
    var preview = ''
    if(player.__elapsed!=json.elapsed){
        player.__elapsed=json.elapsed;
        player._elapsed++;
    }

    if(_OS.isPreview()) {
    	preview = ' | ('+(32-player._elapsed)+'s preview only)';
    	if(player._elapsed>32) {
    	    player.stop();
    	    player.queued = [];
    	}
    }

    if (player.callback) (player.callback(json))
}

let playerListeners = []

player.callback = function(state) {
    for (let listener of playerListeners) listener(state)
}

// let hasDisplayedPlaybackWarningMessage = false

class Audio {
    setStreamingToken(t){
        _OS.token = t
    }
    getQuality(){ return quality; }
    toggleQuality(){
        quality = !quality
        _OS.max = quality ? 9999 : 320
        _OS.mode = quality ? 1 : 2
        _OS.scrub(_OS.played - 1e-5)
    }
    playItems(tracks, index=0, isPrevNext){
        console.log("WILL PLAY FROM INDEX:", index)
        player.report();
        player.stop(isPrevNext)

        player.enqueue(tracks, 'orastream')
        player.currentTrackIndex = index;
        player.select()

        // if (!hasDisplayedPlaybackWarningMessage) {
        //     hasDisplayedPlaybackWarningMessage = true
        //     window.displayWarningMessage('audio')
        // }
    }
    stopPlaying(){
        player.stop()
    }
    play(){
        player.play(true)
    }
    pause(){
        player.play(false)
    }
    seek(pct) {
        player.seek(pct)
    }
    next(){ player._next() }
    prev(){ player._prev() }
    addCallback(fn) {
        playerListeners.push(fn)
    }
    removeCallback(fn) {
        playerListeners = playerListeners.filter(it=>it!==fn)
    }
    onTrackSelect(fnOrNull) { player.onTrackSelect = fnOrNull }
    setVolume(v){ _OS.updateVolume(v) }
    setShowAreYouStillListeningModal(showAreYouStillListeningModal) {
        player.showAreYouStillListeningModal = showAreYouStillListeningModal
    }
    isShowAreYouStillListeningModal() {
        return player.showAreYouStillListeningModal
    }
    isPrevOrNext() {
        return player.isPrevOrNext
    }
}
export default new Audio()
