import React, {Component} from 'react'
import { connect }        from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Formik, Form }   from 'formik'
import cn                 from 'classnames'
import PropTypes          from 'prop-types'
import {
  updatePlaylistAction,
  deletePlaylistAction
} from './actions/playlists'
import VerticalCenter     from './components/vertical-center'
import MusicPlayer        from './components/music-player'
import Credits            from './components/modal-credits'
import Description        from './components/description'
import VideoListing       from './components/modal-video-listing'
import More               from './components/modal-more'
import BuyPopup           from './components/buy-popup'
import PlaylistPopup      from './components/playlist-popup'
import PhotoModal         from './components/photo-modal'
import Modal              from './components/modal'
import Input              from './components/input'
import ScrollBars         from 'react-scrollbar'
import _                  from 'lodash'
import hasClass           from 'has-class'
import History            from './history'
import Audio              from './components/new-player/audio'
import PlaylistManager    from './components/new-player/playlist-manager'
import back               from './util/back'
import {albumIsLockedByID, filmIsLockedByID ,infoCardIsLockedByID, isFreeUser} from './user'
import {
  getOrastreamToken,
  deletePlaylist,
  editPlaylist,
  removeTracksFromPlaylist
} from './services/api';
import {scale}            from './scale'
import {getThumbnail} from './services/vimeo'
import moment from 'moment'

class Field extends Component {
    render(){
        let {label, children} = this.props

        let hasSlash = label.indexOf('/') > -1

        let cx=cn({
            field:true,
            [label]:true,
            'single-line': !hasSlash
        })

        return (
            <div className={cx}>
              <div className="label">{label}:</div>
              <div className="content">{children}</div>
            </div>
        )
    }
}

class AlbumHeader extends Component {
    constructor(props, ctx){
        super(props, ctx)

        this.state = {showPopup: false, showPlaylistPopup: false, showDotsPopup: false, showPlaylistDotsPopup: false, showEditTitleModal: false}

        this.showPopup   = this.showPopup.bind(this)
        this.hidePopup   = this.hidePopup.bind(this)
        this.playAlbum   = this.playAlbum.bind(this)
        this.gotoNetflix = this.gotoNetflix.bind(this)
        this.showPlaylistPopup = this.showPlaylistPopup.bind(this)
        this.hidePlaylistPopup = this.hidePlaylistPopup.bind(this)
    }
    showPopup(){
        this.setState({showPopup:true})
    }
    hidePopup(){
        this.setState({showPopup:false})
    }
    showPlaylistPopup(){
        this.setState({showPlaylistPopup:true})
    }
    hidePlaylistPopup(){
        this.setState({showPlaylistPopup:false})
    }
    playAlbum(){
        let {album} = this.props
        PlaylistManager.playAlbum(album)
    }
    gotoNetflix(){
        const {netflixLink} = this.props.album
        window.open(netflixLink)
    }
    render(){

        let { fields, image, largeArtwork, showPlayAlbumButton, album, buyOptions, buyLinks } = this.props
        let { showPopup, showPlaylistPopup, showDotsPopup, showPlaylistDotsPopup, showEditTitleModal } = this.state
        let { router } = this.context

        let disabled = false
        if (album.isAlbum) {
            disabled = albumIsLockedByID(album.id)
        } else if (album.isFilm) {
            disabled = filmIsLockedByID(album.id)
        } else if (album.isPlaylist && isFreeUser()) {
            disabled = true
        }

        const netflix = album.netflixLink

        let hasBuyLinks = !netflix && (buyLinks && buyLinks.length > 0)

        let backgroundImage = `url('${image}')`

        let hasArt   = largeArtwork && largeArtwork.length > 0
        let clickArt = hasArt ? this.props.onArtworkClick : undefined

        let albumCover = cn('album-cover', {'has-art':hasArt})
        let cx = cn('right-content', {'is-album':this.props.isAlbum, 'is-film': !this.props.isAlbum})

        let { tracks, isPlaylist, isFilm } = album

        const isPlaylistFromContentful = isPlaylist && album.type === 'playlist'

        let customLinksForDotsButton = [
          {title: 'Add to playlist >', isCustomItem: true, customItemClick: (e) => {
            const data = e.target.getBoundingClientRect()
            const iscale = 1/scale()
            const topCoordinate = (e.clientY - 150) * iscale
            const leftCoordinate = (data.left + data.width - 15) * iscale

            this.setState({showPlaylistDotsPopup : {top: topCoordinate, left: leftCoordinate}})
          }}
        ]

        if (isPlaylist && !isPlaylistFromContentful) {
          customLinksForDotsButton.push({title: 'Delete this playlist', isCustomItem: true, customItemClick: () => {
            deletePlaylist(album._id)
              .then(() => {
                router.push({
                  pathname: '/drawer',
                  state: { updateItems: true }
                })
                this.props.dispatch(deletePlaylistAction(album._id))
              })
              .catch(err => {
                console.error(err)
              })
          }}, {title: 'Edit playlist title', isCustomItem: true, customItemClick: () => {
            this.setState({showEditTitleModal: true})
          }}, )
        }

        const hideDotsPopup = () => {
          this.setState({showDotsPopup: false})
        }
        const hidePlaylistDotsPopup = () => {
          this.setState({showPlaylistDotsPopup: false})
        }
        const onCloseModal = () => {
          this.setState({showEditTitleModal: false})
        }

        return (
            <div className="album-header">
                <div className="left-content">
                    <div className={albumCover} onClick={disabled ? window.subs : clickArt} style={{backgroundImage}}>
                        { album.type === 'playlist-custom' &&
                            <div className="collage-group" >
                                { album.tracks.slice(0, 4).map((track, idx, array) => {
                                        let collageCover = cn('collage-cover', { 'collage-only-one': array.length === 1})
                                        return <div
                                            className={collageCover}
                                            key={track._id}
                                            style={{backgroundImage: `url('${track.infoCardAlbumImage}')`}}
                                        />
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className={cx}>
                    <div className="fields">
                        { fields.map((field, idx)=><Field key={idx} label={field.label}>{field.value}</Field>)}
                    </div>
                    { showPlayAlbumButton &&
                        <div className="play-album-button">
                            <div className="hit-area" onClick={disabled ? window.subs : this.playAlbum} />
                        </div>
                    }
                    { netflix &&
                        <div className="netflix-button" onClick={this.gotoNetflix} />
                    }
                    { !isFilm &&
                        <div className="rsdSearchButton" onClick={() => {
                          router.push('/find-store')
                        }}></div>
                    }
                    <div className="buy">
                        { hasBuyLinks &&
                            <div className="blurb">
                                Available on {buyOptions}
                            </div>
                        }
                        { hasBuyLinks &&
                            <div className={cn("buy-button", {'buy-button--single': album.isFilm})} onClick={this.showPopup}>
                                { showPopup &&
                                    <div className="buy-popup-wrapper">
                                        <BuyPopup buyLinks={buyLinks} hidePopup={this.hidePopup} />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {album.isPlaylist && (
                      <div className="dots-button" onClick={(e) => { this.setState({showDotsPopup: true}) }}>
                        <div className="dot" />
                        <div className="dot" />
                        <div className="dot" />
                        { showDotsPopup ? (
                          <BuyPopup
                            buyLinks={customLinksForDotsButton}
                            isTrack
                            hidePopup={hideDotsPopup}
                            style={{top: '-40px', left: isPlaylistFromContentful ? '-200px' : '-275px'}}
                            playlistBackground={!isPlaylistFromContentful}
                        />
                        ) : null}
                        { showPlaylistDotsPopup ?(
                          <PlaylistPopup
                              hidePopup={() => {
                                hidePlaylistDotsPopup()
                                hideDotsPopup()
                              }}
                              style={{top: isPlaylistFromContentful ? '-31px' : '-28px', left: '110px'}}
                              tracks={tracks.map((item) => item.id)}
                              tapeLeft
                          />
                      ): null}
                        <Modal
                          isOpen={showEditTitleModal}
                          onRequestClose={onCloseModal}
                          shouldCloseOnEsc={false}
                        >
                            <Formik
                              initialValues={{ title: album.title }}
                              onSubmit={(values, actions) => {
                                  editPlaylist(album._id, {title: values.title})
                                      .then((result) => {
                                          actions.setSubmitting(false)
                                          this.props.dispatch(updatePlaylistAction(result.body))
                                          onCloseModal()
                                          hideDotsPopup()
                                      })
                                      .catch(err => {
                                          actions.setSubmitting(false)
                                          actions.setFieldError('title', 'Something went wrong')
                                      })
                              }}
                              validate={(values) => {
                                  let errors = {}

                                  if (!values.title) {
                                      errors.title = 'Required'
                                  } else if (values.title.length >= 20) {
                                      errors.title = 'You have exceeded max number of characters'
                                  }

                                return errors
                              }}
                              render={props => (
                                  <Form className="content">
                                    <Input
                                      className='input'
                                      label='PLAYLIST NAME'
                                      limit={20}
                                      isFormikField
                                      name='title'
                                      setAutofocus
                                    />
                                    <div className="buttons-wrapper">
                                      <div className="button button--back" onClick={onCloseModal}>CANCEL</div>
                                      <button
                                          className={cn("button button--continue", {'disabled': props.isSubmitting})}
                                          type="submit"
                                      >SAVE</button>
                                    </div>
                                  </Form>
                                  )}
                              />
                        </Modal>
                      </div>
                    )}
                    {album.isAlbum && (
                        <div className='playlistButtonWrapper'>
                            <div className='playlistButton' onClick={this.showPlaylistPopup} />
                            { showPlaylistPopup ?
                                <PlaylistPopup
                                    hidePopup={this.hidePlaylistPopup}
                                    style={{top: '-25px', left: '-30px'}}
                                    tracks={album.tracks.map((item) => item.id)}
                                />
                                : null}
                        </div>
                    )}
                </div>
                <br className="clearfix" />
            </div>
        )
    }
}

AlbumHeader.contextTypes = {
    router: PropTypes.object.isRequired
}

class AlbumFooter extends Component {
    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    onClick(){
        let {router} = this.context
        let {album}  = this.props
        let {id, releaseDate} = album
        let {month, year} = releaseDate

        let last = History.lastPath()
        let wasMonths = (last && last.indexOf('months') > -1)

        let pathname = wasMonths ? '/timeline-months' : '/timeline'
        let query = {currAlbum:id, month, year}

        //query parameters are duplicated if this is not deleted for some reason
        let location = router.createLocation({pathname, query})
        delete location.search

        router.push(location)
    }
    render(){
        return (
            <VerticalCenter className="album-footer">
              <center>
                <div className="view-on-timeline"
                     onClick={this.onClick} ></div>
              </center>
            </VerticalCenter>
        )
    }
}

AlbumFooter.contextTypes = {
    router: PropTypes.object.isRequired
}

class Track extends Component {
    constructor(props, context){
        super(props, context)

        this.state = {hover:false}

        this.onMouseEnter = this.onMouseEnter.bind(this)
        this.onMouseLeave = this.onMouseLeave.bind(this)
        this.showBuyPopup   = this.showBuyPopup.bind(this)
        this.showPlaylistPopup   = this.showPlaylistPopup.bind(this)
        this.onBuyButtonClick   = this.onBuyButtonClick.bind(this)
        this.onPlaylistButtonClick   = this.onPlaylistButtonClick.bind(this)
    }
    showBuyPopup(e, customLinks, playlistBackground){
        this.props.showBuyPopup({buyLinks: customLinks || this.props.buyLinks, clientX: e.clientX, clientY: e.clientY, playlistBackground})
    }
    showPlaylistPopup(e){
        this.props.showPlaylistPopup({id: this.props.track.id, clientX: e.clientX, clientY: e.clientY, data: e.target.getBoundingClientRect()})
    }
    onMouseEnter(){
        this.setState({hover:true})
    }
    onMouseLeave(){
        this.setState({hover:false})
        // this.props.hideBuyPopup()
    }
    formatDuration(length){
        if (!length) return '00:00';
        return length
    }
    onBuyButtonClick(e, customLinks, playlistBackground){
        e.preventDefault()
        e.stopPropagation()
        this.showBuyPopup(e, customLinks, playlistBackground)
        this.props.onBuyClick(e)
    }
    onPlaylistButtonClick(e){
        e.preventDefault()
        e.stopPropagation()
        this.showPlaylistPopup(e)
    }
    render(){
        let {
          track,
          trackNumber,
          star,
          odd,
          currentlyPlaying,
          buyLinks,
          isPlaylist,
          isPlaylistFromContentful,
          playlistId,
          hideBuyPopup
        } = this.props

        let {hover} = this.state

        let {id, title, length}    = track

        let time = this.formatDuration(length)

        let scale = 35 / 66 /* for scaling down the music player inline */
        let style = {transform:`scale(${scale})`}

        let hasInfoCard = !(track.listOnly || track.hiddenTrack)
        let showsOnTimeline = !(track.listOnly || track.hiddenTrack)

        let titleClass = cn('title', {currentlyPlaying})
        let trackClass = cn('track', {odd})

        let customLinksForDotsButton = [
          {title: 'Add to playlist >', isCustomItem: true, customItemClick: (e) => {
            this.onPlaylistButtonClick(e)
          }}
        ]

        if (isPlaylist && !isPlaylistFromContentful) {
          customLinksForDotsButton.push({title: 'Remove from this playlist', isCustomItem: true, customItemClick: () => {

            removeTracksFromPlaylist(playlistId, [track.id])
              .then(result => {
                hideBuyPopup()
                this.props.dispatch(updatePlaylistAction(result.body))
              })
              .catch(err => {
                console.error(err)
              })
          }})
        }

        if (!buyLinks && track.album && track.album.purchaseOptions && track.album.purchaseOptions.pono) {
          const token = getOrastreamToken();
          const tokenString = token ? `?user_jwt=${token}` : '';
          customLinksForDotsButton.push({title: 'high-res from\nXstream by NYA', link: track.album.purchaseOptions.pono + tokenString, type: 'xstream'})
        }

        // Check if is available for free users
        const disabled = infoCardIsLockedByID(track.id)

        return (
            <li className={trackClass} onClick={this.props.onClick}
                onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}
                data-music-id={id}>
              <div className="shade" />
              {
                  currentlyPlaying || hover ?
                  <div className="music-wrapper-wrapper music-wrapper-wrapper--dummy">
                    <div className="music-wrapper" style={style}>
                        <MusicPlayer
                            item={track}
                            scaled={true}
                            disabled={disabled}
                            makeStateInSync
                            dummy
                        />
                    </div>
                  </div>
                  : <div className="track-number">{trackNumber}</div>
              }
              <div className={titleClass} data-music-id={id}>{title}{star}</div>
              <div className="track-duration">{time === '00:00' ? '' : time}</div>
              {
                  hover && buyLinks ? (
                    <div className="buy"
                        data-music-id={id}
                        onClick={this.onBuyButtonClick}
                    />
                  ) : null
              }
              { hover ? (
                <div className='playlistButtonWrapper'>
                    <div className='playlistButton' onClick={isPlaylist ? (e) => {this.onBuyButtonClick(e, customLinksForDotsButton, !isPlaylistFromContentful)} : this.showPlaylistPopup} />
                </div>
              ) : null}
              {
                  showsOnTimeline ?
                  <div className="track-timeline-icon" onClick={this.props.onTrackClick} data-id={id} />
                  : <div className="track-timeline-icon hidden" />
              }
              {
                  hasInfoCard ?
                  <div className="track-infocard-icon-wrapper">
                    <div className="track-infocard-icon"
                         onClick={this.props.onInfoClick}
                         data-id={id}></div>
                  </div>
                  : null
              }
            </li>
        )
    }
}

class TrackListing extends Component {
    constructor(props, b){
        super(props,b)

        this.state = {currTrack:PlaylistManager.currentTrack()}

        this.onInfoClick         = this.onInfoClick.bind(this)
        this.onTrackClick        = this.onTrackClick.bind(this)
        this.onTrackElementClick = this.onTrackElementClick.bind(this)
        this.onBuyClick          = this.onBuyClick.bind(this)

        this.audioCallback = this.audioCallback.bind(this)
        this.playlistCallback = this.playlistCallback.bind(this)
    }
    componentDidMount(){
        Audio.addCallback(this.audioCallback)
        PlaylistManager.addListener(this.playlistCallback)
    }
    componentWillUnmount(){
        Audio.removeCallback(this.audioCallback)
        PlaylistManager.removeListener(this.playlistCallback)
    }
    audioCallback({state: playerStatus}) {
        this.setState({playerStatus})
    }
    playlistCallback({track: currTrack}){
        this.setState({currTrack})
    }
    onBuyClick(e){
        e.preventDefault()
        e.stopPropagation()
        let id = e.target.getAttribute('data-music-id')
        console.log("got track id: ", id)
        let track = this.props.tracks[id]
        console.log("got track:", track)
    }
    onTrackElementClick(e){
        e.preventDefault()
        e.stopPropagation()

        let id = e.target.getAttribute('data-music-id')

        console.log("got track id: ", id)

        if (!id) return
        let { album } = this.props
        let track  = this.props.tracks[id]

        if (albumIsLockedByID(album.id)) {

            // If album is locked then check the track
            if(infoCardIsLockedByID(id)) {
                window.subs()
                return
            }
        }
        // If album is not locked don't check the individual track
        if (!track.orastream) {
            window.displayWarningMessage('missing-track')
            return
        }

        const {currTrack, playerStatus} = this.state
        if (id === _.get(currTrack, 'id')) {

            if (playerStatus === 'LIVEPLAY') {
                PlaylistManager.pause()
            }

            if (playerStatus === 'PAUSED') {
                PlaylistManager.play()
            }

            return
        }

        PlaylistManager.playFromTrackOnAlbum(track, album)
    }

    onInfoClick(e){
        let id = e.target.getAttribute('data-id')
        this.props.viewInfoCard(id)
        e.preventDefault()
        e.stopPropagation()
    }

    onTrackClick(e){
        let id = e.target.getAttribute('data-id')
        this.props.viewTrackOnTimeline(id)
    }
    render(){
        let {album, showBuyPopup, hideBuyPopup, showPlaylistPopup, hidePlaylistPopup, buyLinks, dispatch}  = this.props
        let {tracks, isPlaylist} = album
        const isPlaylistFromContentful = isPlaylist && album.type === 'playlist'
        const iscale = 1/scale()


        let {currTrack} = this.state

        return (
            <div className="track-listing">
              <div className="header">
                <div className="number">#</div>
                <div className="title">TITLE</div>
              </div>
              { album.type === 'playlist-custom' ? (
                <Droppable droppableId={album._id} type='playlist'>
                  {(provided) => (
                    <ol
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="tracks"
                    >
                      {tracks.map((track, idx) => {
                        let star = album.isPlaylist ? '' : album.trackStars[idx]
                        let odd = ((idx + 1) % 2) === 1

                        let currentlyPlaying = currTrack === track

                        return (
                          <Draggable key={`draggable-${album._id}-${idx}`} draggableId={`draggable-${album._id}-${idx}`} index={idx} type='playlist'>
                            {(provided, snapshot) => {
                              const style = {
                                ...provided.draggableProps.style,
                                width: '1000px',
                                height: '34px',
                                top: `${iscale * provided.draggableProps.style.top}`,
                                left: `${iscale * provided.draggableProps.style.left}`
                              };
                              return (
                                <div
                                  key={'rowWrap' + idx}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={style}
                                >
                                  <Track
                                    trackNumber={idx + 1}
                                    onInfoClick={this.onInfoClick}
                                    showBuyPopup={showBuyPopup}
                                    hideBuyPopup={hideBuyPopup}
                                    showPlaylistPopup={showPlaylistPopup}
                                    hidePlaylistPopup={hidePlaylistPopup}
                                    onTrackClick={this.onTrackClick}
                                    onBuyClick={this.onBuyClick}
                                    buyLinks={isPlaylist ? null : buyLinks}
                                    onClick={this.onTrackElementClick}
                                    isPlaylist={isPlaylist}
                                    isPlaylistFromContentful={isPlaylistFromContentful}
                                    playlistId={isPlaylist && album._id}
                                    currentlyPlaying={currentlyPlaying}
                                    dispatch={dispatch}
                                    track={track}
                                    star={star}
                                    odd={odd}
                                    key={idx}
                                  />
                                </div>
                              )
                            }}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </ol>
                  )}
                </Droppable>
              ) : (
                <ol className="tracks">
                  {tracks.map((track, idx) => {
                        let star = album.isPlaylist ? '' : album.trackStars[idx]
                        let odd = ((idx + 1) % 2) === 1

                        let currentlyPlaying = currTrack === track

                        return (
                          <Track
                            trackNumber={idx + 1}
                            onInfoClick={this.onInfoClick}
                            showBuyPopup={showBuyPopup}
                            hideBuyPopup={hideBuyPopup}
                            showPlaylistPopup={showPlaylistPopup}
                            hidePlaylistPopup={hidePlaylistPopup}
                            onTrackClick={this.onTrackClick}
                            onBuyClick={this.onBuyClick}
                            buyLinks={isPlaylist ? null : buyLinks}
                            onClick={this.onTrackElementClick}
                            isPlaylist={isPlaylist}
                            isPlaylistFromContentful={isPlaylistFromContentful}
                            playlistId={isPlaylist && album._id}
                            currentlyPlaying={currentlyPlaying}
                            dispatch={dispatch}
                            track={track}
                            star={star}
                            odd={odd}
                            key={idx}
                          />
                        )
                    })}
                </ol>
                )

              }
            </div>
        )
    }
}

class AlbumTabs extends Component {
    constructor(props, b){
        super(props,b)
        this.onClick = this.onClick.bind(this)
    }
    onClick(e){
        let tab = e.target.getAttribute('data-tab')
        this.props.onChange(tab)
    }
    getTabs(){
        let {current, tabs} = this.props
        return tabs.map(tab=>{
            let selected = tab === current
            let cx = cn('album-tab', {selected})
            return (
                <div className={cx}
                     key={tab}
                     data-tab={tab}
                     onClick={this.onClick}>{tab}</div>
            )
        })
    }
    render(){
        return (
            <div className="album-tabs">
              <div className="album-tabs-container">
                {this.getTabs()}
                <div className="remainder">X</div>
              </div>
            </div>
        )
    }
}

class AlbumModal extends Component {
    constructor(props, b){
        super(props, b)

        this.state = {buyPopup: null, playlistPopup: null, videoThumbnails: []}

        this.updateTabs          = this.updateTabs.bind(this)
        this.onBGClick           = this.onBGClick.bind(this)
        this.viewOnTimeline      = this.viewOnTimeline.bind(this)
        this.viewTrack           = this.viewTrack.bind(this)
        this.clickRelated        = this.clickRelated.bind(this)
        this.onCloseClick        = this.onCloseClick.bind(this)
        this.onMoreClick         = this.onMoreClick.bind(this)
        this.hidePhotoModal      = this.hidePhotoModal.bind(this)
        this.photoModalUpdate    = this.photoModalUpdate.bind(this)
        this.viewTrackOnTimeline = this.viewTrackOnTimeline.bind(this)
        this.onArtworkClick      = this.onArtworkClick.bind(this)
        this.renderBuyPopup      = this.renderBuyPopup.bind(this)
        this.renderPlaylistPopup = this.renderPlaylistPopup.bind(this)
        this.hideBuyPopup        = this.hideBuyPopup.bind(this)
        this.showBuyPopup        = this.showBuyPopup.bind(this)
        this.hidePlaylistPopup   = this.hidePlaylistPopup.bind(this)
        this.showPlaylistPopup   = this.showPlaylistPopup.bind(this)
        this.getThumbnailsUrls   = this.getThumbnailsUrls.bind(this)
        this.getCurrentAlbumAndTab = this.getCurrentAlbumAndTab.bind(this)
    }
    componentDidMount(){
      const { album } = this.getCurrentAlbumAndTab()
      if (album && album.more) {
        this.getThumbnailsUrls(album.more)
      }
    }
    getThumbnailsUrls(items) {
        // This method fetchs from vimeo api the urls for thumbnails, it is loaded here so there is no awaiting when its time to show the videos modal
        let videos = items.filter(v => v && v.vimeoId) // Check if values are defined, could be null if videos were deleted from contentful

        if(videos.length > 0) {
            let thumbnailsPromises = videos.map(v => getThumbnail(v.vimeoId))
            Promise.all(thumbnailsPromises).then( results => {
                this.setState({
                    videoThumbnails: results
                })
            })
        }
    }
    _getPhotoForModal(album){
        let id = this.props.location.query.photo
        if (id) {
            let collection = this._getPhotoCollection(id, album)
            let idx = _.findIndex(collection, {id})
            return collection[idx]
        } else {
            return null
        }
    }
    _getPhotoCollection(idProp, albumProp) {
        let {id} = this.props.location.query
        let album = albumProp || this.getQueryItemById(id)

        if (idProp.match(/^aa_/i)) return album.largeArtwork
        else                   return album.more
    }
    getCurrentAlbumAndTab(){
        let {id, tab} = this.props.location.query

        let album = this.getQueryItemById(id)

        // Playlist are always accesible, album and film depend on "free" property
        let locked = false

        // videos and more should be unavailable for free users
        if (album && album.isAlbum && (tab === 'related' || tab === 'videos')) {
          locked = albumIsLockedByID(id)
        }

        if (album && album.isFilm && (tab === 'related' || tab === 'videos')) {
          locked = filmIsLockedByID(id)
        }

        tab = tab || this.defaultTab(album)

        if (locked) tab = this.defaultTab(album)

        return {album, tab}
    }
    clickRelated(e){
        e.preventDefault()
        e.stopPropagation()

        let id = e.target.getAttribute('data-id')

        let album = this.props.albums[id]

        let {tab} = this.props.location.query
        tab = tab || this.defaultTab(album)

        if (!album) {
            console.error("could not find album: ", id)
            return
        }

        let {router} = this.context
        router.replace(router.createLocation(`${this.baseUrl()}?id=${id}&tab=${tab}`))
    }
    viewTrackOnTimeline(id){
        let {router} = this.context
        let pathname = '/timeline-months'
        let track    = this.props.tracks[id]
        let {month, year} = track.releaseDate
        let query = {currTrack:id, month, year}
        //query parameters are duplicated if this is not deleted for some reason
        let location = router.createLocation({pathname, query})
        delete location.search
        router.push(location)
    }
    onArtworkClick(){
        const { album, tab } = this.getCurrentAlbumAndTab()

        let {id}         = album.largeArtwork[0]
        let albumId      = album.id
        let {router}     = this.context
        router.push(router.createLocation(`${this.baseUrl()}?id=${albumId}&tab=${tab}&photo=${id}`))
    }
    onMoreClick(asset, e){
        let {router} = this.context
        const { album } = this.getCurrentAlbumAndTab()
        if (asset.videoUrl || asset.vimeoId) {
            router.push(router.createLocation(`/video?id=${asset._id}&related=1`))
        } else {
            router.push(router.createLocation(`${this.baseUrl()}?id=${album.id}&tab=related&photo=${asset.id}`))
        }
    }
    photoModalUpdate(photo){
        const { album, tab } = this.getCurrentAlbumAndTab()

        let {router} = this.context
        let albumId  = album.id
        let {id}     = photo

        router.replace(router.createLocation(`${this.baseUrl()}?id=${albumId}&tab=${tab}&photo=${id}`))
    }
    hidePhotoModal(){
        this.context.router.goBack()
    }
    viewOnTimeline(){
        //TODO: verify correct view
        let {router} = this.context
        const { album } = this.getCurrentAlbumAndTab()
        let {id, releaseDate} = album
        let {month, year} = releaseDate

        let pathname = '/timeline-months'
        let query = {currAlbum:id, month, year}

        //query parameters are duplicated if this is not deleted for some reason
        let location = router.createLocation({pathname, query})
        delete location.search

        router.push(location)
    }
    viewTrack(id){
        let {router} = this.context
        router.push(router.createLocation(`/info-card?track=${id}`))
    }
    updateTabs(tab){
        const { album } = this.getCurrentAlbumAndTab()
        let {id}      = album

        let disabled = false

        if (album.isAlbum) disabled = albumIsLockedByID(id)
        else if (album.isFilm) disabled = filmIsLockedByID(id)

        if (disabled && (tab === 'related' || tab === 'videos')) {
            window.subs()
            return
        }

        let {router} = this.context
        router.replace(router.createLocation(`${this.baseUrl()}?id=${id}&tab=${tab}`))
    }
    onBGClick(e){
        let el = e.target
        if (el === this.refs.background ||
            hasClass(el, 'album-modal-contents-center') ||
            hasClass(el, 'album-modal-contents-center-inner') ||
            hasClass(el, 'background-mousefix-left') ||
            hasClass(el, 'background-mousefix-right')
        ) {
            e.preventDefault()
            e.stopPropagation()
            back(this.context.router)
        }
    }
    onCloseClick(){
        back(this.context.router)
    }
    showPlayAlbumButton(){
        const { album } = this.getCurrentAlbumAndTab()
        return album && album.hasAudioTracks && !album.isFilm
    }
    buyingOptions(){
        const { album } = this.getCurrentAlbumAndTab()
        return album.isFilm ? 'DVD & Blu-ray' : 'PONO, CD, Vinyl, DVD & Blu-ray'
    }
    buyingLinks(){
        const { album } = this.getCurrentAlbumAndTab()
        let buyLinks = []

        if (album.isFilm && album.buyLink) {
          return [{title: 'from neil young\'s\ngreedy hand store', link: album.buyLink, type: 'handStore'}]
        }

        if (album && album.purchaseOptions) {
            if (album.purchaseOptions.pono) {
                const token = getOrastreamToken();
                const tokenString = token ? `?user_jwt=${token}` : '';
                buyLinks.push({title: 'high-res from\nXstream by NYA', link: album.purchaseOptions.pono + tokenString, type: 'xstream'});
            }

            if (album.purchaseOptions.reprise) {
                buyLinks.push({title: 'from neil young\'s\ngreedy hand store', link: album.purchaseOptions.reprise, type: 'handStore'});
            }
        }

        return buyLinks.length > 0 ? buyLinks : null
    }
    baseUrl(){
        const { album } = this.getCurrentAlbumAndTab()
        return album.isFilm ? '/film' : '/album'
    }
    getQueryItemById(id){
        const { playlists, albums, films } = this.props
        let album = albums[id]
        let playlist = playlists[id]
        let film = films[id]
        return album || playlist || film
    }
    defaultTab(album){
        return (album && album.isFilm) ? (this.tabList(album)[0] ||'info') : 'songs'
    }
    tabList(album){
        let exists = key => {
            let it = album[key]
            if (!it) return false
            if (_.isArray(it) && it.length === 0) return false
            if (_.isString(it) && it.trim().length === 0) return false
            return true
        }

        if (album.isFilm) {
            let tabs = [];

            ['info', 'credits', 'articles', 'videos', 'more'].forEach(tab=>{
                if (exists(tab)) tabs.push(tab === 'more' ? 'related' : tab) // Fix for more field used for related tab now
            })

            return tabs
        }

        let tabs = ['songs'];

        ['credits', 'articles', 'videos', 'more', 'description'].forEach(tab=>{
            if (exists(tab)) tabs.push(tab === 'more' ? 'related' : tab) // Fix for more field used for related tab now
        })

        return tabs
    }
    renderCurrentTab(tab){
        const { album } = this.getCurrentAlbumAndTab()

        if (album.isFilm) {
            let {info, credits, articles, videos, more} = album

            if (tab === 'info') {
                return <Description simpleHTML={info && info[0] && info[0].content} />
            }
            if (tab === 'credits') {
                return <Credits credits={credits || []} />
            }
            if (tab === 'articles') {
                return <Description richHTML={articles} hideHeader/>
            }
            if (tab === 'videos') {
                return <VideoListing videos={videos || []} />
            }
            if (tab === 'related') {
                return <More assets={more || []} thumbnails={this.state.videoThumbnails} onClickItem={this.onMoreClick} />
            }
        }

        if(album.isPlaylist && album.type === 'playlist') {
            if (tab === 'description') {
                return <Description richHTML={album.description} hideHeader />
            }
        }

        if (tab === 'songs') {
            return <TrackListing
                dispatch={this.props.dispatch}
                album={album}
                buyLinks={this.buyingLinks()}
                viewTrackOnTimeline={this.viewTrackOnTimeline}
                viewInfoCard={this.viewTrack}
                hideBuyPopup={this.hideBuyPopup}
                showBuyPopup={this.showBuyPopup}
                hidePlaylistPopup={this.hidePlaylistPopup}
                showPlaylistPopup={this.showPlaylistPopup}
                tracks={this.props.tracks}
            />
        }
        else if (tab === 'credits') { return  <Credits credits={album.credits} /> }
        else if (tab === 'articles') { return  <Description richHTML={album.articles} hideHeader/> }
        else if (tab === 'videos')  { return  <VideoListing videos={album.videos} />  }
        else if (tab === 'related')    { return  <More assets={album.more}
                                                    onClickItem={this.onMoreClick}
                                                    thumbnails={this.state.videoThumbnails} />  }
    }
    renderBuyPopup(){
        let { buyPopup } = this.state

        if (buyPopup) {
            const iscale = 1/scale()
            const topCoordinate = buyPopup.clientY - 150

            return (
                <BuyPopup
                    buyLinks={buyPopup.buyLinks}
                    isTrack
                    hidePopup={this.hideBuyPopup}
                    style={{top: `${topCoordinate * iscale}px`, left: '50%'}}
                    playlistBackground={buyPopup.playlistBackground}
                />
            )
        }


    }
    renderPlaylistPopup(){
        let { playlistPopup } = this.state

        if (playlistPopup) {
            const iscale = 1/scale()
            const topCoordinate = playlistPopup.clientY - 150
            const leftCoordinate = playlistPopup.data && playlistPopup.data.left + playlistPopup.data.width - 15

            return (
                <PlaylistPopup
                    hidePopup={this.hidePlaylistPopup}
                    style={{top: `${topCoordinate * iscale}px`, left: leftCoordinate ? `${leftCoordinate * iscale}px` : '60%'}}
                    tracks={[playlistPopup.id]}
                    tapeLeft={playlistPopup.data}
                />
            )
        }
    }
    hideBuyPopup(){
        this.setState({buyPopup: null})
    }
    showBuyPopup(props){
        this.setState({buyPopup: props})
    }
    hidePlaylistPopup(){
        this.setState({playlistPopup: null})
    }
    showPlaylistPopup(props){
        this.setState({playlistPopup: props})
    }
    albumHeaderFields(){
        const { album } = this.getCurrentAlbumAndTab()
        let {month, day, year}        = album.displayDate || album.releaseDate

        let {title, artists, releaseDateText, catalog, series, unreleased, creator, director, producer, cinematographer, releases} = album

        let date = releaseDateText || `${month}/${day}/${year}`
        if(album.isPlaylist && album.type === 'playlist-custom') date = moment.utc(album.displayDate).local().format('l') // displayDate is set by management api
        if (unreleased) date = ''

        let artist = (artists && artists[0] && artists[0].name) || ""

        if (album.isPlaylist) {
            return [
                {label:'playlist title', value:title},
                {label:'creator',       value:creator},
                {label:'creation date', value:date},
            ]
        } else if (album.isFilm) {
            return [
                {label:'title',                value:title},
                {label:'directed by',          value:director},
                {label:'produced by',          value:producer},
                {label:'cinematography',       value:cinematographer},
                {label:'release date/ format', value:releases},
            ]
        } else {
            return [
                {label:'album',        value:title},
                {label:'artist',       value:artist},
                {label:'release date', value:date},
                {label:'catalog #',    value:catalog},
                {label:'series',       value:series}
            ]
        }

    }
    renderContent(){
        const { album, tab } = this.getCurrentAlbumAndTab()
        let tabs = this.tabList(album)

        let hasTabs = tabs && tabs.length

        let fields = this.albumHeaderFields()

        const background = this.props.location.query.b === 'true'
        let style

        if (background) {
          style = {["backgroundColor"]: "rgba(0,0,0,0.7)"}
        }

        let prev = album.previousRelease
        let next = album.nextRelease

        let image = album.albumViewImage

        return (
            <div ref="background" style={style} className="album-modal" onClick={this.onBGClick}>
              {background ? null : <div className="gradient-left" />}
              {background ? null : <div className="gradient-right" />}
              <VerticalCenter className="album-modal-contents-center"
                              innerClassName="album-modal-contents-center-inner"
                              onClick={this.onBGClick}>
                <div className="album-modal-contents">
                  <div className="background">
                    <div className="background-mousefix-left"
                         onClick={this.onBGClick} />
                    <div className="background-mousefix-right"
                         onClick={this.onBGClick}/>
                  </div>
                  <div className="close-button" onClick={this.onCloseClick} />
                  <div className="album-modal-top">
                    <AlbumHeader image={image} fields={fields}
                                 showPlayAlbumButton={this.showPlayAlbumButton()}
                                 album={album}
                                 isAlbum={album.isAlbum}
                                 largeArtwork={album.largeArtwork}
                                 buyOptions={this.buyingOptions()}
                                 buyLinks={this.buyingLinks()}
                                 onArtworkClick={this.onArtworkClick}
                                 dispatch={this.props.dispatch}
                                 viewOnTimeline={this.viewOnTimeline} />
                    { hasTabs &&
                      <AlbumTabs
                          tabs={tabs}
                          current={tab}
                          onChange={this.updateTabs}/> }
                  </div>
                  { hasTabs ?
                    <ScrollBars key={tab}>
                      {this.renderCurrentTab(tab)}
                    </ScrollBars>
                  : null }
                  <AlbumFooter album={album} />
                  <div className="glow-left" />
                  <div className="glow-right" />
                </div>
                {this.renderBuyPopup()}
                {this.renderPlaylistPopup()}
              </VerticalCenter>

              { prev ?
                <VerticalCenter className="related previous">
                <div className="content">
                  <div className="arrow" data-id={prev.id} onClick={this.clickRelated} ></div>
                  <div className="label" data-id={prev.id} onClick={this.clickRelated} >previous</div>
                </div>
                </VerticalCenter>
                : null
              }
              { next ?
                <VerticalCenter className="related next">
                <div className="content">
                  <div className="label" data-id={next.id} onClick={this.clickRelated} >next</div>
                  <div className="arrow" data-id={next.id} onClick={this.clickRelated} ></div>
                </div>
                </VerticalCenter>
                : null
              }
              <div className="corner-protector lower left" />
              <div className="corner-protector lower right" />
            </div>
        )
    }
    render(){
        const { album } = this.getCurrentAlbumAndTab()
        let {id} = this.props.location.query
        const locked = (album && album.isPlaylist) ? false : ((album && album.isAlbum) ? albumIsLockedByID(id) : filmIsLockedByID(id))

        let photoForModal = !(album && album.isPlaylist) ? this._getPhotoForModal(album) : null

        if (photoForModal && !locked) {
            let {id} = photoForModal
            let collection = this._getPhotoCollection(id)
            return (
                <PhotoModal photo={photoForModal}
                            collection={collection}
                            location={this.props.location}
                            onUpdate={this.photoModalUpdate}
                            onDismiss={this.hidePhotoModal}
                />
            )
        } else if (album) {
            return this.renderContent()
        } else {
            return null
        }
    }
}

AlbumModal.contextTypes = {
    router: PropTypes.object.isRequired
}

const mapStateToProps = function (state) {
    return { playlists: state.playlists.byId, tracks: state.tracks.byId, albums: state.albums.byId, films: state.films.byId };
};

export default connect(mapStateToProps)(AlbumModal);
