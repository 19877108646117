import React, { Component, Fragment } from 'react'
import moment from 'moment'
import videojs from 'video.js'
import { get } from 'lodash'
import { getABTestURL } from './ab-test'
import { hasAuth, getUserInfo } from '../services/api'
import vimeoPlayButtonImage from '../../images/movie-night/vimeo-button.png'
import 'videojs-mux'
import 'video.js/dist/video-js.css'

export default class VideoJS extends Component {
	constructor(props) {
		super(props)

		this.state = {
			showPostPoster: false,
			showVideoPlayer: false
		}

		this.checkPlaybackTime = this.checkPlaybackTime.bind(this)
		this.initPlayer = this.initPlayer.bind(this)
		this.disposePlayer = this.disposePlayer.bind(this)
		this.shouldUpdate = this.shouldUpdate.bind(this)
		this.getUserID = this.getUserID.bind(this)
		this.onFailed = this.onFailed.bind(this)
		this.onContextMenu = this.onContextMenu.bind(this)
	}

	componentDidMount() {
		const { film } = this.props
		const { startDate, endDate } = film

		// pre -> live -> post
		// check and update status in case user stays in a same page
		this.interval = setInterval(() => this.checkPlaybackTime(startDate, endDate), 1000)
	}

	componentWillUnmount() {
		clearInterval(this.interval)
		this.disposePlayer()
	}

	componentWillReceiveProps(nextProps) {
		const { startDate, endDate } = nextProps.film
		// pre -> live -> post
		this.checkPlaybackTime(startDate, endDate)
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.shouldUpdate(nextState)
	}

	shouldUpdate(nextState) {
		return this.state.showPostPoster !== nextState.showPostPoster ||
			this.state.showVideoPlayer !== nextState.showVideoPlayer
	}

	getUserID() {
		if (!hasAuth()) {
			return 'anonymous'
		}

		const { user_id, email } = getUserInfo()

		return user_id || email;
	}

	checkPlaybackTime(startDate, endDate) {
		const now = moment()
		const nextState = {
			showVideoPlayer: startDate.isSameOrBefore(now) && now.isSameOrBefore(endDate),
			showPostPoster: now.isAfter(endDate),
		}

		if (!this.shouldUpdate(nextState)) {
			return
		}

		this.disposePlayer()

		this.setState(
			nextState,
			() => {
				this.state.showVideoPlayer && this.props.canViewFilm(this.props.film) && this.initPlayer()
				this.state.showPostPoster && clearInterval(this.interval)
			}
		)
	}

	initPlayer() {
		// Already initialed
		if (this.player) return

		const { film } = this.props
		// NOTE: temporarry A/B testing middleware
		// const { liveStreamingUrlPrimary: m3u8 } = film
		const m3u8 = getABTestURL(film)

		const options = {
			controls: true,
			autoplay: false,
			preload: 'auto',
			html5: {
				hls: {
					overrideNative: !!window.MediaSource
				}
			},
			controlBar: {
				audioTrackButton: false,
				durationDisplay: false,
				remainingTimeDisplay: false,
				progressControl: false
			}
		}

		this.player = videojs(this.video, options)

		// Set assets
		this.player.src({
			src: m3u8,
			type: 'application/x-mpegURL',
			overrideNative: !!window.MediaSource
		})

		this.player.poster(get(film, 'liveStreamingPrePoster.fields.file.url'))

		// Register plugin(s)
		const data = {
			env_key: process.env.MUX_ENV_KEY,
			player_name: 'Hearse Theater Live Streaming',
			player_init_time: Date.now(),
			video_id: get(film, '_id', 0),
			video_title: get(film, 'title', '(no title)'),
			video_stream_type: 'live',
			viewer_user_id: this.getUserID()
		}

		this.player.mux({
			debug: true,
			data
		})

		// Events
		this.player.on('timeupdate', e => {

			console.log(`%c${e.type}`, 'color:deeppink;font-weight:bold;', this.player.liveTracker.isLive())

			if (this.player.liveTracker.isLive()){
				return
			}

			// live is ended or something went wrong...
			this.onFailed()
		})

		this.player.on('error', e => console.log(`%c${e.type}`, 'color:deeppink;font-weight:bold;', this.player.liveTracker.isLive()))
		this.player.on('waiting', e => console.log(`%c${e.type}`, 'color:deeppink;font-weight:bold;', this.player.liveTracker.isLive()))
		this.player.on('durationchange', e => console.log(`%c${e.type}`, 'color:deeppink;font-weight:bold;', this.player.liveTracker.isLive()))
		this.player.on('ready', e => console.log(`%c${e.type}`, 'color:deeppink;font-weight:bold;', this.player.liveTracker.isLive()))
		this.player.on('stalled', e => console.log(`%c${e.type}`, 'color:deeppink;font-weight:bold;', this.player.liveTracker.isLive()))
		this.player.on('ended', e => console.log(`%c${e.type}`, 'color:deeppink;font-weight:bold;', this.player.liveTracker.isLive()))

		// TODO: add current time
		// this.player.on('play', () => {})
	}

	// TODO: Either use provider api to hande failed streams or show users relevent message
	onFailed() {
		clearInterval(this.interval)

		this.disposePlayer()

		this.setState({
			showPostPoster: true,
			showVideoPlayer: false
		})
	}

	disposePlayer() {
		if (this.disposed) {
			return
		}

		if (!this.player) {
			return
		}

		this.disposed = true
		this.player.pause()
		setTimeout(() => this.player.dispose(), 0)
	}

	onContextMenu(e) {
		return e.preventDefault()
	}

	render() {
		const { showPostPoster, showVideoPlayer } = this.state
		const { film, canViewFilm } = this.props

		if (!canViewFilm(film)) {
			return (
				<Fragment>
					<div className="vimeo-wrapper" onClick={window.subs}>
						<img className="vimeo-button" src={vimeoPlayButtonImage} alt="Vimeo Play Button" />
					</div>
					<img
						src={get(film, 'liveStreamingPrePoster.fields.file.url')}
						alt="Pre poster"
					/>
				</Fragment>
			)
		}

		if (showPostPoster) {
			return (
				<img
					src={get(film, 'liveStreamingPostPoster.fields.file.url')}
					alt="post poster"
				/>
			)
		}

		if (showVideoPlayer) {
			return (
				<div data-vjs-player onContextMenu={this.onContextMenu}>
					<video
						ref={el => this.video = el}
						className="video-js vjs-big-play-centered"
					/>
				</div>
			)
		}

		return null
	}
}
