import _                             from 'lodash';
import React, {Component}            from 'react';
import {Link}                        from 'react-router'
import PropTypes                     from 'prop-types'
import interpose                     from './util/interpose';
import ReactCSSTransitionGroup       from 'react-addons-css-transition-group';
import { ShareButtons }              from 'react-share';
import cn                            from 'classnames';
import BackNextSwitch                from './components/back-next-switch';
import UserInfo                      from './components/user-info';
import {getCurrentDateAsQueryString} from './currentDate'
import {isFreeUser}                  from './user'


const {
    FacebookShareButton,
    TwitterShareButton
} = ShareButtons;

const q=s=>()=>`${s}?${getCurrentDateAsQueryString()}`

const links = [
    {path: "/search?fromMenu=1" , className:"find"              },
    {path: "/"                  , className:"home"              },
    {path: q("/drawer")         , className:"file-cabinet"      },
    {path: q("/timeline")       , className:"timeline"          },
    {path: "/drawer/?position=100", className:"playlist"          },
    {type: "delimiter"                                          },
    {path: "/news/1"            , className:"news"              },
    {path: "/movie-night"       , className:"movie-night-image" },
    {type: "delimiter"                                          },
    {path: "/audio-setup"       , className:"audio-setup"       },
    {path: "/faq"               , className:"faq"               },
    {path: "/contact"           , className:"contact"           },
    {type: "delimiter"                                          }
]

const ignore = e => {
    if (e) {
        e.preventDefault()
        e.stopPropagation()
    }
    return false;
}

export default class GlobalMenu extends Component {
    constructor(props){
        super(props)

        let hasSigned = localStorage.getItem('nya-user-has-signed-up-for-newsletter')

        this.state = {ready: false, hasSigned,
                      showingPulldown: false}

        this.onClose           = this.onClose.bind(this)
        this.escFunction       = this.escFunction.bind(this)
        this.onBGClick         = this.onBGClick.bind(this)
        this.onNewsletterClick = this.onNewsletterClick.bind(this)
    }
    go(path){
        let {router} = this.context
        /* router.replace(router.createLocation(path)) */
        router.push(router.createLocation(path))
    }
    onNewsletterClick(){
        this.go('/newsletter')
    }
    onClose(){
        let {router} = this.context
        router.goBack()
    }
    escFunction(event){
        if(event.keyCode === 27) {
            let {router} = this.context
            router.goBack()
        }
    }
    onBGClick(e){
        if (
          !e.target.closest('.user-info')
          && !e.target.closest('.sharing')
          && !e.target.closest('.drawer-front-bottom-items')
          && !e.target.closest('.link')
        ) {
          this.props.router.goBack()
        }
    }
    getLinks(){
        return links.map((link, ind) => {
            if (link.type === "delimiter") {
                return <div key={`delimiter-${ind}`} className="delimiter" />
            }
            let path = _.isFunction(link.path) ? link.path() : link.path

            let cx = cn('link', { [link.className]:true })

            let click = ()=>{ this.go(path) }

            return (
              <div onClick={click}
                key={path}
                className={cx}
              />
            )
        })
    }
    componentDidMount(){
        setTimeout(()=>{this.setState({ready:true})}, 50)
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }
    renderContent(){
        let {hasSigned} = this.state

        let c = String.fromCharCode(169)  //copyright symbol

        return (
            <div className="global-menu" onClick={this.onBGClick} key="global-menu">
              {/* <div className="close" onClick={this.onClose}></div>
              <BackNextSwitch /> */}
              <UserInfo showSubscribe={isFreeUser()} />
              <div className="text-items">
                {this.getLinks()}
                <div className="sharing">
                  <a href="http://neilyoung.warnerbrosrecords.com" target="_blank">
                    <div className="warner-bros" />
                  </a>
                  <Link to="/contact">
                    <div className="email" />
                  </Link>
                  <a href="https://www.facebook.com/NeilYoungArchives/" target="_blank">
                    <div className="facebook"></div>
                  </a>
                  <a href="https://twitter.com/NeilYoungNYA" target="_blank">
                    <div className="twitter"></div>
                  </a>
                  {/* <div className="email"></div> */}
                </div>
              </div>
              <div className="drawer-front-bottom-items">
                <div className="items-right">
                  <span className="c">{c}</span>2017 Shakey Pictures/<span className="c">{c}</span>2017 Warner Bros. Records
                  <span className="pipe">|</span>
                  <a target="_blank" onContextMenu={ignore} href="https://nya.orastream.com/">powered by ORASTREAM</a><span className="pipe">|</span>
                  <a target="_blank" onContextMenu={ignore} href="/terms.html">TERMS AND CONDITIONS</a><span className="pipe">|</span>
                  <a target="_blank" onContextMenu={ignore} href="/privacy.html">PRIVACY POLICY</a><span className="pipe">|</span>

                  <a target="_blank" onContextMenu={ignore} href="/privacy.html#adchoices">AD CHOICES</a><span className="pipe">|</span>
                  <Link to="/credits">CREDITS</Link><span className="pipe">|</span>
                  <Link to="/faq">FAQ</Link>
                </div>
              </div>
            </div>
        )
    }
    render(){
        let transitionClass = "global-menu-fade-in";
        let {ready} = this.state;

        return (
            <div id="global-menu-wrapper">
              <ReactCSSTransitionGroup
                  component="div"
                  transitionName={transitionClass}
                  transitionEnterTimeout={1000}
                  transitionLeaveTimeout={1000}>
                { ready ? this.renderContent() : <div key="dummy"></div> }
              </ReactCSSTransitionGroup>
            </div>
        )
    }
}
GlobalMenu.contextTypes = {
    router: PropTypes.object.isRequired
}
