import React, {Component}           from 'react'
import Input                        from './components/input'
import {getAllTracks, getAllAlbums,
        getAllFilms}                from './drawer-items'
import ScrollBars                   from 'react-scrollbar'
import _                            from 'lodash'
import VerticalCenter               from './components/vertical-center'
import BackNextSwitch               from './components/back-next-switch'
import cn                           from 'classnames'
import hasClass                     from 'has-class'
import PropTypes                    from 'prop-types'

const cache = Object.create(null)

const matchTitlesOnly = true

function query({query}){
    if (cache[query]) return cache[query]

    let r = new RegExp(query, "i")

    let strmatch = (s) => {
        return s && (s.search(r) > -1)
    }

    let match = (o, field) => {
        return o && strmatch(o[field])
    }

    let scores = {}
    let trackMatchedAlbums = []
    let seenAlbums = {}

    let tracks = _.filter(getAllTracks(), o=>{
        let titleMatch = match(o, 'title')
        let didMatch   = titleMatch

        if (!matchTitlesOnly) {
            didMatch = titleMatch            ||
                       match(o, 'musicians') ||
                       match(o, 'performer') ||
                       match(o, 'producer')  ||
                       match(o, 'publisher') ||
                       match(o, 'lyrics')    ||
                       match(o, 'session')   ||
                       (o.artist && _.some(o.artist, a=>match(a, 'name')))
        }

        let score = titleMatch ? (query.toLowerCase() === o.title.toLowerCase() ? 0 : 1) : 2
        if (didMatch) scores[o.id] = score

        //matched tracks should have their album appear in search results.
        if (didMatch && o.album) {
            let albumScore = 1.1 + (score * 0.1), albumId = o.album.id
            if (seenAlbums[albumId]) {
                scores[albumId] = Math.min(scores[albumId], albumScore)
            } else {
                trackMatchedAlbums.push(o.album)
                scores[albumId] = albumScore
            }
            seenAlbums[albumId] = true
        }

        //list only tracks should have their album appear in search results, but not appear themselves.
        if (o.listOnly) return false

        return didMatch
    })

    let albums = _.filter(getAllAlbums(), o=>{
        let titleMatch = match(o, 'title')
        let didMatch   = titleMatch

        if (!matchTitlesOnly) {
            didMatch = titleMatch                                                  ||
                       match(o, 'musicians')                                       ||
                       match(o, 'recordedAt')                                      ||
                       (o.producedBy && _.some(o.producedBy, strmatch))            ||
                       (o.credits    && _.some(o.credits, c=>match(c, 'credits'))) ||
                       (o.artists    && _.some(o.artists, a=>match(a, 'name')))
        }

        let score = titleMatch ? (query.toLowerCase() === o.title.toLowerCase() ? 0 : 1) : 2

        if (seenAlbums[o.id]) {
            if (didMatch) {
                scores[o.id] = Math.min(scores[o.id], score)
            }
            return false
        }

        if (didMatch) scores[o.id] = score

        return didMatch
    })

    let films  = _.filter(getAllFilms(),  o=>{
        let titleMatch = match(o, 'title')
        let didMatch   = titleMatch

        if (!matchTitlesOnly) {
            didMatch = titleMatch                                               ||
                       match(o, 'director')                                     ||
                       match(o, 'producer')                                     ||
                       match(o, 'cinematographer')                              ||
                       (o.credits && _.some(o.credits, c=>match(c, 'credits'))) ||
                       (o.info    && _.some(o.info,    i=>match(i, 'content')))
        }

        let score = titleMatch ? (query.toLowerCase() === o.title.toLowerCase() ? 0 : 1) : 2
        if (didMatch) scores[o.id] = score

        return didMatch
    })

    albums = albums.concat(trackMatchedAlbums)

    tracks = tracks.sort((a, b)=>scores[a.id] - scores[b.id])
    albums = albums.sort((a, b)=>scores[a.id] - scores[b.id])
    films  = films.sort( (a, b)=>scores[a.id] - scores[b.id])

    let result = {tracks, album:albums, film:films}
    cache[query] = result
    return result
}

function queryCached(q){
    return new Promise(resolve=>resolve(query(q)))
}


function desc(item, idx, onClick){

    let artist = item.type === 'track' ?
                 item.performer
               : (item.artists || []).map(a=>a.name).join(' ')

    let {year,month,day} = item.releaseDate

    return (
        <div className="result"
             key={idx}
             data-type={item.type}
             data-id={item.id}
             onClick={onClick}>
          <img className="image" src={item.searchImage} />
          <div className="description">
            <div className="title">{item.title}</div>
            <div className="artist">{artist}</div>
            <div className="date">{month}/{day}/{year}</div>
          </div>
          <br style={{clear: 'both'}} />
        </div>
    )
}

class SearchResults extends Component {
    render(){
        let {results, onClick} = this.props
        let {tracks, albums, films, query} = results

        if (!query) return null


        return (
            <div className="search-results">
              <div className="results-for">Search results for "{query}"</div>
              <div className="tracks">
                { tracks.length ?
                  <div className="header">
                    <div className="desc">songs</div>
                    <div className="count">{tracks.length} Result{tracks.length > 1 ? "s" : ""}</div>
                    <br style={{clear: 'both'}} />
                  </div>
                  : null }
                  <ScrollBars>
                    <div className="filler" />
                    {
                        tracks.map((item, idx)=>(desc(item, idx, onClick)))
                    }
                  </ScrollBars>
              </div>
              <div className="albums">
                { albums.length ?
                  <div className="header">
                    <div className="desc">albums</div>
                    <div className="count">{albums.length} Result{albums.length > 1 ? "s" : ""}</div>
                    <br style={{clear: 'both'}} />
                  </div>
                  : null }
                  <ScrollBars>
                    <div className="filler" />
                    {
                        albums.map((item, idx)=>(desc(item, idx, onClick)))
                    }
                  </ScrollBars>
              </div>
              <div className="films">
                { films.length ?
                  <div className="header">
                    <div className="desc">films</div>
                    <div className="count">{films.length} Result{films.length > 1 ? "s" : ""}</div>
                    <br style={{clear: 'both'}} />
                  </div>
                  : null }
                  <ScrollBars>
                    <div className="filler" />
                    {
                        films.map((item, idx)=>(desc(item, idx, onClick)))
                    }
                  </ScrollBars>
              </div>
            </div>
        )
    }
}

let searchId = 0
let lastSearch = ''

export default class SearchContainer extends Component {
    constructor(props, ctx) {
        super(props, ctx)

        let {q} = props.location.query

        q = q || lastSearch

        this.state = {results: {tracks:[], albums:[],
                                films: [], query:''}, q }

        this.onChange  = _.debounce(this.onChange.bind(this), 1000)
        this.onClick   = this.onClick.bind(this)
        this.onBGClick = this.onBGClick.bind(this)
        this.onClose   = this.onClose.bind(this)
    }
    componentWillMount(){
        let {q} = this.state
        if (q) this.search(q)
    }
    /* componentDidMount(){
     *     this.search('jo')
     * }*/
    onChange(string){
        console.log('will search for string: ', string)
        let search = string.trim()
        this.setState({q:search})
        this.search(search)
    }
    onClick(e){
        let el   = e.target
        let id   = el.getAttribute('data-id')
        let type = el.getAttribute('data-type')

        let table = {
            track: '/info-card?track=X',
            album: '/album?id=X',
            film:  '/film?id=X'
        }

        let template = table[type]

        if (!template) {
            console.error("don't know how to handle item of type:", type)
            return
        }

        let url = template.replace('X', id)

        let {router} = this.context

        router.push(router.createLocation(url))
    }
    onBGClick(e){
        let el = e.target

        if (!(hasClass(el, 'search-page') ||
              hasClass(el, 'search-page-inner'))) return

        e.preventDefault()
        e.stopPropagation()

        this.onClose()
    }
    onClose(){
        this.context.router.goBack()
    }
    updateUrl(search){
        let {router}   = this.context
        let {pathname} = this.props.location

        let query = {q: search}

        let location = router.createLocation({pathname, query})
        delete location.search

        router.replace(location)
    }
    search(search){
        this.updateUrl(search)

        if (search === '') {
            this.setState({results: {tracks:[], albums:[], films:[], query:''}})
            return
        }

        lastSearch = search

        searchId +=1
        let thisSearch = searchId

        queryCached({include:1, query:search}).then(results=>{

            if (searchId !== thisSearch) return

            let tracks = results.tracks
            let albums = results.album
            let films  = results.film

            this.setState({results: {tracks, albums, films,
                                     query:search}})

        })
    }
    render(){

        let fromMenu = this.props.location.query.fromMenu
        let cx = cn('search-page', {'from-menu': fromMenu})

        return (
            <div className="search-page-wrapper"> 
              <VerticalCenter className={cx}
                              innerClassName="search-page-inner"
                              onClick={this.onBGClick}>
                {/*fromMenu ?
                 <div className="controls">
                   <div className="close-button" onClick={this.onClose} />
                   <BackNextSwitch />
                 </div>
                 : null */}
                 <div className="content">
                   <div className="background" />
                   <Input className="searchbox"
                          value={this.state.q}
                          autofocus={true}
                          focusOnClear={true}
                          topShadow={true}
                          placeholder="Search"
                          onChange={this.onChange} />
                   <div className="results-area">
                     <SearchResults results={this.state.results}
                                    onClick={this.onClick} />
                   </div>
                   <div className="bottom-line" />
                 </div>
              </VerticalCenter>
            </div>
        )
    }
}

SearchContainer.contextTypes = {
    router: PropTypes.object.isRequired
}
