import { ADD_PLAYLIST, UPDATE_PLAYLIST, DELETE_PLAYLIST, CHANGE_PLAYLIST_ITEMS_ORDER } from '../constants';

export const addPlaylistAction = (playlist) => {
    return {
        type: ADD_PLAYLIST,
        playlist: playlist
    }
}

export const updatePlaylistAction = (playlist) => {
    return {
        type: UPDATE_PLAYLIST,
        playlist: playlist
    }
}

export const deletePlaylistAction = (playlistId) => {
    return {
        type: DELETE_PLAYLIST,
        playlistId: playlistId
    }
}

export const changePlaylistItemsOrder = (result) => {
    return {
        type: CHANGE_PLAYLIST_ITEMS_ORDER,
        result: result
    }
}
