import {allYears}  from './gen-years'
import {genPhotos} from './gen-photos'
import genVideos   from './gen-videos'
import lorem       from 'lorem-ipsum';

import {pop_random, random_int, chance, pick, pick_not} from '../util/random.js'

export const albums = {
    all: [],
    byYear: {},
    index: {}
}

/* fake date addition for generating fake dates */
/* assumes days less than a month, pretends every month has 31 days */
function addDate(date, days){

    let newDay   = date.day + days
    let newMonth = newDay < 1 ? date.month - 1 : date.month
    let newYear  = newMonth < 1 ? date.year - 1 : date.year

    let day   = newDay < 1 ? 31 + newDay : newDay
    let month = newMonth < 1 ? 12 + newMonth : newMonth
    let year  = newYear

    if (day > 31) {
        day = day - 31
        month = month + 1
    }
    if (month > 12) {
        month = month - 12
        year = year + 1
    }

    return {day,month,year}
}


let colors    = ['#694629', '#6b4c2f', '#8c6b21', '#604f31', '#5f6031']
let color     = pick(colors)
let _trackId  = 0

function genTracks(number, albumReleaseDate){
    let result = []
    let count  = number
    while(count--){
        color    = pick_not(colors,color)

        let title = lorem({count: 2, units:'words'})

        let id    = `${_trackId++}`

        let released = !chance(5) // 1 in 5 chance it's unreleased

        let position = released ? 'left' : 'right'

        let live  = chance(3)

        let duration = random_int(120,240)
        let trackNumber = count + 1

        let dateOffset  = random_int(0, 30)
        let releaseDate = addDate(albumReleaseDate, -dateOffset)

        let photos      = genPhotos(random_int(1, 20))
        let documents   = genPhotos(random_int(1, 20), true)
        let press       = genPhotos(random_int(1, 20))
        let memorabilia = genPhotos(random_int(1, 20))

        let session   = 'session'
        let performer = 'performer'
        let producer  = 'producer'
        let musicians = 'musicians'
        let label     = 'label'

        let videos = genVideos(random_int(1, 5))

        let lyrics = lorem({count: 5, units:'paragraphs'})

        result.unshift({id, color, position, live, released,
                        session, performer, producer, musicians, label,
                        trackNumber, lyrics, videos,
                        photos, documents, press, memorabilia,
                        title, releaseDate, duration})
    }
    return result
}

let greatestHitsYear = 2004

let _albumId = 0
allYears.forEach(yr => {
    let albumCount = pick([1,2/* ,3*/])
    let year = yr.year
    albums.byYear[year] = []

    if (year === greatestHitsYear) albumCount = 1 /* leave room for greatest hits */

    let months = [1,2,3,4,5,6,7,8,9,10,11,12]

    while(albumCount--){

        let id = `${_albumId++}`

        let title = lorem({count: 2, units:'words'})
        let month = pop_random(months) /* release months shouldn't overlap for dev */
        let day   = random_int(1,31)

        let releaseDate     = { year, month, day }
        let trackCount      = random_int(10,20)
        let tracks          = genTracks(trackCount, releaseDate)
        let longDescription = lorem({count:3, units:'paragraphs'})
        let isAlbum         = true
        let color           = pick(colors)
        let trackStars      = tracks.map(()=>'')
        let artists         = [{name: 'Neil Young'}]
        let credits         = []

        let album = {title, releaseDate, tracks, id, longDescription, isAlbum,
                     color, trackStars, artists, credits}

        tracks.forEach(track=>{
            //primary album
            track.album = album
            track.presentOn = {[id]: true}
        })

        albums.all.push(album)
        albums.byYear[year].push(album)
        albums.index[id] = album
    }
})

{
    let id = 'greatest-hits'

    let title = 'Greatest Hits'
    let year = greatestHitsYear
    let month = random_int(1,12)
    let day   = random_int(1,31)

    let getTracks = function(number){
        let result = [], count = number
        while (count --) {
            let yr = random_int(1965, greatestHitsYear)
            let album = pick(albums.byYear[yr])
            let track = pick(album.tracks)
            result.push(track)
        }
        return result
    }

    let releaseDate     = { year, month, day }
    let tracks          = getTracks(20)
    let longDescription = lorem({count:3, units:'paragraphs'})
    let trackStars      = tracks.map(()=>"")
    let artists         = [{name: 'Neil Young'}]
    let credits         = []

    let album = {title, releaseDate, tracks, id, longDescription,
                 trackStars, artists, credits}

    tracks.forEach(track=>{
        track.presentOn[id] = true
    })

    album.isAlbum = true

    albums.all.push(album)
    albums.byYear[year].push(album)
    albums.index[id] = album

}
