import preloadImages from './util/preload-images'

import info1 from"../images/info-card/background.jpg"
import info2 from "../images/info-card/bottom-panel-center.png"
import info3 from "../images/info-card/bottom-panel.png"
import info4 from "../images/info-card/buy-sticker-hl.png"
import info5 from "../images/info-card/buy-sticker.png"
import info6 from "../images/info-card/cabinet-hl.png"
import info7 from "../images/info-card/cabinet.png"
import info8 from "../images/info-card/close-hl.png"
import info9 from "../images/info-card/close.png"
import info10 from "../images/info-card/folders/a.png"
import info11 from "../images/info-card/lyrics-sticker-hl.png"
import info12 from "../images/info-card/lyrics-sticker.png"
import info13 from "../images/info-card/open-folder-right.png"
import info14 from "../images/info-card/paperclip.png"
import info15 from "../images/info-card/postit-link.png"
import info16 from "../images/info-card/postit-return.png"
import info17 from "../images/info-card/slider-background.png"
import info18 from "../images/info-card/slider-thumb.png"
import info19 from "../images/info-card/stamps/original_bs_version.png"
import info20 from "../images/info-card/stamps/stamp_in_production.png"
import info21 from "../images/info-card/stamps/stamp_unreleased.png"
import info22 from "../images/info-card/stamps/stamp_unreleased_demo.png"
import info23 from "../images/info-card/stamps/stamp_unreleased_instrumental.png"
import info24 from "../images/info-card/stamps/stamp_unreleased_live_ver.png"
import info25 from "../images/info-card/stamps/stamp_unreleased_livemont.png"
import info26 from "../images/info-card/stamps/stamp_unreleased_mix.png"
import info27 from "../images/info-card/stamps/stamp_unreleased_orig_vers.png"
import info28 from "../images/info-card/stamps/stamp_unreleased_song.png"
import info29 from "../images/info-card/stamps/stamp_unreleased_version.png"
import info30 from "../images/info-card/stamps/stamp_version1.png"
import info31 from "../images/info-card/stamps/stamp_version2.png"
import info32 from "../images/info-card/stamps/unfinished_recording.png"
import info33 from "../images/info-card/stamps/unreleased_2009_mix.png"
import info34 from "../images/info-card/stamps/unreleased_original.png"
import info35 from "../images/info-card/tabs/docs-hl.png"
import info36 from "../images/info-card/tabs/docs-left-sel.png"
import info37 from "../images/info-card/tabs/docs-right-sel.png"
import info38 from "../images/info-card/tabs/docs.png"
import info39 from "../images/info-card/tabs/memo-hl.png"
import info40 from "../images/info-card/tabs/memo-left-sel.png"
import info41 from "../images/info-card/tabs/memo-right-sel.png"
import info42 from "../images/info-card/tabs/memo.png"
import info43 from "../images/info-card/tabs/photos-hl.png"
import info44 from "../images/info-card/tabs/photos-left-sel.png"
import info45 from "../images/info-card/tabs/photos-right-sel.png"
import info46 from "../images/info-card/tabs/photos.png"
import info47 from "../images/info-card/tabs/press-hl.png"
import info48 from "../images/info-card/tabs/press-left-sel.png"
import info49 from "../images/info-card/tabs/press-right-sel.png"
import info50 from "../images/info-card/tabs/press.png"
import info51 from "../images/info-card/tabs/title-left.png"
import info52 from "../images/info-card/timeline-arrow-hl.png"
import info53 from "../images/info-card/timeline-arrow.png"
import info54 from "../images/info-card/timeline-hl.png"
import info55 from "../images/info-card/timeline.png"
import info56 from "../images/info-card/up-arrow-hl.png"
import info57 from "../images/info-card/up-arrow.png"
import info58 from "../images/info-card/video-sticker-hl.png"
import info59 from "../images/info-card/video-sticker.png"

const images = [
    info1, info2, info3, info4, info5, info6, info7, info8, info9, info10, info11, info12, info13, info14, info15, info16, info17, info18, info19, info20, info21, info22, info23, info24, info25, info26, info27, info28, info29, info30, info31, info32, info33, info34, info35, info36, info37, info38, info39, info40, info41, info42, info43, info44, info45, info46, info47, info48, info49, info50, info51, info52, info53, info54, info55, info56, info57, info58, info59
]

let promise= null
export default function preloadInfoCard(){
    if (!promise) {
        promise = preloadImages(images)
    }
    return promise
}