import React, {Component} from 'react';
import _ from 'lodash';

function end(event){
    if (event.stopPropagation()) event.stopPropagation()
    if (event.preventDefault()) event.preventDefault()

    return false;
}

export default class HorizontalSlider extends Component {
    constructor(props,b){
        super(props,b);
        this.mouseDown  = this.mouseDown.bind(this)
        this.mouseMove  = this.mouseMove.bind(this)
        this.mouseUp    = this.mouseUp.bind(this)
        this.touchStart = this.touchStart.bind(this)
        this.touchMove  = this.touchMove.bind(this)
        this.touchEnd   = this.touchEnd.bind(this)

        let min = _.has(props, 'min') ? props.min : 0
        let max = _.has(props, 'max') ? props.max : 100
        this.state = {min, max}

        this.pct = this.unscale(props.pct)
    }
    _mouseMap(){
        return {mousemove: this.mouseMove, mouseup:this.mouseUp}
    }
    _touchMap(){
        return {touchmove:this.touchMove, touchend:this.touchEnd}
    }
    _addHandlers(map){
        _.each(map, (value, key)=>{
            document.addEventListener(key, value, false)
        })
    }
    _removeHandlers(map){
        _.each(map, (value, key)=>{
            document.removeEventListener(key, value, false)
        })
    }
    scale(pct){
        let {min,max} = this.state
        return ((pct / 100) * (max - min)) + min
    }
    unscale(value){
        let {min,max} = this.state
        let v = value - min
        return (v / (max - min)) * 100
    }
    calcPct(rawX){
        let line = this.refs.line
        let rect = line.getBoundingClientRect()
        let x    = rawX - this._initialOffset

        let min = rect.left
        let max = min + rect.width

        if (x < min) return 0
        if (x > max) return 100

        return ((x - min) / rect.width) * 100
    }
    calcClient(pct){
        let rect = this.refs.line.getBoundingClientRect()
        return rect.left + (rect.width * (pct / 100))
    }
    mouseDown(e){
        if (this.props.disabled) return

        this._addHandlers(this._mouseMap());
        this._initialOffset = e.clientX - this.calcClient(this.pct)
        this._initialPct    = this.calcPct(e.clientX)
        /* console.log("mousedown: " + e.clientX)*/

        if (this.props.dragStart) this.props.dragStart()

        return end(e)
    }
    _scaleAffordance(mag, diff){
        if (this.props.affordance) {
            let cut = 18
            if (mag >= cut) return diff
            let scale = mag / cut
            return diff * scale
        } else {
            return diff
        }
    }
    mouseMove(e){
        let x = e.clientX
        let pct = this.calcPct(x)

        let diff = (this._initialPct - pct)
        let mag  = Math.abs(diff)
        pct = this._initialPct - this._scaleAffordance(mag, diff)

        let val = this.scale(pct)
        this.props.onScroll(val)
        return end(e)
    }
    mouseUp(e){
        /* console.log("mouseup: " + e.clientX)*/
        this._removeHandlers(this._mouseMap())

        if (this.props.dragEnd) this.props.dragEnd()

        return end(e)
    }
    touchStart(e){
        if (this.props.disabled) return
        if (e.touches.length > 1) return

        let clientX = e.touches[0].clientX
        this._initialOffset = clientX - this.calcClient(this.pct)
        this._initialPct    = this.calcPct(clientX)
        this._addHandlers(this._touchMap())
        /* console.log("touchstart: " + e.touches[0].clientX)*/

        if (this.props.dragStart) this.props.dragStart()

        return end(e)
    }
    touchMove(e){
        if (e.touches.length > 1) return
        let x = e.touches[0].clientX
        let pct = this.calcPct(x)

        let diff = (this._initialPct - pct)
        let mag  = Math.abs(diff)
        pct = this._initialPct - this._scaleAffordance(mag, diff)

        let val = this.scale(pct)
        this.props.onScroll(val)
        return end(e)
    }
    touchEnd(e){
        /* console.log("touchend: " + e.touches[0].clientX)*/
        this._removeHandlers(this._touchMap())

        if (this.props.dragEnd) this.props.dragEnd()

        return end(e)
    }
    shouldComponentUpdate(newprops){
        let pct  = this.unscale(newprops.pct)
        this.pct = pct
        return true;
    }
    getThumbStyle(){
        let pct = this.unscale(this.props.pct)
        return { left: `${pct}%` }
    }
    render(){

        let left  = this.props.left || 0
        let width = this.props.width || '100%'

        let style = {left, width}

        return (<div className="horizontal-slider-container" style={style}>
                  <div ref="line" className="horizontal-slider-line"></div>
                  <div ref="thumb" style={this.getThumbStyle()}
                       className="horizontal-slider-thumb">
                    <div className="horizontal-slider-thumb-hit-area"
                       onMouseDown={this.mouseDown} onTouchStart={this.touchStart} >
                    </div>
                  </div>
                </div>);
    }
}
