import _ from 'lodash'
import Audio from './audio'
import { getListedTracks, getFreeAlbumID } from '../../drawer-items'
import {isFreeUser} from '../../user'

let currentPlaylist = null
let currentIndex = null
let listeners = []

class Manager {
    constructor(){
        this.onNext = this.onNext.bind(this)
        Audio.onTrackSelect(this.onNext)
    }
    onNext(idx) {
        currentIndex = idx
        const state = (idx === -1) ? 'STOPPED' : 'WILLPLAY'

        if (state === 'STOPPED') {
            for (let cb of listeners) cb({state})
            return
        }

        let atStart = idx === 0
        let atEnd   = idx === currentPlaylist.length - 1
        let track = currentPlaylist[idx]
        for (let cb of listeners) cb({state, track, atStart, atEnd})
    }
    addListener(listener) {
        listeners.push(listener)
    }
    removeListener(listener){
        listeners = listeners.filter(it=>it!==listener)
    }
    prevNextDisabled(){
        let idx = currentIndex
        if (!currentPlaylist || idx === null || idx === -1) {
            return {prevDisabled:true, nextDisabled:true}
        }
        let atStart = idx === 0
        let atEnd   = idx === currentPlaylist.length - 1
        return {prevDisabled:atStart, nextDisabled:atEnd}
    }
    currentTrack(){
        if (currentIndex === null || currentPlaylist === null) return null
        return currentPlaylist[currentIndex]
    }
    playTrack(track) {
        this._playItemInItems(track, [track])
    }
    _filterItems(tracks){
        let items = _.filter(tracks, 'orastream')
        if (isFreeUser()) items = _.filter(items, 'free')
        return items
    }
    playFromTrackChronologically(track) {
        this._playItemInItems(track, this._filterItems(getListedTracks()))
    }
    playTrackInMiddle(track, showAreYouStillListeningModal) {
        const tracks = this._filterItems(getListedTracks())
        this._playItemInMiddleItems(track, tracks, showAreYouStillListeningModal)
    }
    isShowAreYouStillListeningModal() {
        return Audio.isShowAreYouStillListeningModal()
    }
    isPrevOrNext() {
        return Audio.isPrevOrNext()
    }
    play() {
        Audio.play()
    }
    pause() {
        Audio.pause()
    }
    stopPlaying(){
        Audio.stopPlaying()
    }
    playFromTrackOnAlbum(track, album) {
        let idx = album.tracks.indexOf(track)
        if (idx < 9 && album.id && album.id.toLowerCase() === 'a_012') {
            //skip the last two tracks for Harvest per N request
            let items = this._filterItems(album.tracks)
            items.pop()
            items.pop()
            this._playItemInMiddleItems(track, items)
        } else {
            this._playItemInMiddleItems(track, this._filterItems(album.tracks))
        }
    }
    playAlbum(album){
        let items = this._filterItems(album.tracks)
        if (!items.length) return
        if (album.id && album.id.toLowerCase() === 'a_012') {
            //skip the last two tracks for Harvest per N request
            items.pop()
            items.pop()
        }
        this._playItemInItems(items[0], items, album)
    }
    resumePlayback(isInitial){
        if (!this._playlists) return
        let next = this._playlists.shift()
        if (next) {
            currentPlaylist = next
            currentIndex    = 0
            Audio.playItems(next, 0, isInitial)
            this.onNext(0)
        }
    }
    _playItemInItems(track, items, album, showAreYouStillListeningModal) {
        Audio.setShowAreYouStillListeningModal(showAreYouStillListeningModal)
        let { id } = track
        let idx = _.findIndex(items, (item) => { return item.id === id })
        if (idx !== -1) {
            // Remove current track being played from items to play (track parameter)
            const itemsToPlay = items.slice(idx)

            // This section segments current items in individual playlists just for free users
            // Everytime the album played is not the free album (isFreeAlbum)
            // Later inside OnNext() the prompt for keep playing is showed after a playlist is finished
            const isFreeAlbum = album && album.id === getFreeAlbumID()
            let allowedPlaylistLength = isFreeUser() && !isFreeAlbum ? 1 : items.length

            this._playlists = _.chunk(itemsToPlay, allowedPlaylistLength)

            this.resumePlayback(true)
        }
    }
    _playItemInMiddleItems(track, items, showAreYouStillListeningModal) {
        if (!items) return
        Audio.setShowAreYouStillListeningModal(showAreYouStillListeningModal)

        let { id } = track
        let idx = _.findIndex(items, (item) => {return item.id === id})
        if (idx !== -1) {
            const freeTracks = items.filter(item => item.free)

            const itemsToPlay = isFreeUser() ? freeTracks : items
            if (_.isElement(itemsToPlay)) return

            const playableIdx = _.findIndex(itemsToPlay, item => item.id === id)
            if (playableIdx === -1) return

            this._playlists = [itemsToPlay]
            currentPlaylist = itemsToPlay
            currentIndex    = playableIdx
            Audio.playItems(itemsToPlay, playableIdx, false)
            this.onNext(playableIdx)
        }
    }
}
export default new Manager()
