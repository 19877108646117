export const getGeolocation = () => {
    if (navigator && navigator.geolocation) {
        return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(position => {
            let {coords} = position
            let {latitude, longitude} = coords
            console.log(`Got coordinates ${latitude}, ${latitude}`)
            return resolve({latitude, longitude})
        })
    })
  
    } else {
        return Promise.reject(new Error('No geolocation enbled'))
    }
}