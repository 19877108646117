import React, {Component}            from 'react'
import {Link}                        from 'react-router'
import cn                            from 'classnames'
import marked                        from 'marked'
import Vimeo                         from 'react-tj-vimeo'

const imgSizes = {
    left: '475',
    center: '475',
    right: '475'
}

function externalizeHrefs(el) {
    let links = el.querySelectorAll('a')
    for (let i = 0; i < links.length; i++) {
        let a = links[i]
        if (location.hostname === a.hostname || !a.hostname.length) continue;
        a.setAttribute('target', '_blank')
    }
}

function getExcerptImages(data) {
    const {id, column, vimeoId, order} = data
    const link = `/news/${order}/article?id=${encodeURIComponent(id)}`
    if (vimeoId) {
        return <div className="video-container">
                <Vimeo videoId={parseInt(vimeoId, 10)} supportFS={true} />
            </div>
    } else if (data.type === 'two-image') {
        const url0 = `url(${data.images[0]})`
        const url1 = `url(${data.images[1]})`
        return (
            <div className="images two-image">
              <Link to={link}><div className="image left"  style={{backgroundImage:url0}} /></Link>
              <Link to={link}><div className="image right" style={{backgroundImage:url1}} /></Link>
              <div className="clearfix" />
            </div>
        )
    } else if (data.type === 'one-image') {
        const imageWidth = imgSizes[column];
        const url = `${data.images[0]}`
        return (
            <div className="images one-image">
              <Link to={link}><img src={url} /></Link>
              <div className="clearfix" />
              <div className="credits">{data.credits}</div>
            </div>
        )
    } else {
        return null
    }
}

class Excerpt extends Component {
    componentDidMount(){
        externalizeHrefs(this.refs.content)
    }
    render(){
        const { headline, type, id, dateText, column, headlineText, bodyText, subtitle, callout, showBodyText, titlesFullWidth, titleDivider, topTitleDivider, subtitleDivider, calloutDivider, byline } = this.props.data
        const { idx, page, layout } = this.props;
        const linkToArticle = `/news/${page}/article?id=${encodeURIComponent(id)}`

        // Default page rules
        let featuredPost = (column === 'center') && idx === 0;
        const firstPost = idx === 0;
        const centerColumn = column === 'center';
        let showContent = firstPost || showBodyText;
        let showTopDivider = false;
        let imageAtTop = false;
        let includeDate = true;
        let includeByline = false;

        // Page 2 rules
        if ( page === '2') {
            showTopDivider = column === 'left' && !firstPost
            showContent = showContent || column === 'left'
            featuredPost = column === 'center' && !titlesFullWidth  // This is because page2/center is featured layout when titles full width is not selected.
        }

        // Page 4 rules
        if ( page === '4') {
            showContent = showContent || column === 'left'
        }

        // Page 6 rules
        if (page === '6') {
            imageAtTop = featuredPost;
            showTopDivider = centerColumn;
            showContent = showBodyText || column === 'left' || column === 'right';
            includeByline = true;
        }

        // Page 7 rules
        if (page === '7') {
            includeDate = false;
            featuredPost = false;
        }

        /* Two column layout if
            It is the center column
            It is the first post or the option showBodyText is true, this prevents posts that don't show body text to being two column
            If titles full width is intended the two columns layout are applied just to excerpt content
            If layout is 3-column
        */
       let twoColumnTitle = centerColumn && showContent && !titlesFullWidth && (layout === 'column-3')
       let twoColumnContent = titlesFullWidth && (layout === 'column-3')
       let threeColumnTitle = centerColumn && showContent && !titlesFullWidth && (layout === 'column-3-full')
       let threeColumnContent = titlesFullWidth && (layout === 'column-3-full')
        let ct = cn('title-column', {'double-column': twoColumnTitle}, {'three-column': threeColumnTitle})
        let cx = cn('excerpt-content', type, {'double-column': twoColumnContent}, {'three-column': threeColumnContent})

        return (
            <div className="excerpt">
                { (featuredPost && imageAtTop) && getExcerptImages(this.props.data)}
                { headlineText &&
                    <Link to={linkToArticle}>
                        { showTopDivider && <div style={{width: `${topTitleDivider || 150}px`}} className="excerpt-divider" /> }
                        <div className={'title headline ' + column + ` news-${page}`} ref="content" dangerouslySetInnerHTML={{__html:marked(headlineText || '')}} />
                        { titleDivider !== 0 && <div style={{width: `${titleDivider || 150}px`}} className="excerpt-divider" /> }
                    </Link>
                }
                { (headline && !headlineText) &&
                    <Link to={linkToArticle}>
                        { showTopDivider && <div className="excerpt-divider" /> }
                        <div className={'title headline ' + column + ` news-${page}`}><h1>{headline}</h1></div>
                        { titleDivider !== 0 && <div style={{width: `${titleDivider || 150}px`}} className="excerpt-divider" /> }
                    </Link>
                }
                { (featuredPost && !imageAtTop) && getExcerptImages(this.props.data)}
                <div className={ct} >
                    {/* centerColumn && <div style={{width: `${titleDivider || 150}px`}} className="excerpt-divider notop" /> */}
                    { subtitle &&
                        <Link to={linkToArticle}>
                            <div className={'title subtitle ' + column + ` news-${page}`} ref="content" dangerouslySetInnerHTML={{__html:marked(subtitle || '')}} />
                            { subtitleDivider !== 0 && <div style={{width: `${subtitleDivider || 150}px`}} className="excerpt-divider" /> }
                        </Link>
                    }
                    { callout &&
                        <Link to={linkToArticle}>
                            <div className={'title callout ' + column + ` news-${page}`} ref="content" dangerouslySetInnerHTML={{__html:marked(callout || '')}} />
                            { calloutDivider !== 0 && <div style={{width: `${calloutDivider || 150}px`}} className="excerpt-divider" /> }
                        </Link>
                    }
                    { (includeByline && byline) &&
                        <Link to={linkToArticle}>
                            <div className="byline">{byline}</div>
                        </Link>
                    }
                    { (!featuredPost && showContent) && getExcerptImages(this.props.data)}
                    { includeDate && <div className="dateline">{dateText}</div> }
                    <div className={cx} ref="content" dangerouslySetInnerHTML={{__html:marked(showContent && bodyText ? bodyText : '')}} />
                </div>
            </div>
        )
    }
}

export default Excerpt