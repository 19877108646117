/* this file sets up relationships between the models
   and provides the query functions used by the rest of the app. */

import {stream_from}                      from './util/random.js'
import {albums as allAlbums}              from './stubs/gen-albums'
import {films as allFilms}                from './stubs/gen-films'
import archive                            from './archive-tracks'
import store                              from './store'
import _                                  from 'lodash'
import compareReleaseDate, {compareItems, comparePlaylists} from './util/compare-release-date.js'
import lorem                              from 'lorem-ipsum';
import { getUserInfo } from './services/api.js';

import folderLabel01 from '../images/stubs/stub_folder_label_01.png'
import folderLabel02 from '../images/stubs/stub_folder_label_01.png'
import folderLabel03 from '../images/stubs/stub_folder_label_01.png'


export function usingStubs(){ return false; }
export let useStubData = usingStubs();

let useStubAudio = false

let tracks = null
let albums = null
let items  = null
let playlists = null

let freeAlbumID = null

if (useStubData) {
    tracks = archive.items
    albums = _.filter(allAlbums.all, ({unreleased})=>!unreleased)
    items  = tracks.concat(albums).concat(allFilms.all);
}

/* to be called after initial app load */
export function indexInitialData(state) {

    if (useStubData) return;

    let tracksIndex        = state.tracks,
        albumsIndex        = state.albums,
        relatedIndex       = state.related,
        artistsIndex       = state.artists,
        videosIndex        = state.videos,
        filmsIndex         = state.films,
        moreIndex          = state.more,
        albumArtIndex      = state.albumArt,
        boxsetIndex        = state.boxset,
        tourZOIndex        = state.tourZO,
        tourZIIndex        = state.tourZI,
        timelineEventIndex = state.timelineEvents,
        lyrics             = state.lyrics,
        playlistsIndex     = state.playlists

    let findMedia = fixupFunction(moreIndex, relatedIndex)

    fixupTracks(tracksIndex)
    fixupAlbums(albumsIndex)
    fixupAlbumsAndArtists(albumsIndex, artistsIndex)
    fixupTracksAndAlbums(tracksIndex, albumsIndex, playlistsIndex, relatedIndex)
    fixupTracksFilmsAlbumsAndVideos(tracksIndex, filmsIndex, albumsIndex, videosIndex)
    fixupVideos(videosIndex)
    fixupMoreAlbumsAndFilms(moreIndex, albumsIndex, filmsIndex)
    fixupMore(moreIndex)
    fixupAlbumsAndAlbumArt(albumsIndex, albumArtIndex)
    fixupBoxSetsAlbumsAndFilms(boxsetIndex, albumsIndex, filmsIndex)
    fixupTour(tourZOIndex, findMedia)
    fixupTour(tourZIIndex, findMedia)
    fixupTimelineEvents(timelineEventIndex, findMedia, videosIndex)
    fixupCustomPlaylists(playlistsIndex)

    if (lyrics) {
        fixupTracksAndLyrics(tracksIndex, lyrics)
    }

    let tracks = tracksIndex.all
    let albums = albumsIndex.all
    let films  = filmsIndex.all
    playlists = playlistsIndex.all

    // Remove items under certain conditions from the cabinet
    albums = albums.filter(a=>!a.unreleased)
    tracks = tracks.filter(track=>!track.listOnly)
    tracks = tracks.filter(track=>!track.hiddenTrack)

    // Concat all types
    items = _.compact(tracks.concat(albums).concat(films))

    // Sort by date
    sortItems(items)

    // Add to the end
    items = items.concat(_.compact(playlists.sort(comparePlaylists)))

    processItems(items)     // Maps css rules depending on item's type of content
    addPlaylistSection()    // Add playlist section header and bottom spacers
    processAlbums(albums)   // fixupAlbums2 ?

    console.log('\n\n------------------------------------------------------------')
    console.log("tracks:", tracksIndex.all.length)
    console.log("albums:", albumsIndex.all.length)
    console.log("films:", filmsIndex.all.length)
    console.log('------------------------------------------------------------')

}

// PLAYLISTS ============================================
export function addPlaylistToCabinet(playlist) {
    // If this playlist already has tracks populated (not contentful ids) we just need to add it at the end
    items.splice(-5, playlist);

    // Process again items, new playlist needs styles
    processItems(items)

    return items;
}

export function editPlaylistCabinet(playlist) {
    items.find(item => item._id === playlist._id) // find by contentful id

    return items;
}
// ========================================================

export function sortedByReleaseDate(items){
    return items.slice().sort((a,b)=>compareReleaseDate(a.releaseDate, b.releaseDate))
}
export function sortedItems(items){
    return items.slice().sort(compareItems)
}

function sortItems(items) {
    items.sort(compareItems)
}

if (useStubData) {
    sortItems(items)
}

//used to safely resolve model to-many references
function fixupFunction(...indexes) {

    let toObject
    if (indexes.length === 1) {
        let index = indexes[0]
        toObject = ({_id})=>index.byContentfulId[_id]
    } else {
        toObject = ({_id})=>{
            for (let i = 0; i < indexes.length; i++) {
                let index = indexes[i]
                if (index.byContentfulId[_id]) return index.byContentfulId[_id]
            }
            return null
        }
    }

    return function fix(item, kind, what) {
        let a = item[what] || []
        let b = _.compact(a)
        if (a.length !== b.length) {
            console.error(`bad item in ${kind} ${what}, ${kind} = `, item)
        }
        return b.map(toObject)
    }
}

let folder_classes = ['folder-1', 'folder-2', 'folder-3', 'folder-4', 'folder-5', 'folder-6', 'folder-7', 'folder-8', 'folder-9', 'folder-10' ];
let folder_playlist_classes = ['folder-pl-1', 'folder-pl-2', 'folder-pl-3'];

let tab_classes = ['tab-1','tab-2','tab-3','tab-4','tab-5']

let sticker_classes = ['black-1', 'black-2', 'blue-1', 'blue-2', 'green-1',
                       'green-2', 'orange-1', 'orange-2', 'red-1', 'red-2',
                       'yellow-1', 'yellow-2']

let stub_labels = [undefined,
                   folderLabel01,
                   folderLabel02,
                   folderLabel03
                ]

let paperclip_classes = [ 'paperclip-01', 'paperclip-02', 'paperclip-03',
                          'paperclip-04', 'paperclip-05', 'paperclip-06' ]

let audio_stubs = [
    'https://www.dropbox.com/s/n0fl4rou4rdso5e/I%20Am%20A%20Child.mp3?dl=1',
    'https://www.dropbox.com/s/oyv9d6vut1s34jr/Down%20To%20The%20Wire.mp3?dl=1',
    'https://www.dropbox.com/s/61suyfly5msdbez/Kahuna%20Sunset.mp3?dl=1',
    'https://www.dropbox.com/s/mufh8ecf5mt6q4v/MR%20soul.mp3?dl=1',
    'https://www.dropbox.com/s/dhg2zvbyw3x05ru/One%20More%20Sign.mp3?dl=1',
    'https://www.dropbox.com/s/c7kodb8vc9cwzu1/Down%20Down%20Down.mp3?dl=1',
    'https://www.dropbox.com/s/cvz0gnjbur9vlrq/Sell%20Out.mp3?dl=1',
    'https://www.dropbox.com/s/dvik8foa4k7euum/Slowly%20Burning.mp3?dl=1',
    'https://www.dropbox.com/s/3p1buohybdwstwj/This%20is%20it.mp3?dl=1'
]
let next_audio_preview = stream_from(audio_stubs)

let next_folder    = stream_from(folder_classes)
let next_folder_playlist = stream_from(folder_playlist_classes)
let next_tab       = stream_from(tab_classes)
let next_sticker   = stream_from(sticker_classes.concat([null]))
let next_label     = stream_from(stub_labels)
let next_paperclip = stream_from(paperclip_classes)


function processItems(){
    items.forEach((item)=>{

        if (item.isTrack) {
            item.paperclip = next_paperclip()
        }

        if (item.isFilm) {
            item.folder = 'folder-film'
        } else if (item.isCompilation) {
            item.folder = 'folder-compilation'
        } else if (item.isPerformanceSeries){
            item.folder = 'folder-performance-series'
        } else if (item.isPlaylist){
            item.folder = next_folder_playlist()
        } else {
            item.folder = next_folder()
        }

        /* item.title = item.folder */
        /* item.position = 'right'*/


        if (item.isAlbum && item.isCompilation) {
            item.tab = 'tab-compilation'
        } else if (item.isAlbum || item.isFilm || item.isPlaylist){
            item.tab = 'tab-center'
        } else {
            item.tab = next_tab()
        }

        if (item.position === 'right') {
            //allow right-hand-side stickers to be blank
        } else if (item.isCompilation){
            item.sticker = 'compilation'
        } else if (item.isPerformanceSeries) {
            item.sticker = 'performance-series'
        } else if (item.isFilm) {
            item.sticker = 'film'
        } else if (item.isPlaylist) {
            item.sticker = item.type    // playlist-custom : playlist
        } else if (!item.sticker) {
            item.sticker = next_sticker()
            /* ensure we get a sticker if it is an album */
            if (!item.sticker && item.isAlbum) item.sticker = next_sticker()
        }

        if (item.sticker) {
            let table = {
                'label-45': 'type-1',
                film: 'type-film',
                compilation: 'type-compilation',
                'performance-series': 'type-performance-series',
                playlist: 'black-2 type-2',
                'playlist-custom': 'blank-label'
            }

            let style = table[item.sticker]

            if (!style) style = item.sticker.match(/1/) ? 'type-1' : 'type-2'
            item.stickerStyle = style
        } else {
            item.stickerStyle = null
        }

        if (useStubData) {
            item.labelImage = next_label()
        }

        if ((useStubData || useStubAudio) &&
            !item.isAlbum && !item.previewAudio) {
                item.previewAudio = next_audio_preview()
        }

    })
}

function addPlaylistSection() {
    // Add playlist divider
    items.splice(-playlists.length, 0, {
        id: 'playlists-section',
        folder: 'folder-playlist-section',
        title: 'Playlist',
        tab: 'tab-section',
        position: 'center',
    })

    /* add spacer items to fill out bottom of filing cabinet */
    {
        let i = 5
        while(--i){
            items.push({
                id: `empty-${i}`,
                spacer: true,
                folder: next_folder()
            })
        }
    }
}

function genAlbumAssets(count){
    let result = [], n = count
    while (n--){
        result.push({credits: lorem({count: 2, units:'paragraphs'}),
                     title:   lorem({count: 4, units:'words'})})
    }
    return result
}

function processAlbums(albums){
    /* stub in video and review content for now */
    albums.forEach(album =>{
        /* album.videos = album.videos || genVideos(10)
         * album.reviews = album.reviews || lorem({count: random_int(2, 8), units:'paragraphs'})
         * album.assets = genAlbumAssets(28)*/

        processAssociatedReleases(album, albums)

        const markFree = it => it.free = true

        if (album.free) {
            album.tracks && album.tracks.forEach(track => {
                track.free = true
                track.memorabilia && track.memorabilia.forEach(markFree)
                track.photos      && track.photos.forEach(markFree)
                track.press       && track.press.forEach(markFree)
                track.documents   && track.documents.forEach(markFree)
                track.videos      && track.videos.forEach(markFree)
            })
            album.videos && album.videos.forEach(markFree)
            album.more && album.more.forEach(markFree)
        }
    })
}

if (useStubData) {
    processItems(items)
    processAlbums(albums)
}

function fixupTracks(tracksIndex){
    tracksIndex.all.forEach(t=>{
        if (t.linkedTrack && t.linkedTrack._id) {
            let other = tracksIndex.byContentfulId[t.linkedTrack._id]
            if (!other) return
            t.linkedTrack = other
            other.primaryTrack = t
        }
    })
}

function fixupAlbums(albumsIndex) {
    albumsIndex.all.forEach(album=>{
        if (album.repriseLink) {
            if (!album.purchaseOptions) {
                album.purchaseOptions = {}
            }
            album.purchaseOptions.reprise = album.repriseLink
        }
        if (album.free) {
            freeAlbumID = album.id
        }
    })
}

function fixupCustomPlaylists(playlistsIndex) {
    playlistsIndex.all.forEach(playlist=>{
        if (playlist.type === 'playlist-custom') {

            // Get user and set it as creator
            let info = getUserInfo()
            playlist.creator = (info.user_metadata && info.user_metadata.customFirstname) || info.name || info.nickname || ''

        }
    })
}

function fixupAlbumsAndArtists(albums, artists){
    if (!artists) return

    let toObject = ({_id})=>artists[_id]

    function fix(item, kind, what) {
        let a = item[what] || []
        let b = _.compact(a)
        if (a.length !== b.length) {
            console.error(`bad item in ${kind} ${what}, ${kind} = `, item)
        }
        return b.map(toObject)
    }

    albums.all.forEach(album => {
        if (album.artists) {
            album.artists = fix(album, 'album', 'artists')
        } else {
            //XXX not many artists entered in the CMS yet, so just defaulting to NY for now
            album.artists = [{name:'Neil Young'}]
        }
    })
}

function processAssociatedReleases(album, albums){
    let releases = album.associatedReleases

    // uncomment to stub related albums
    /* releases = [pick(albums).id, album.id, pick(albums).id]*/

    if (!releases) return

    let idx = releases.indexOf(album.id)

    if (idx === -1)  return

    let names = [
        '1st edition',
        '2nd edition',
        '3rd edition',
        '4th edition',
        '5th edition'
    ]

    let prev = idx -1, next = idx + 1

    if (releases[prev]) album.previousRelease = {name: names[prev], id: releases[prev]}
    if (releases[next]) album.nextRelease = {name: names[next], id: releases[next]}

}

function fixupTracksAndAlbums(tracks, albums, playlists, related) {
    albums.all.forEach(album => {
        let trks = _.compact(album.tracks || [])
        album.tracks = _.compact(trks.map(({_id})=> {
            let track = tracks.byContentfulId[_id]
            if (!track) {
                console.error('bad id in album tracks for ', album.title, ' ', album.id)
                return null
            }
            track.presentOn[album.id] = album
            if (track.originalReleaseSystemId && track.originalReleaseSystemId === album._id) {
                track.album = album
            }
            return track
        }))

        album.hasAudioTracks = _.find(album.tracks, it=>it.orastream)

        album.tracksForTimeline = _.filter(album.tracks, t=>!t.listOnly)
                                   .sort(compareItems)

        album.hasTracksForTimeline = album.tracksForTimeline.length > 0
    })

    playlists.all.forEach(album => {
        let trks = _.compact(album.tracks || [])
        album.tracks = _.compact(trks.map(({_id})=> {
            let track = tracks.byContentfulId[_id] || tracks.byId[_id]
            if (!track) {
                console.error('bad id in album tracks for ', album.title, ' ', album.id)
                return null
            }
            track.presentOn[album.id] = album
            if (track.originalReleaseSystemId && track.originalReleaseSystemId === album._id) {
                track.album = album
            }
            return track
        }))

        album.hasAudioTracks = _.find(album.tracks, it=>it.orastream)

        album.tracksForTimeline = _.filter(album.tracks, t=>!t.listOnly)
                                   .sort(compareItems)

        album.hasTracksForTimeline = album.tracksForTimeline.length > 0
    })

    tracks.all.forEach(track => {

        /* let albumCount = Object.keys(track.presentOn).length
         * if (albumCount > 1) {
         *     console.log(`track ${track.id} has ${albumCount} albums.`)
         * }
         */

        if (track.album) return

        let original = track.originalRelease
        if (original){
            let {_id} = original
            let album = albums.byContentfulId[_id]
            if (!album){
                console.error('missing album original release!', track.id, _id, original)
                track.album = track.originalRelease = null
            } else {
                track.album = track.originalRelease = album
            }
        }
    })

    let toObject = ({_id})=>related.byContentfulId[_id]

    function fix(track, what) {
        let a = track[what] || []
        let b = _.compact(a)
        if (a.length !== b.length) {
            console.error("bad item in track " + what + " track = ", track)
        }
        return _.compact(b.map(toObject))
    }

    tracks.all.forEach(track => {
        track.memorabilia = fix(track, 'memorabilia')
        track.photos      = fix(track, 'photos')
        track.press       = fix(track, 'press')
        track.documents   = fix(track, 'documents')
    })
}

function fixupTracksFilmsAlbumsAndVideos(tracksIndex, filmsIndex, albumsIndex, videosIndex) {

    let toObject = ({_id})=>videosIndex.byContentfulId[_id] || null

    function fix(item, kind, what) {
        let a = item[what] || []
        let b = _.compact(a)
        if (a.length !== b.length) {
            console.error(`bad item in ${kind} ${what}, ${kind} = `, item)
        }

        return b.map(toObject)
    }

    let fixup = kind => item => {
        item.videos = fix(item, kind, 'videos')
    }

    _.each(tracksIndex.all, fixup('track'))
    _.each(filmsIndex.all,  fixup('film'))
    _.each(albumsIndex.all, fixup('album'))
}

function fixupVideos(videosIndex){
    videosIndex.all.forEach(video => {
        if (video.vimeoThumbnail) {
            //TODO: set thumbnail from vimeo
        }
    })
}

function fixupMore(moreIndex) {
    //TODO: set thumbnail from vimeo
    moreIndex.all.forEach(video => {
        if (video.vimeoThumbnail) {
            //TODO: set thumbnail from vimeo
        }
    })
}

function fixupTracksAndLyrics(tracksIndex, lyricsArray) {
    _.each(lyricsArray, lyrics=>{
        let {trackId} = lyrics
        if (!trackId) {
            console.error("Missing trackId for lyrics: ", lyrics)
        } else {
            let ids = trackId.split(',').map(s=>s.trim())
            _.each(ids, id=>{
                let track = tracksIndex.byId[id]
                if (!track) {
                    console.error("missing track for lyrics, trackId:", id)
                } else {
                    track.lyrics = lyrics.lyrics
                }
            })
        }
    })
}

function fixupMoreAlbumsAndFilms(moreIndex, albumIndex, filmIndex){

    let fix = fixupFunction(moreIndex)

    albumIndex.all.forEach(album=>{
        album.more = fix(album, 'album', 'more')
    })
    filmIndex.all.forEach(film=>{
        film.more = fix(film, 'film', 'more')
    })

}

function fixupAlbumsAndAlbumArt(albumsIndex, albumArtIndex) {
    let fix = fixupFunction(albumArtIndex)

    albumsIndex.all.forEach(album=>{
        album.largeArtwork = fix(album, 'album', 'largeArtwork')
    })
}

function fixupBoxSetsAlbumsAndFilms(boxsetIndex, albumsIndex, filmsIndex) {
    let fix = fixupFunction(albumsIndex, filmsIndex)

    boxsetIndex.all.forEach(boxset=>{
        boxset.items = fix(boxset, 'boxset', 'items')
    })
}

function fixupTour(tourIndex, findMedia){
    tourIndex.all.forEach(tour=>{
        tour.photos = findMedia(tour, 'tour', 'photos')
    })
}

function fixupTimelineEvents(timelineEventIndex, findMedia, videosIndex) {
    let lookupVideo = ({_id})=>videosIndex.byContentfulId[_id]

    timelineEventIndex.all.forEach(event=>{
        event.photos = findMedia(event, 'event', 'photos')
        let video = event.video
        if (video) {
            event.video  = lookupVideo(video)
            if (!event.video) {
                console.error('bad video for timeline event:', event, video)
            }
        }
    })
}

/*******************************************************************************/

export function getFreeAlbumID(){
    return freeAlbumID
}

export function getItems(){
    return items
}

export function getCustomPlaylists(){
    return items.filter((item) => item.isPlaylist && item.type !== 'playlist')
}

export function getAllTracks(){
    if (useStubData) {
        return archive.items
    } else {
        return store.getState().tracks.all
    }
}

export function getListedTracks() {
    const freeAlbumID = getFreeAlbumID()
    const { id: freeSongId } = getFreeSong()

    return _.filter(getAllTracks(), track => {
        const { id: trackId, hiddenTrack, listOnly, album } = track

        if (hiddenTrack) {
            return false
        }

        // let's make song of the day tracks playable regarless if it is listOnly
        if (trackId === freeSongId) {
            return true
        }

        // let's make free album tracks playable regarless if they are listOnly
        if (_.get(album, 'albumId', -1) === freeAlbumID) {
            return true
        }

        return !listOnly
    })
}

// Needed because availability to play conditions are not relevant 
// to wether the song should be listed on the info-card view.
export function getListedTracksForInfoCards() {
    return _.filter(getAllTracks(), track => {
        const { hiddenTrack, listOnly } = track

        if (hiddenTrack) {
            return false
        }

        return !listOnly
    })
}


export function getTrackById(id){
    let index = useStubData ? archive.index : store.getState().tracks.byId
    return index[id]
}

export function getTracksByYear(year){
    let index = useStubData ? archive : store.getState().tracks
    return index.byYear[year]
}

export function getAllAlbums() {
    return store.getState().albums.all
}

export function getAlbumById(id) {
    let index = useStubData ? allAlbums.index : store.getState().albums.byId
    return index[id]
}

export function getAlbumsByYear(year) {
    let index = useStubData ? allAlbums : store.getState().albums
    return index.byYear[year]
}

export function getPlaylistById(id) {
    let index = store.getState().playlists.byId
    return index[id]
}

export function getPlaylistSOTW() {
    const playlists = store.getState().playlists.byContentfulId
    const values = Object.values(playlists)
    const sotw = values.find(item => item.title === 'Songs of the Week' )
    return sotw || {}
}

export function getAllFilms() {
    return store.getState().films.all
}


export function getFilmById(id){
    let index = useStubData ? allFilms.index : store.getState().films.byId
    return index[id]
}
export function getFilmsByYear(year){
    let index = useStubData ? allFilms : store.getState().films
    return index.byYear[year]
}


export function getFilmsAndAlbumsByYear(year) {
    return (getAlbumsByYear(year) || []).concat(getFilmsByYear(year) || [])
}

function cat(a,b){
    if (!a) return b
    if (!b) return a
    return a.concat(b)
}

export function getTimelineTopHalfContentByYear(year) {
    return cat(getAlbumsByYear(year),
               cat(getFilmsByYear(year),
                   cat(getBoxSetsByYear(year),
                       getBooksByYear(year))))
}

export function getVideoById(id){
    return store.getState().videos.byId[id]
}

export function getBoxSetById(id){
    return store.getState().boxset.byId[id]
}

export function getBoxSetsByYear(year) {
    return store.getState().boxset.byYear[year]
}

export function getBooksByYear(year) {
    return store.getState().book.byYear[year]
}

export function getEventById(id) {
    return store.getState().timelineEvents.byId[id]
}

export function getEventsByYear(year) {
    return store.getState().timelineEvents.byYear[year]
}

export function getTourZOByYear(year){
    return store.getState().tourZO.byYear[year]
}
export function getTourZOById(id){
    return store.getState().tourZO.byId[id]
}
export function getTimelineZOEventById(eventOrTourId){
    return getEventById(eventOrTourId) || getTourZOById(eventOrTourId)
}

export function getTourZIByYearAndMonth(year, month){
    let months = store.getState().tourZI.byYear[year]
    return months && months[month]
}
export function getTourZIById(id){
    return store.getState().tourZI.byId[id]
}
export function getTimelineZIEventById(eventOrTourId){
    return getTourZIById(eventOrTourId)
}

export function getMoreById(id){
    return store.getState().more.byId[id]
}

export function getMoreByContentfulId(_id){
    return store.getState().more.byContentfulId[_id]
}
export function getVideoByContentfulId(_id){
    return store.getState().videos.byContentfulId[_id]
}

export function getFreeDownloadSong(_id){
    return store.getState().tracks.all.filter(item => item.freeToDownload)
}

export function getFreeSong(_id){
    const freeTracks = store.getState().tracks.all.filter(item => item.free)
    const freeAlbumID = getFreeAlbumID()
    const SOTD = freeTracks.find(track => _.get(track, 'album.albumId', false) !== freeAlbumID)
    const trackOftheDay = SOTD || freeTracks[freeTracks.length - 1]
    return trackOftheDay
}
