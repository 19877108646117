import React         from 'react'
import FolderBrowser from './components/folder-browser'
import PhotoModal    from './components/photo-modal'

export default class Container extends FolderBrowser {
    contentType(){
        return 'docs'
    }
    selectedTab(){
        return 'documents'
    }
    extractPhotosFromTrack(track){
        return track.documents
    }
    modalForPhotoAndPhotos(photo, photos){
        return (<PhotoModal photo={photo} collection={photos}
                            location={this.props.location}
                            alwaysShowZoom={true}
                            onUpdate={this.showCurrentPhoto}
                            onDismiss={this.hideModal} /> )
    }
}
