export default function compareReleaseDate(a, b){
    if      (a.year < b.year)   return -1
    else if (b.year < a.year)   return  1
    else if (a.month < b.month) return -1
    else if (b.month < a.month) return  1
    else if (a.day < b.day)     return -1
    else if (b.day < a.day)     return  1
    else                        return  0
}
export function compareItems(a, b){
    let comp = compareReleaseDate(a.releaseDate, b.releaseDate)
    if (comp === 0) {
        if (a.isTrack && b.isTrack) { /* tie break tracks by day order */
            if      (a.dayOrder < b.dayOrder) return -1
            else if (b.dayOrder < a.dayOrder) return  1
            else                              return  0
        }
        else if (a.isAlbum && b.isAlbum) { /* tie break albums by id order */
            if      (a.order < b.order) return -1
            else if (b.order < a.order) return  1
            else                        return  0
        }
        else if (a.isFilm  && b.isFilm)  return  0
        else if (b.isAlbum)              return -1 /* albums sort after tracks and films */
        else if (a.isAlbum)              return  1
        else if (b.isFilm)               return -1 /* films sort after tracks */
        else if (a.isFilm)               return  1
        else                             return  0
    } else {
        return comp
    }
}

export function comparePlaylists(a,b) {
    if (a.type === 'playlist' && b.type === 'playlist') return a.order - b.order
    if (a.type === 'playlist-custom' && b.type === 'playlist-custom') return new Date(b.createdAt) - new Date(a.createdAt)
    if (a.type === 'playlist' && b.type === 'playlist-custom') return 1
    if (a.type === 'playlist-custom' && b.type === 'playlist') return -1
    return 0;
}
