import React, { Fragment, Component }   from 'react';
import Auth                             from './services/auth';
import { hasAuth }                      from './services/api';

class HOCRemoveAuth extends Component {
    constructor(props) {
        super(props)
        this.hideAuth0 = this.hideAuth0.bind(this)
    }
    componentDidMount() {
        this.hideAuth0()
    }
    componentWillUpdate() {
        this.hideAuth0()
    }
    hideAuth0() {
        if (!hasAuth()) {
            Auth.hide()
        }
    }
    render() {
        return <Fragment>{this.props.children}</Fragment>
    }
}

export default HOCRemoveAuth
