import React, {Component} from 'react'
import _ from 'lodash'
import {Button, URLOpener} from './common.js'

export default class Splash extends Component {
    render() {
      const { showSchedule, film } = this.props
        if (!film) return null
        const {live, title, director, dateText, time, buyLink} = film

        let showDate = true
        if (live) showDate = false

        return (
            <div className="splash">
              <center>
                <div className="watch" />
                <div className="title">{title}</div>
                <div className="director">Directed by {director}</div>
                <div className="time">Running time: {time}</div>
                {showDate && <div className="date-text">{dateText}</div>}
                {buyLink && <Button action={URLOpener(buyLink)} title="buy film" />}
                <div className="to-schedule" onClick={showSchedule}>See movie schedule</div>
              </center>
            </div>
        )
    }
}
