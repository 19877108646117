import { ENTRIES_LOADED, UPDATE_MOVIE } from '../constants';

export default function update(state = {byContentfulId:{}, all:[]}, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.movieNightFilm) {
                let result = {byContentfulId:{}, all:[]}
                Object.keys(action.entries.movieNightFilm).forEach(k => {
                    let it = action.entries.movieNightFilm[k]
                    if (it && it._id) {
                        result.byContentfulId[it._id] = it
                        result.all.push(it)
                    }
                })
                return result
            }
            break;

        case UPDATE_MOVIE:
            const { movie } = action
            const result = { ...state }

            result.byContentfulId[movie._id] = movie
            result.all = result.all.map(it => it._id === movie._id ? movie : it )

            return result

        default: return state
    }
}
