import store from './store';

const getPlan = planId => {
  const plans = store.getState().plans || []
  return plans.find(plan => plan.name === planId)
}

export const getPlanProperty = (property = 'banner', planId = 'NYA-UNLIMITED',) => {
  const plan = getPlan(planId)
  return plan[property]
}

export const getPlanBenefits = (planId = 'NYA-FREE') => {
  return getPlanProperty('benefits', planId)
}
