import React, {Component,Fragment} from 'react'
import Vimeo from 'react-tj-vimeo'
import vimeoPlayButtonImage from '../../images/movie-night/vimeo-button.png'

export default class VOD extends Component {
    constructor(props){
        super(props)
        this.onVimeoClick = this.onVimeoClick.bind(this);
    }
    onVimeoClick() {
        return window.subs()
    }
    render(){
        const {canViewFilm,currentFilmHasEnded,film,prePoster} = this.props
        const canView = canViewFilm(film)
        return (
            canView ? (
                <Vimeo videoId={film.videoID} onEnded={currentFilmHasEnded} supportFS={true} />
            ) : (
                <Fragment>
                  <div className="vimeo-wrapper" onClick={this.onVimeoClick}>
                    <img className="vimeo-button" src={vimeoPlayButtonImage} alt="Vimeo Play Button"/>
                  </div>
                  {prePoster && <img src={prePoster} alt="Pre poster"/>}
                </Fragment>
            )
        )
    }
}
