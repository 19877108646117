import { LOAD_ENTRIES, LOAD_SUBSCRIBER_ENTRIES } from '../constants';

export const loadEntries = () => {
    return {
        type: LOAD_ENTRIES
    }
}

export const loadSubscriberEntries = () => {
    return {
        type: LOAD_SUBSCRIBER_ENTRIES
    }
}