import _ from 'lodash';
import { ENTRIES_LOADED } from '../constants';

export default function update(state = {}, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            if(action.entries.postIt) {
                let result = {}
                Object.keys(action.entries.postIt).forEach(k => {
                    let it = action.entries.postIt[k]
                    if (it && it.id) {
                        result[it.id] = it
                    }
                })
                return result
            }
            break;
    }
    return state;
}
