import { ENTRIES_LOADED, ADD_PLAYLIST, UPDATE_PLAYLIST, DELETE_PLAYLIST, CHANGE_PLAYLIST_ITEMS_ORDER } from '../constants';
import { editPlaylist } from '../services/api'
import { getTrackById } from '../drawer-items';

const initialState = { loaded: false, byId: [], byContentfulId: {}, all: [] }

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const includesPlaylist = (playlists, it) => playlists.find(playlist => playlist._id === it._id)

export default function update(state = initialState, action) {
    switch (action.type) {
        case ENTRIES_LOADED:
            state.loaded = true

            // Contentful playlists
            if(action.entries.playlist) {
                Object.keys(action.entries.playlist).forEach(k => {
                    const it = action.entries.playlist[k]
                    if (includesPlaylist(state.all, it)) {
                        return
                    }

                    if (it && it._id) {
                        state.byId[it.id] = it
                        state.byContentfulId[it._id] = it
                        state.all.push(it)
                    }
                })
            }

            // User's playlists
            if(action.playlists && Array.isArray(action.playlists.body) && action.playlists.body.length > 0) {
                action.playlists.body.forEach(it => {
                    if (includesPlaylist(state.all, it)) {
                        return
                    }

                    if (it && it._id) {
                        it.tracks = it.tracks.map(track => getTrackById(track._id))

                        state.byId[it.playlistId] = it
                        state.all.push(it)
                    }
                })
            }

            return state
        case ADD_PLAYLIST:
            let resultAddPlaylist = {
                byId: [],
                byContentfulId: Object.assign({}, state.byContentfulId),
                all: state.all.map(item=>item)
            }

            state.all.map(it => {
                resultAddPlaylist.byId[it.playlistId || it._id] = it
            })

            resultAddPlaylist.byId[action.playlist.playlistId] = action.playlist
            resultAddPlaylist.all.push(action.playlist)

            return resultAddPlaylist

        case UPDATE_PLAYLIST:
            let resultUpdatePlaylist = {
                byId: [],
                byContentfulId: Object.assign({}, state.byContentfulId),
                all: state.all.map(item=>item)
            }

            state.all.map(it => {
                resultUpdatePlaylist.byId[it.playlistId || it._id] = it
            })

            resultUpdatePlaylist.byId[action.playlist._id] = action.playlist
            const playlistUpdateIndex = resultUpdatePlaylist.all.findIndex((item) => item._id === action.playlist._id)
            resultUpdatePlaylist.all.splice(playlistUpdateIndex, 1, action.playlist)

            return resultUpdatePlaylist

        case DELETE_PLAYLIST:
            let resultDeletePlaylist = {
                byId: [],
                byContentfulId: Object.assign({}, state.byContentfulId),
                all: state.all.map(item=>item)
            }

            state.all.map(it => {
                if (it._id !== action.playlistId) {
                    resultDeletePlaylist.byId[it.playlistId || it._id] = it
                }
            })

            const playlistDeleteIndex = resultDeletePlaylist.all.findIndex((item) => item._id === action.playlistId)
            resultDeletePlaylist.all.splice(playlistDeleteIndex, 1)

            return resultDeletePlaylist

        case CHANGE_PLAYLIST_ITEMS_ORDER:
            const changePlaylistOrderId = action.result.destination.droppableId

            const reorderedTracks = reorder(
                state.byId[changePlaylistOrderId].tracks.map(item => item),
                action.result.source.index,
                action.result.destination.index
            )

            let newItem

            let resultChangePlaylistOrder = {
                byId: [],
                byContentfulId: Object.assign({}, state.byContentfulId),
                all: state.all.map(item => {
                    if (item._id === changePlaylistOrderId) {
                        newItem = Object.assign({}, item, {tracks: reorderedTracks})
                        return newItem
                    } else {
                        return item
                    }
                })
            }

            editPlaylist(newItem._id, {tracks: reorderedTracks.map(item => item.trackId), title: newItem.title}, 'changeOrder')
                .catch(err => {
                    console.error(err)
                })

            state.all.map(it => {
                if (it._id !== changePlaylistOrderId) {
                    resultChangePlaylistOrder.byId[it.playlistId || it._id] = it
                } else {
                    resultChangePlaylistOrder.byId[it.playlistId || it._id] = newItem
                }
            })

            return resultChangePlaylistOrder

        default: return state
    }
}
