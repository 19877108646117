import React, {Component} from 'react'
import AdColumn           from './ad-column'
import Billboard          from './billboard'

export default class Top40Tracks extends Component {
    render(){
        return (
          <div className="main-content article">
            <div className="column article">
              <Billboard which="top40" />
            </div>
            <AdColumn showLinks />
          </div>
        )
    }
}
