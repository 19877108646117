import React, {Component} from 'react'
import BasicPage          from './components/basic-page'
import Input              from './components/input'
import request            from 'superagent'



export default class NewsletterContainer extends Component {
    constructor(props, context){
        super(props, context)

        let complete = !!localStorage.getItem('nya-user-has-signed-up-for-newsletter')
        let pending  = false

        this.state = {complete, pending}

        this.onSubmit = this.onSubmit.bind(this)
    }
    onSubmit(){
        let name  = this.refs.name.value
        let email = this.refs.email.value

        if (! (name && email)) return
        if (this.state.pending) return

        this.setState({pending:true})

        request
            .post('https://signup.wmg.com/register')
            .type('form')
            .send({js:true,
                   email:email, firstname:name,
                   newsletterId:'1407385,14076800',
                   datasource:'neilyoung_archives_website_newslettersignup'
            })
            .end((err, res)=>{
                if (err){
                    this.setState({complete: false, pending: false})
                } else {
                    console.log("got response:", res.body)
                    localStorage.setItem('nya-user-has-signed-up-for-newsletter', true)
                    this.setState({complete: true, pending: false})
                }
            })
    }
    renderForm(){
        return (
            <BasicPage className="newsletter-page">
              <div className="content">
                <div className="header-image"></div>
                <Input className="name"  ref="name"  placeholder="Name" />
                <Input className="email" ref="email" placeholder="Email Address" />
                <div className="submit" onClick={this.onSubmit} />
              </div>
            </BasicPage>
        )
    }
    renderComplete(){
        return (
            <BasicPage className="newsletter-page">
              <div className="signup-complete"></div>
            </BasicPage>
        )
    }
    render(){
        if (this.state.complete) {
            return this.renderComplete()
        } else {
            return this.renderForm()
        }
    }
}
