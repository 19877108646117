import React, {Component} from 'react'
import cn                 from 'classnames'
import {Link}             from 'react-router'
import {ScrollContainer}  from 'react-router-scroll'
import PropTypes          from 'prop-types'
import VerticalCenter     from '../components/vertical-center'
import {getNewsData, getPagesData}      from './article-data'
import {formatDate}       from './date-utils'
import NewsNavbar from './news-navbar'
import { updateMetadata } from '../actions/metas'


function getDate(){
    return formatDate(new Date())
}

function externalizeHref(a){
    if (location.hostname === a.hostname || !a.hostname.length) return;
    a.setAttribute('target', '_blank')
}

const routes = {
    1: '/news/1',
    2: '/news/2',
    3: '/news/3',
    4: '/news/4',
    5: '/news/5',
    6: '/news/6',
    7: '/news/7'
}
const maxPage = 7;

const nextPage = (currPage, dir) => {
    return Math.max(1, Math.min(maxPage, currPage + dir))
}

class Navigation extends Component {
    click(dir){
        const {router, page} = this.props
        const nextpage = nextPage(page, dir)
        const route = routes[nextpage]
        router.push(router.createLocation(route))
    }
    render(){
        const {page} = this.props,
              hasPrev = page > 1,
              hasNext = page < maxPage

        return (
            <div className="navigation">
              <div className={cn("button prev", {hidden:!hasPrev})} onClick={()=>this.click(-1)}>PREVIOUS</div>
              <div className="text">{page} of {maxPage}</div>
              <div className={cn("button next", {hidden:!hasNext})} onClick={()=>this.click(+1)}>NEXT</div>
            </div>
        )
    }
}

export default class Newspaper extends Component {
    constructor(props, context){
        super(props, context)
        this.onClickX = this.onClickX.bind(this)

        const page = parseInt(props.params ? props.params.page : 1) || 1
        this.state = { page }
    }
    componentWillMount(){
      updateMetadata({ title: 'NYA Times Contrarian' })
    }
    componentDidMount(){
        if (this.refs.linkLeft) externalizeHref(this.refs.linkLeft)
        if (this.refs.linkRight) externalizeHref(this.refs.linkRight)
    }
    onClickX(){
        const {router} = this.context
        router.push(router.createLocation('/menu'))
    }
    render(){
        const data = getNewsData();
        const pagesData = getPagesData();
        const volume     = data.volume || '',
              today      = getDate(),
              blurbLeft  = data.leftHeaderText || '',
              linkLeft   = data.leftHeaderLink,
              blurbRight = data.rightHeaderText || '',
              linkRight  = data.rightHeaderLink

        let leftEl = <VerticalCenter className="blurb left">{blurbLeft}</VerticalCenter>
        let rightEl = <VerticalCenter className="blurb right">{blurbRight}</VerticalCenter>

        if (linkLeft) leftEl = <a ref="linkLeft" href={linkLeft}>{leftEl}</a>
        if (linkRight) rightEl = <a ref="linkRight" href={linkRight}>{rightEl}</a>
        return (
            <div className="newspaper-page">
              <ScrollContainer scrollKey={this.props.location.pathname}>
              <div className="page-wrapper">
                <VerticalCenter style={{width:'100%', height:'100%'}}>
                  <div className="page">
                    <div className="ragged-edge" />
                    <div className="page-contents">
                      <div className="newspaper-header">
                        <div className={'title' + ` news-${this.state.page}`} />
                        <Link to="/news/1"><div className="title-click-area" /></Link>
                        <div className="midline">
                          <div className="volume">{volume}</div>
                          <div className="today">{today}</div>
                          <Navigation page={this.state.page} router={this.context.router} />
                        </div>
                        <NewsNavbar page={this.state.page} pagesData={pagesData} router={this.context.router} />
                        {leftEl}
                        {rightEl}
                      </div>
                      <div className="content">
                        <div className={'news-content' + ` news-${this.state.page}`}>
                            {this.props.children}
                        </div>
                      </div>
                    </div>
                    {/* this.state.page === 6 && <div className="ragged-edge bottom" /> */}
                  </div>
                </VerticalCenter>
              </div>
              </ScrollContainer>
            </div>
        )
    }
}

Newspaper.contextTypes = {
    router: PropTypes.object.isRequired
}
