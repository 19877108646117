import React, {Component} from 'react'
import marked             from 'marked'
import AdColumn           from './ad-column'
import {getArticleById}   from './article-data'
import Vimeo              from 'react-tj-vimeo'
import PropTypes          from 'prop-types'
import { updateMetadata } from '../actions/metas'

function externalizeHrefs(el) {
    let links = el.querySelectorAll('a')
    for (let i = 0; i < links.length; i++) {
        let a = links[i]
        if (location.hostname === a.hostname || !a.hostname.length) continue;
        a.setAttribute('target', '_blank')
    }
}

function getArticleImages(data) {
    if (data.vimeoId) {
        return <div className="video-container">
                <Vimeo videoId={parseInt(data.vimeoId, 10)} supportFS={true} />
            </div>
    } else if (data.type === 'two-image') {
        const url0 = `url(${data.images[0]})`
        const url1 = `url(${data.images[1]})`
        return (
            <div className="images two-image">
              <div className="image left" style={{backgroundImage:url0}} />
              <div className="image right"style={{backgroundImage:url1}} />
              <div className="credits">{data.credits}</div>
              <div className="clearfix" />
            </div>
        )
    } else if (data.type === 'one-image') {
        const url = `${data.images[0]}?w=970`
        return (
            <div className="images one-image">
              <img src={url} />
              <div className="clearfix" />
              <div className="credits">{data.credits}</div>
            </div>
        )
    } else {
        return null
    }
}

export default class NewsArticle extends Component {
    constructor(props) {
        super(props)

        const id = this.props.location.query.id;
        const data = id && getArticleById(id)
        this.state = {
            data
        }
    }
    componentDidMount() {
        const { data } = this.state
        if (!_.isEmpty(data)) {
            const { headline: title } = data
            updateMetadata({ title })
        }

        if (this.refs.content) externalizeHrefs(this.refs.content)
    }
    render() {
        const { data } = this.state
        if (_.isEmpty(data)) {
            // TODO: 404
            return null
        }

        const { headline, author, dateText, bodyText, column, bodyHeadline, page } = data

        return (
            <div className="main-content article">
                <div className="column article">
                {bodyHeadline ? <div className={'title ' + column + ` news-${page.slice(-1)}`} ref="content" dangerouslySetInnerHTML={{__html:marked(bodyHeadline || '')}} /> : <div className={'title ' + column + ` news-${page.slice(-1)}`}><h1>{headline}</h1></div>}
                <div className="byline">
                    <span className="author">{author}</span>
                    <span className="date">{dateText}</span>
                </div>
                {getArticleImages(data)}
                <div ref="content" className="article-text"
                        dangerouslySetInnerHTML={{__html:marked(bodyText || '')}}
                />
                </div>
                <AdColumn />
            </div>
        )
    }
}
NewsArticle.contextTypes = {
    router: PropTypes.object.isRequired
}
